import { ToastError } from "app/components/ToastError";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { resolve } from "path/posix";
import React from "react";
import { toast } from "react-toastify";
import { useMountedState, usePrevious } from "react-use";
import { AppAPI } from "services/AppApi.service";
import { EPatchOp } from "shared/enums/patch-operations.enum";
import { IPagination } from "shared/interfaces/pagination.interface";
import {
  getRandomUUID,
  maybeObject,
  parseAxiosErrorForMessage,
} from "utils/functions.utils";

type Options = {
  load?: boolean;
  query?: {};
};

export interface APIMeta {
  nextId: any;
  prevId: any;
  nextUrl: string;
  prevUrl: string;
  counts?: {
    active: { value: number; url: string; [x: string]: any };
    recent: { value: number; url: string; [x: string]: any };
    inactive: { value: number; url: string; [x: string]: any };
    filtered: { value: number; url: string; [x: string]: any };
  };
  pagination?: Partial<IPagination> | null;
  filters?: { [x: string]: any };
  [x: string]: any;
}

interface IPatchData {
  action?: EPatchOp;
  field?: IPatchField;
  fields?: IPatchField[];
}
interface IPatchField {
  name: string;
  value?: any;
  op?: EPatchOp;
}

interface IDefaultLogicState<T = any> {
  meta?: APIMeta | null | undefined;
  data?: T;

  // Statuses
  initialLoad: boolean;
  loading: boolean;

  creating: boolean;
  created: boolean;

  updating: boolean;
  updated: boolean;

  patching: boolean;
  patched: boolean;

  removing: boolean;
  removed: boolean;

  // Errors
  error: string | { id?: string; message?: string; timestamp?: string } | null;
}

interface IModifyConfig {
  url?: string;
  query?: {};
  data?: {};
}

const defaultOptions: Options = {
  load: false,
  query: {},
};

interface IApiOptions {
  onStart?: (obj?: any) => void;
  onSuccess?: (obj?: any) => void;
  onError?: ((obj?: any) => void) | null;
  onFinally?: () => void;
  onUploadProgress?: Function;
  onDownloadProgress?: Function;
  showError?: boolean;
  abortPrevious?: boolean;
  config?: AxiosRequestConfig;
}

const DEFAULT_API_OPTIONS: IApiOptions = {
  onStart: () => {},
  onSuccess: () => {},
  onError: null,
  onFinally: () => {},
  showError: false,
  abortPrevious: false,
};

const DEFAULT_STATE: Partial<IDefaultLogicState> = {
  meta: undefined,
  data: undefined,

  initialLoad: true,
  loading: true,

  created: false,
  creating: false,

  updating: false,
  updated: false,

  patching: false,
  patched: false,

  removed: false,
  removing: false,

  error: null,
};

const GENERIC_ERROR =
  "An error occurred preventing a proper response from the server.";

export function useApi<Type = any>(
  initialUrl: string = "",
  options: Options = defaultOptions,
  initialState = DEFAULT_STATE
) {
  const { load, query } = options;
  const [state, setState] = React.useState<Partial<IDefaultLogicState<Type>>>({
    ...DEFAULT_STATE,
    ...initialState,
  });
  const [url, setUrl] = React.useState<string>(initialUrl);
  const [loadFlag, setLoadFlag] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string>(getRandomUUID());
  // const [controllers, setControllers] = React.useState<AbortController[]>([]);
  const controllers = React.useRef<AbortController[]>([]);

  const isMounted = useMountedState();
  const previousUrl = usePrevious(url);
  // const previousLoadFlag = usePrevious(loadFlag);

  // const api = React.useMemo(() => new AppAPI(), []);
  const api = new AppAPI();

  React.useEffect(() => {
    if (load) {
      get(query);
    }
  }, []);

  React.useEffect(() => {
    // if (previousUrl && url != previousUrl) {
    //     get();
    // }
  }, [url]);

  // React.useEffect(() => {
  //     if (loadFlag && previousUrl != url) {
  //         get();
  //         setLoadFlag(false);
  //     }
  // }, [loadFlag]);

  const reset = (resetState: Partial<IDefaultLogicState> = {}) => {
    if (isMounted()) {
      setState({
        ...DEFAULT_STATE,
        ...resetState,
      });
    }
  };

  const handleSetUrl = (
    url: string,
    load: boolean = false,
    options: IApiOptions = {}
  ) => {
    setUrl(url);

    if (load && previousUrl != url) {
      get(url, options);
    }
  };

  const cancelAll = async () => {
    const abortList: any = [];
    for (const controller of controllers?.current) {
      abortList.push(
        new Promise((resolve) => {
          controller.abort();
          resolve(true);
        })
      );
    }

    await Promise.all(abortList);
  };

  const cancelLatest = async () => {
    await new Promise((resolve) => {
      controllers?.[controllers?.current?.length - 1]?.abort?.();
      resolve(true);
    });
  };

  const generateId = () => {
    setId(getRandomUUID());
  };

  const handleSetState = (newState: Partial<IDefaultLogicState>) => {
    if (isMounted()) {
      setState((state) => ({ ...state, ...newState }));
    }
  };

  const instance = api;

  const get = async (
    query: {} | string = {},
    options: IApiOptions = DEFAULT_API_OPTIONS
  ): Promise<AxiosResponse<Type>> => {
    const {
      onStart,
      onSuccess,
      onError,
      onFinally,
      onDownloadProgress,
      showError,
      abortPrevious,
    } = options;
    let res: AxiosResponse<Type>;
    const config: any = {};
    const controller = new AbortController();
    let canceled: boolean = false;

    if (abortPrevious) {
      controllers?.current?.forEach((v) => v?.abort?.());
    }

    controllers.current = [...controllers.current, controller];

    if (onDownloadProgress) {
      config.onDownloadProgress = onDownloadProgress;
    }

    try {
      handleSetState({ error: null, loading: true });
      onStart && onStart();
      res = await api.get({
        url: typeof query == "string" ? query : url,
        config: {
          params: typeof query == "object" ? query : null,
          signal: controller.signal,
          ...maybeObject(options.config),
        },
      });

      if (!res) {
        throw new Error(GENERIC_ERROR);
      }

      handleSetState({
        data:
          // @ts-ignore
          typeof res?.data?.data != "undefined" ? res?.data?.data : res?.data,
        // @ts-ignore
        meta: res?.data?.meta,
      });

      onSuccess && (await onSuccess(res));
    } catch (error: any) {
      canceled = error?.message == "canceled";

      if (error?.message != "canceled") {
        handleSetState({
          error: parseAxiosErrorForMessage(error)?.message,
        });
        onError &&
          onError({ error: parseAxiosErrorForMessage(error)?.message });
        if (showError || !onError) {
          toast.error(<ToastError error={error} />);
        }
      }
    } finally {
      if (!canceled) {
        handleSetState({
          loading: false,
          initialLoad: false,
        });
        onFinally && onFinally();
      }
      // @ts-ignore
      return res;
    }
  };

  const create = async (
    data: { [x: string]: any },
    options: IApiOptions = DEFAULT_API_OPTIONS
  ) => {
    const { onSuccess, onError, onFinally, onUploadProgress, showError } =
      options;
    const config: any = {};

    let headers = { "Content-Type": "application/json" };

    if (data instanceof FormData) {
      headers = { "Content-Type": "multipart/form-data" };

      // @ts-ignore
      for (const [key, value] of data.entries()) {
        if (value === undefined || value === "undefined") {
          data.delete(key);
        }
      }
    }

    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress;
    }

    try {
      handleSetState({ data: null, meta: null, creating: true, error: null });
      const res = await api.instance({
        method: "POST",
        url,
        data,
        headers,
        ...config,
      });

      if (!res) {
        throw new Error(GENERIC_ERROR);
      }

      handleSetState({
        data:
          typeof res?.data?.data != "undefined" ? res?.data?.data : res?.data,
        meta: res?.data?.meta,
        created: true,
      });

      onSuccess && (await onSuccess(res));

      return res;
    } catch (error) {
      handleSetState({
        error: parseAxiosErrorForMessage(error)?.message,
      });
      onError && onError({ error: parseAxiosErrorForMessage(error)?.message });
      if (showError || !onError) {
        toast.error(<ToastError error={error} />, {
          autoClose: false,
          closeOnClick: false,
        });
      }
    } finally {
      handleSetState({ creating: false });
      onFinally && onFinally();
    }
  };

  const update = async (
    data: any,
    options: IApiOptions = DEFAULT_API_OPTIONS
  ) => {
    const { onSuccess, onError, onFinally, onUploadProgress, showError } =
      options;

    const config: any = {};

    let headers = { "Content-Type": "application/json" };

    if (data instanceof FormData) {
      headers = { "Content-Type": "multipart/form-data" };
    }

    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress;
    }

    try {
      handleSetState({ updating: true, updated: false, error: null });
      const res = await api.instance({
        method: "PUT",
        url,
        data,
        headers,
        ...config,
      });
      const updateObj: any = { updated: true };

      if (!res) {
        throw new Error(GENERIC_ERROR);
      }

      if (res?.data) {
        if (res.data?.data) {
          updateObj.data = res?.data?.data;
          updateObj.meta = res?.data?.meta;
        } else {
          updateObj.data = res?.data;
        }
      }

      onSuccess && (await onSuccess(res));

      handleSetState(updateObj);

      return res;
    } catch (error) {
      handleSetState({
        error: parseAxiosErrorForMessage(error)?.message,
      });
      onError && onError({ error: parseAxiosErrorForMessage(error)?.message });
      if (showError || !onError) {
        toast.error(<ToastError error={error} />, {
          autoClose: false,
          closeOnClick: false,
        });
      }
    } finally {
      handleSetState({ updating: false });
      onFinally && onFinally();
    }
  };

  const patch = async (
    data: IPatchData | FormData,
    options: IApiOptions = DEFAULT_API_OPTIONS
  ) => {
    const { onSuccess, onError, onFinally, onUploadProgress, showError } =
      options;

    const config: any = {};

    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress;
    }

    try {
      handleSetState({ patching: true, updated: false, error: null });
      const res = await api.patch({ url, data, config });

      if (!res) {
        throw new Error(GENERIC_ERROR);
      }

      handleSetState({
        data:
          typeof res?.data?.data != "undefined" ? res?.data?.data : res?.data,
        meta: res?.data?.meta,
        patched: true,
      });

      onSuccess && (await onSuccess(res));

      return res;
    } catch (error) {
      handleSetState({
        error: parseAxiosErrorForMessage(error)?.message,
      });
      onError && onError({ error: parseAxiosErrorForMessage(error)?.message });
      if (showError || !onError) {
        toast.error(<ToastError error={error} />, {
          autoClose: false,
          closeOnClick: false,
        });
      }
    } finally {
      handleSetState({ patching: false });
      onFinally && onFinally();
    }
  };

  const remove = async (
    query?: {},
    options: IApiOptions = DEFAULT_API_OPTIONS
  ) => {
    const { onSuccess, onError, onFinally, showError } = options;
    try {
      handleSetState({ removing: true, removed: false, error: null });
      const res = await api.delete({ url, config: { params: query } });

      if (!res) {
        throw new Error(GENERIC_ERROR);
      }

      handleSetState({
        data: null,
        meta: null,
        removed: true,
      });

      onSuccess && (await onSuccess(res));

      return res;
    } catch (error) {
      handleSetState({
        error: parseAxiosErrorForMessage(error)?.message,
      });
      onError && onError({ error: parseAxiosErrorForMessage(error)?.message });
      if (showError || !onError) {
        toast.error(<ToastError error={error} />, {
          autoClose: false,
          closeOnClick: false,
        });
      }
    } finally {
      handleSetState({ removing: false });
      onFinally && onFinally();
    }
  };

  return React.useMemo(
    () => ({
      ...state,
      instance,
      url,
      id,
      cancelAll,
      cancelLatest,
      reset,
      setUrl: handleSetUrl,
      generateId,
      get,
      create,
      update,
      patch,
      remove,
      updateState: handleSetState,
    }),
    [...Object.values(state), url]
  );
}
