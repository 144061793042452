import { THEME_DEFAULT_ERROR_BACKGROUND, THEME_DEFAULT_SUCCESS_BACKGROUND, THEME_DEFAULT_WARNING_BACKGROUND } from 'constants/theme.contants';
import React from 'react';

type SizeTypes = 'sm' | 'md' | 'lg';
type IndicatorTypes = 'success' | 'warning' | 'error';

interface IStatusLight {
    color?: string;
    type?: IndicatorTypes;
    content?: string;
    size?: string;
    blink?: boolean;
}

const sizes = {
    sm: 'h-2 w-2',
    md: 'h-3 w-3',
    lg: 'h-4 w-4',
}

const types = {
    success: THEME_DEFAULT_SUCCESS_BACKGROUND,
    warning: THEME_DEFAULT_WARNING_BACKGROUND,
    error: THEME_DEFAULT_ERROR_BACKGROUND,
}

export default React.forwardRef(({ color, type, content, blink, size = 'md', ...rest }: IStatusLight, ref) => {

    const _size = sizes[size];
    const style:any = {}
    
    if (color && !type) {
        style.backgroundColor = color;
    }

    return (
        <div
            className={`${_size} rounded-full ${types[type!] || ''} ${blink ? 'animate-pulse' : ''}`}
            style={style}
            ref={ref as any}
            {...rest}
        >

        </div>
    )
})