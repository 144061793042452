import { BrowserRouter } from "react-router-dom";
import { Routes } from "app/routes";
import { AuthContextProvider } from "services/auth";
import { Main } from "app/layouts/Main";
import { BreakpointContextProvider } from "hooks/breakpoint.hook";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AppSettingsContextProvider } from "hooks/app-settings.hook";
import { UpdateContextProvider } from "services/update";
import { SocketContextProvider } from "services/socket/socket";

// @ts-ignore
// library.add(
//     // @ts-ignore
//     fal,
//     fad,
//     far,
//     fas,
// )

const App = () => {
  // React.useEffect(() => {
  //     if (localStorage.theme === 'dark') {
  //         document.documentElement.classList.add('dark')
  //     } else {
  //         document.documentElement.classList.remove('dark')
  //     }
  // }, []);

  return (
    <BrowserRouter>
      <SocketContextProvider>
        <UpdateContextProvider>
          <AuthContextProvider>
            <AppSettingsContextProvider>
              <BreakpointContextProvider>
                <HelmetProvider>
                  <Helmet defaultTitle={process.env.REACT_APP_NAME} />
                  <Main>
                    <Routes />
                  </Main>
                </HelmetProvider>
              </BreakpointContextProvider>
            </AppSettingsContextProvider>
          </AuthContextProvider>
        </UpdateContextProvider>
      </SocketContextProvider>
    </BrowserRouter>
  );
};

export default App;
