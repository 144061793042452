import Loader from 'app/components/generics/Loader';
import classNames from 'classnames';
import { THEME_DEFAULT_BACKGROUND, THEME_DEFAULT_BORDER } from 'constants/theme.contants';
import React, { CSSProperties } from 'react';
import { TypeShadowSize } from 'shared/types/shadow-size.type';

interface IPaper extends React.HTMLAttributes<HTMLDivElement> {
  gutters?: 'sm' | 'md' | 'lg' | boolean;
  border?: boolean;
  rounded?: boolean;
  loading?: boolean;
  fill?: boolean;
  hover?: boolean;
  width?: string|number;
  children?: any;
  elevation?: TypeShadowSize;
}

const gutterSpacing = {
  sm: 'p-2',
  md: 'p-4',
  lg: 'p-6',
}

export const Paper = React.forwardRef((props: IPaper, ref) => {
  
  const { 
    gutters = true,
    border = true,
    rounded = true,
    hover,
    fill,
    className,
    children,
    elevation,
    loading,
    width,
    ...rest
  } = props;
  const style: CSSProperties = {};
  const defaultClasses: string[] = [
    'rounded-sm relative',
  ]
  // let spacing = gutterSpacing.md;
  let spacing = gutterSpacing.md;
  const classes: string[] = [];

  if (!gutters) {
    spacing = '';
  } else if (typeof gutters == 'string') {
    spacing = gutterSpacing[gutters];
  }

  if (typeof width != 'undefined') style.width = width;

  if (hover) {
    classes.push(`hover:shadow${elevation ? '-' + elevation : ''}`);
  } else {
    classes.push(`shadow${elevation ? '-' + elevation : ''}`);
  }

  return (
    //@ts-ignore
    <div ref={ref}
      className={classNames([
        ...defaultClasses,
        ...classes,
        'paper',
        // 'flex',
        // 'flex flex-col h-full',
        THEME_DEFAULT_BACKGROUND,
        border ? `${THEME_DEFAULT_BORDER} border` : '',
        rounded ? 'rounded' : '',
        fill ? 'flex-auto' : '',
        spacing,
        className,
      ])}
      {...rest}
      style={{...style, ...rest.style}}
    >
      { loading ? <Loader center size='xl' className='py-6'/> : children }
    </div>
  )
})