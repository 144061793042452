import { Button } from "app/components/generics/Button";
import { Typography } from "app/components/generics/Typography";
import classNames from "classnames";
import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IButton } from "app/components/generics/Button/Button";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";

interface ISteps extends Partial<HTMLDivElement> {
  defaultActiveKey?: string | number;
  activeKey?: string | number;
  height?: number;
  destroyOnChange?: boolean;
  footer?: any;
  nextButton?: Partial<IButton>;
  prevButton?: Partial<IButton>;
  actionButton?: Partial<IButton>;
  children?: any;
}

const generateStep = (props) => {
  const {
    foundKeys,
    key,
    isActive,
    disabled,
    title,
    hint,
    currentKey,
    description,
    setCurrentKey,
    icon,
  } = props;

  const stepClasses = [
    "pl-4 py-2 flex flex-col border-l-4",
    "md:pl-0 md:pt-3 md:pb-0 md:border-l-0 md:border-t-4",
  ];

  const currentKeyIndex = foundKeys.findIndex((v) => v.key == currentKey);
  const keyIndex = foundKeys.findIndex((v) => v.key == key);

  const _disabled = disabled || keyIndex > currentKeyIndex + 1;
  // const disableNext = keyIndex > currentKeyIndex + 1;

  if (isActive) {
    stepClasses.push("border-blue-500 dark:border-blue-500");
  } else if (keyIndex < currentKeyIndex) {
    stepClasses.push("group border-blue-400 hover:border-blue-500 ");
  } else {
    stepClasses.push("group border-gray-300 dark:border-gray-500");
    if (!_disabled) {
      stepClasses.push("hover:border-gray-400 dark:hover:border-gray-400");
    }
  }

  return (
    <li key={key} className="md:flex-1">
      <a
        className={classNames([
          stepClasses.join(" "),
          !_disabled ? "cursor-pointer" : "cursor-not-allowed",
        ])}
        onClick={() => !_disabled && setCurrentKey(key)}
      >
        <div className="flex space-x-2">
          {icon}
          <div className="flex flex-1 space-x-2">
            <div className="flex-1 space-y-2">
              {title && (
                <Typography disabled={_disabled} bold size="sm" flat>
                  {title}
                </Typography>
              )}
              {description && (
                <Typography.Paragraph
                  disabled={_disabled}
                  fontWeight="medium"
                  size="xs"
                >
                  {description}
                </Typography.Paragraph>
              )}
            </div>
            {typeof hint == "string" ? (
              <Typography
                disabled={_disabled}
                type="secondary"
                size="xxs"
                fontWeight="medium"
                flat
              >
                {hint}
              </Typography>
            ) : (
              hint
            )}
          </div>
        </div>
      </a>
    </li>
  );
};

const Steps = (props: ISteps) => {
  const {
    defaultActiveKey = 0,
    activeKey = 0,
    children,
    height = 400,
    footer,
    destroyOnChange,
    prevButton,
    nextButton,
    actionButton,
    className = "",
  } = props;

  const style: CSSProperties = {
    minHeight: height,
  };

  const [currentKey, setCurrentKey] = React.useState(
    defaultActiveKey || children?.[0]?.key || defaultActiveKey
  );
  const [foundKeys, setFoundKeys] = React.useState<any[]>([]);
  const [viewedKeys, setViewKeys] = React.useState([
    defaultActiveKey || children?.[0]?.key || defaultActiveKey,
  ]);
  const [isLastKey, setIsLastKey] = React.useState(false);
  const [isFirstKey, setIsFirstKey] = React.useState(false);

  const [prevKey, setPrevKey] = React.useState({ disabled: false, key: null });
  const [nextKey, setNextKey] = React.useState({ disabled: false, key: null });

  React.useEffect(() => {
    setCurrentKey(activeKey);
  }, [activeKey]);

  // Get all keys from incoming children or grab from their index
  React.useEffect(() => {
    const foundKeys: any[] = [];
    React.Children.map(children, (child, i) => {
      const _key = child.key != null ? child.key : i;

      foundKeys.push({
        key: _key,
        disabled: !!child.props.disabled,
      });
    });
    setFoundKeys(foundKeys);
  }, [children]);

  // Reset the prev/next key functions when new keys are found or the current key changes
  React.useEffect(() => {
    const currentKeyIndex = foundKeys.findIndex((v) => v.key == currentKey);
    const prevKeyIndex = currentKeyIndex - 1;
    const nextKeyIndex = currentKeyIndex + 1;

    if (currentKeyIndex != -1) {
      setPrevKey({
        key: prevKeyIndex > -1 ? foundKeys[prevKeyIndex]?.key : 0,
        disabled: !(prevKeyIndex > -1) || !!foundKeys[prevKeyIndex]?.disabled,
      });
      setNextKey({
        key: nextKeyIndex <= foundKeys.length && foundKeys[nextKeyIndex]?.key,
        disabled:
          !(nextKeyIndex <= foundKeys.length) ||
          !!foundKeys[nextKeyIndex]?.disabled,
      });
      setIsLastKey(nextKeyIndex == foundKeys.length);

      setIsFirstKey(prevKeyIndex == -1);
    }

    // const prevKeyIndex = foundKeys[currentKey - 1] != -1 ? foundKeys[currentKey - 1] : null
    // const nextKeyIndex = foundKeys[currentKey + 1] <= foundKeys.length ? foundKeys[currentKey - 1] : null
  }, [foundKeys, currentKey]);

  const steps: any[] = [];
  const _children = React.Children.map(children, (child, i) => {
    const _key = child.key != null ? child.key : i;

    const props = {
      isActive: currentKey == _key,
      destroyOnChange,
    };

    steps.push(
      generateStep({
        ...props,
        ...child.props,
        currentKey,
        foundKeys,
        setCurrentKey,
        key: _key,
      })
    );

    return React.cloneElement(child as any, props);
  });

  React.useEffect(() => {
    if (!viewedKeys.includes(currentKey)) {
      setViewKeys((state) => [...state, currentKey]);
    }
  }, [currentKey]);

  return (
    <div className="flex flex-col h-full">
      <nav className="mb-8" aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">{steps}</ol>
      </nav>
      <div style={style} className={`mb-4 flex-auto ${className}`}>
        {/*@ts-ignore */}
        {_children}
      </div>
      {footer ? (
        footer({ currentKey, foundKeys, setCurrentKey })
      ) : (
        <div
          className={classNames([
            "flex w-full justify-between flex-row-reverse",
            "mt-auto",
          ])}
        >
          <div>
            <Button
              {...actionButton}
              type={actionButton?.type || "primary"}
              disabled={actionButton?.disabled}
              onClick={(e) => isLastKey && actionButton?.onClick?.(e)}
              className={isLastKey && actionButton ? undefined : "hidden"}
              tabIndex={isLastKey && actionButton ? undefined : -1}
              htmlType="submit"
            >
              {actionButton?.children}
            </Button>
            <Button
              {...nextButton}
              type={
                typeof nextButton != "undefined" ? nextButton?.type : "primary"
              }
              disabled={
                typeof nextButton != "undefined"
                  ? nextButton?.disabled
                  : nextKey.disabled
              }
              onClick={(e) =>
                typeof nextButton != "undefined"
                  ? nextButton.onClick?.(e)
                  : setCurrentKey(nextKey.key)
              }
              className={isLastKey ? "hidden" : undefined}
              tabIndex={isLastKey ? -1 : undefined}
            >
              {nextButton?.children || (
                <>
                  Next &nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </>
              )}
            </Button>
          </div>
          <Button
            {...prevButton}
            type={
              typeof prevButton != "undefined" ? prevButton?.type : "primary"
            }
            disabled={
              typeof prevButton?.disabled == "boolean"
                ? prevButton?.disabled
                : isFirstKey || prevKey.disabled
            }
            onClick={(e) => {
              prevButton?.onClick?.(e);
              setCurrentKey(prevKey.key);
            }}
          >
            {prevButton?.children || (
              <>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp; Previous
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

interface IStep extends React.HTMLProps<HTMLDivElement> {
  isActive?: boolean;
  forceRender?: boolean;
  title?: any;
  subtitle?: any;
  description?: any;
  disabled?: boolean;
  destroyOnChange?: boolean;
  children?: any;
  hint?: any;
  icon?: any;
}

const Step = (props: IStep) => {
  const {
    children,
    isActive,
    forceRender,
    destroyOnChange,
    id,
    title,
    subtitle,
    icon,
    ...rest
  } = props;

  const [viewed, setViewed] = React.useState(false);

  React.useEffect(() => {
    if (isActive) {
      setViewed(true);
    }
  }, [isActive]);

  const canView = (viewed && !destroyOnChange) || isActive || forceRender;
  const style: CSSProperties = {};
  let tabIndex;

  if (!isActive) {
    tabIndex = -1;
    style.position = "absolute";
    style.display = "hidden";
    style.visibility = "hidden";
    style.height = 0;
    style.left = "-9999px";
    style.top = "-9999px";
  }

  const header = (
    <div className="flex items-center p-4 space-x-4">
      {icon && <div>{icon}</div>}
      <div className="space-y-2">
        <Typography>{title}</Typography>
        {subtitle && <Typography>{subtitle}</Typography>}
      </div>
    </div>
  );

  return (
    <div style={style} tabIndex={tabIndex} {...rest}>
      {canView && children}
    </div>
  );
};

Steps.Step = Step;

export default Steps;

export { default as Panels } from "./Panels";
