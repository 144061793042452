import React from 'react';
import { Portal } from 'react-portal';

export const Sidebar = props => {
    const { children } = props;
    const [sideNav, setSideNav] = React.useState();

    React.useEffect(() => {
        if (document && document.getElementById('side-sub-nav')) {
            setSideNav(document.getElementById('side-sub-nav') as any)
        }
    }, []);

    return (
        <Portal node={sideNav}>
            <div className='w-56'/>
            <div className='flex flex-shrink-0 h-full fixed drop-shadow-right z-10'>
                <div className='flex flex-col'>
                    <div className='flex flex-col flex-grow bg-white dark:bg-gray-800 pb-2'>
                        <div className='flex'>
                            <nav
                                className='mt-2 flex flex-col overflow-y-auto pt-2 w-56'
                                aria-label='sidenav'
                            >
                                {children}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    )
}