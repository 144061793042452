import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sizes } from "shared/types/size.type";
import { createPagination } from "utils/functions.utils";
import { IPagination } from "shared/interfaces/pagination.interface";
import { Select } from "app/components/generics";
import { DEFAULT_PAGE_SIZE } from "constants/default-query.constants";
import { PaginationButtons } from "app/components/generics/Pagination/components/Buttons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";

interface IPaginationRow extends IPagination {
  size: Sizes;
  onPrevious: Function;
  onNext: Function;
  disableNext: boolean;
  disablePrevious: boolean;
  gotoPage: Function;
  showSizeChanger: boolean;
  onSizeChange: (size: number) => void;
  pageSizeOptions: number[];
  defaultPageSize: number;
}

const defaultOptions = [DEFAULT_PAGE_SIZE, 25, 50];

const sizes = {
  sm: "h-3 w-3",
  md: "h-4 w-4",
  lg: "h-5 w-5",
};
const actionButtonSizes = {
  sm: "px-2 py-1",
  md: "px-3 py-1.5",
  lg: "px-3.5 py-2",
};

const Pagination = (props: Partial<IPaginationRow>) => {
  const {
    // Pager
    size = "md",
    pageCount,
    pageIndex,
    onPrevious,
    onNext,
    disableNext,
    disablePrevious,
    gotoPage,

    // Sizer
    showSizeChanger,
    defaultPageSize,
    pageSizeOptions = defaultOptions,
    onSizeChange,
  } = props;

  const [pagination, setPagination] = React.useState<any[]>([]);

  React.useEffect(() => {
    const builtPagination = createPagination(pageIndex, pageCount);
    setPagination(builtPagination);
  }, [pageIndex, pageCount]);

  const paginationRow = React.useMemo(() => {
    return pagination?.map((v, i) => (
      <a
        key={i}
        aria-current="page"
        onClick={() => {
          gotoPage && gotoPage(v - 1);
        }}
        className={classNames([
          //@ts-ignore
          actionButtonSizes[size] || actionButtonSizes.md,
          !isNaN(v) ? "cursor-pointer" : "",
          "relative inline-flex items-center border text-sm font-medium w-10 justify-center hover:text-blue-300 dark:hover:text-blue-300",
          v - 1 == pageIndex
            ? "border-blue-400 text-blue-400 z-10"
            : "border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400",
        ])}
      >
        {v}
      </a>
    ));
  }, [pagination, pageCount]);

  return (
    <div className="flex space-x-2">
      {showSizeChanger && (
        <Select.Native
          defaultValue={defaultPageSize}
          onChange={(e) => {
            const size = !isNaN(e.target.value)
              ? Number(e.target.value)
              : DEFAULT_PAGE_SIZE;
            onSizeChange && onSizeChange(size);
          }}
          options={pageSizeOptions}
          size={size}
        />
      )}
      <nav
        className="relative z-0 inline-flex -space-x-px rounded-md"
        aria-label="Pagination"
      >
        <button
          onClick={() => onPrevious && onPrevious()}
          disabled={disablePrevious}
          className={classNames([
            // 'dark:bg-gray-700',
            //@ts-ignore
            actionButtonSizes[size] || actionButtonSizes.md,
            "relative inline-flex items-center rounded-l-md border border-gray-300 dark:border-gray-700 w-10 justify-center cursor-pointer",
          ])}
        >
          <span className="sr-only">Previous</span>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={faChevronLeft}
            className={classNames([sizes[size] || sizes.md])}
          />
        </button>
        {paginationRow}
        <button
          onClick={() => onNext && onNext()}
          disabled={disableNext}
          className={classNames([
            //@ts-ignore
            actionButtonSizes[size] || actionButtonSizes.md,
            "relative inline-flex items-center rounded-r-md border border-gray-300 dark:border-gray-700 w-10 justify-center cursor-pointer",
          ])}
        >
          <span className="sr-only">Next</span>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={faChevronRight}
            className={classNames([sizes[size] || sizes.md])}
          />
        </button>
      </nav>
    </div>
  );
};

Pagination.Buttons = PaginationButtons;

export default Pagination;
