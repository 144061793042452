import { useClickAway, usePrevious } from "react-use";
import { Input, Tooltip, Modal } from "app/components/generics";
import classNames from "classnames";
import {
  THEME_DARK_BACKGROUND_BODY,
  THEME_DEFAULT_DARK_TEXT_COLOR,
  THEME_DEFAULT_DISABLED_TEXT,
  THEME_DEFAULT_ERROR_TEXT,
  THEME_DEFAULT_ERROR_TEXT_WITH_HOVER,
  THEME_DEFAULT_HOVER,
  THEME_DEFAULT_INFO_TEXT,
  THEME_DEFAULT_INFO_TEXT_WITH_HOVER,
  THEME_DEFAULT_PRIMARY_TEXT,
  THEME_DEFAULT_PRIMARY_TEXT_WITH_HOVER,
  THEME_DEFAULT_SECONDARY_TEXT,
  THEME_DEFAULT_SUCCESS_TEXT,
  THEME_DEFAULT_SUCCESS_TEXT_WITH_HOVER,
  THEME_DEFAULT_TEXT_COLOR,
  THEME_DEFAULT_TEXT_COLOR_INVERTED,
  THEME_DEFAULT_WARNING_TEXT,
  THEME_DEFAULT_WARNING_TEXT_WITH_HOVER,
} from "constants/theme.contants";
import React, { CSSProperties } from "react";
import { isEmpty, onEnterOrEscapeKeyPress } from "utils/functions.utils";
import { copyTextToClipboard } from "utils/general.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
type Types =
  | "secondary"
  | "primary"
  | "success"
  | "warning"
  | "error"
  | "info"
  | "link"
  | "disabled"
  | undefined;
type Sizes =
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";
type Alignments = "left" | "right" | "center";
type FontWeights = "bold" | "medium" | "light";

interface ITypographyProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  align?: Alignments;
  children?: any;
  as?: keyof HTMLElementTagNameMap;
  size?: Sizes;
  type?: Types;
  ellipsis?: boolean;
  invert?: boolean;
  darkText?: boolean;
  block?: boolean;
  help?: boolean;
  noFocus?: boolean;
  bold?: boolean;
  underline?: boolean;
  wordWrap?: boolean;
  noWrap?: boolean;
  overflow?: boolean;
  inline?: boolean;
  flat?: boolean;
  copy?: boolean;
  button?: boolean;
  gutter?: boolean;
  hover?: boolean;
  hideEditButton?: boolean;
  copyText?: string;
  fontWeight?: FontWeights;
  href?: string;
  editable?: IEditable;
  inputProps?: IInputProps;
}

interface IInputProps {
  rows?: number | { min?: number; max?: number };
  style?: CSSProperties;
}

export interface IEditable {
  editing?: boolean;
  placeholder?: string;
  controlled?: boolean;
  maxLength?: number;
  onStart?: Function;
  onChange?: (value: string) => void;
  onCancel?: Function;
  onEnd?: Function;
  // editingSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

interface ICompoundedComponent
  extends React.ForwardRefExoticComponent<
    ITypographyProps & React.RefAttributes<HTMLInputElement>
  > {
  Paragraph: any;
  Code: any;
}

const types = (hover) => ({
  secondary: hover
    ? THEME_DEFAULT_SECONDARY_TEXT
    : THEME_DEFAULT_SECONDARY_TEXT,
  primary: hover
    ? THEME_DEFAULT_PRIMARY_TEXT_WITH_HOVER
    : THEME_DEFAULT_PRIMARY_TEXT,
  link: hover
    ? THEME_DEFAULT_PRIMARY_TEXT_WITH_HOVER
    : THEME_DEFAULT_PRIMARY_TEXT,
  success: hover
    ? THEME_DEFAULT_SUCCESS_TEXT_WITH_HOVER
    : THEME_DEFAULT_SUCCESS_TEXT,
  warning: hover
    ? THEME_DEFAULT_WARNING_TEXT_WITH_HOVER
    : THEME_DEFAULT_WARNING_TEXT,
  error: hover ? THEME_DEFAULT_ERROR_TEXT_WITH_HOVER : THEME_DEFAULT_ERROR_TEXT,
  info: hover ? THEME_DEFAULT_INFO_TEXT_WITH_HOVER : THEME_DEFAULT_INFO_TEXT,
});

const fontWeights = {
  light: "font-light",
  medium: "font-medium",
  bold: "font-bold",
};

const sizes = {
  xxs: "text-xxs",
  xs: "text-xs",
  sm: "text-xs",
  md: "text-sm",
  lg: "text-base",
  xl: "text-lg",
  "2xl": "text-2xl",
  "3xl": "text-3xl",
  "4xl": "text-4xl",
  "5xl": "text-5xl",
  "6xl": "text-6xl",
  "7xl": "text-7xl",
  "8xl": "text-8xl",
  "9xl": "text-9xl",
};

const lineHeights = {
  xxs: "leading-3",
  xs: "leading-5",
  sm: "leading-6",
  md: "leading-8",
  lg: "leading-10",
  xl: "leading-10",
  "2xl": "leading-11",
  "3xl": "leading-11",
  "4xl": "leading-11",
  "5xl": "leading-11",
  "6xl": "leading-11",
  "7xl": "leading-11",
  "8xl": "leading-11",
  "9xl": "leading-11",
};

const defaultEditing: Partial<IEditable> = {
  editing: false,
};

const Typography = React.forwardRef<
  React.HTMLProps<HTMLSpanElement>,
  ITypographyProps
>((props, ref) => {
  const {
    as,
    children,
    className = "",
    type,
    darkText,
    noWrap,
    inline,
    flat,
    size = "md",
    ellipsis,
    bold,
    help,
    hover,
    disabled,
    noFocus,
    invert,
    wordWrap,
    copy,
    copyText,
    overflow,
    gutter,
    button,
    underline,
    editable,
    block,
    hideEditButton,
    ...rest
  } = props;
  let Element: any = "span";
  let fontColorClass = invert
    ? THEME_DEFAULT_TEXT_COLOR_INVERTED
    : THEME_DEFAULT_TEXT_COLOR;
  let fontSize = sizes[size] || sizes.md;
  let lineHeight = button || flat ? "" : lineHeights[size] || lineHeights.md;
  let truncate = "";
  let fontWeight = "font-normal";
  let EllipsisWrapper: any = React.Fragment;
  const inputRef = React.useRef(null);
  const [animating, setAnimating] = React.useState(false);
  const prevEditing = usePrevious((editable as IEditable)?.editing);
  const [editProps, setEditProps] = React.useState<Partial<IEditable>>();
  const [editingInput, setEditingInput] = React.useState<string | undefined>(
    typeof children == "string" ? children : ""
  );
  const [confirmExternalLink, setConfirmExternalLink] =
    React.useState<string>("");
  const [openExternalLinkModal, setOpenExternalLinkModal] =
    React.useState<boolean>(false);

  let isExternalLink = false;
  if (rest.href && !rest.href?.includes(window.location.host)) {
    isExternalLink = true;
  }

  React.useEffect(() => {
    if (editable) {
      setEditProps(editable);
    }
  }, [editable]);

  // useDebounce(() => {
  //     setAnimating(false);
  // }, 450, [animating]);

  useClickAway(inputRef, () => {
    editProps?.onCancel?.();
    handleUncontrolledEditing(false);
  });

  if (as && typeof as == "string") {
    Element = as;
  }

  if (editable) {
    Element = "div";
  }

  if (noFocus) {
    rest.tabIndex = -1;
  }

  if (type == "link" || button || rest.href) {
    // if (type == 'link' || copy || button || rest.href) {
    if (rest.href) {
      Element = "a";
      rest.target = "_blank";
      // rest.rel = 'noopener noreferrer';
      if (
        rest.href &&
        !rest.href?.startsWith("/") &&
        !rest.href?.includes(window.location.host) &&
        !rest.download
      ) {
        rest.target = undefined;
        const url = rest.href;
        // @ts-ignore
        rest.onClick = () => {
          setOpenExternalLinkModal(true);
          setConfirmExternalLink(url);
          return false;
        };
        rest.href = undefined;
      }
    }
    if (!type) {
      fontColorClass = types(false).primary;
    }
  }

  if (type && types(hover)[type]) {
    fontColorClass = types(hover)[type];
  }

  if (disabled) {
    fontColorClass = THEME_DEFAULT_DISABLED_TEXT;
  }

  if (darkText && !disabled) {
    fontColorClass = THEME_DEFAULT_DARK_TEXT_COLOR;
  }

  if (ellipsis) {
    truncate = "truncate";
    if (typeof children == "string") {
      EllipsisWrapper = () => <Tooltip content={children}>{children}</Tooltip>;
    }
  }

  if (fontWeight && fontWeights[fontWeight]) {
    fontWeight = fontWeights[fontWeight];
  } else if (bold) {
    fontWeight = fontWeights.bold;
  }

  if (!prevEditing && (editable as IEditable)?.editing) {
    (editable as IEditable)?.onStart?.();
  }

  if (prevEditing && !(editable as IEditable)?.editing) {
    (editable as IEditable)?.onEnd?.();
  }

  const handleUncontrolledEditing = (editing) => {
    if (!editable?.controlled) {
      setEditProps({ ...editProps, editing });
    }
  };

  return (
    <>
      {editProps?.editing ? (
        <Input
          ref={inputRef}
          size={(size as any) || "md"}
          insetMaxLength
          autoFocus
          block
          onChange={(e) => {
            const value = e.target.value;
            setEditingInput(value);
            editProps?.onChange?.(value);
          }}
          maxLength={editProps?.maxLength}
          defaultValue={typeof children == "string" ? children : undefined}
          onKeyUp={onEnterOrEscapeKeyPress({
            onEnter: (e) => {
              editProps?.onEnd?.(e);
              handleUncontrolledEditing(false);
            },
            onEscape: (e) => {
              editProps?.onCancel?.(e);
              setEditingInput(typeof children == "string" ? children : "");
              handleUncontrolledEditing(false);
            },
          })}
          footer={null}
        />
      ) : (
        <Element
          ref={ref}
          className={classNames([
            `${fontSize} ${fontColorClass} ${truncate} ${className}`,
            // lineHeight,
            fontWeight,
            underline ? "underline" : "",
            type == "link" ? "hover:underline active:underline" : "",
            // NOTE: (8/31/2022) Changed from block to inline-block, check back if issues come up [Caused issues]
            copy
              ? "relative cursor-pointer flex flex-1 justify-between group "
              : !inline
              ? "block"
              : "",
            gutter ? "mb-1.5" : "",
            inline ? "max-w-fit" : "",
            (button || !!rest?.onClick) && !disabled ? "cursor-pointer" : "",
            disabled
              ? `pointer-events-none ${THEME_DEFAULT_DISABLED_TEXT}`
              : "",
            editProps && hideEditButton
              ? `${THEME_DEFAULT_HOVER} px-1 border border-transparent dark:hover:border-gray-800 rounded cursor-text`
              : "",
            editProps && !hideEditButton ? "flex" : "",
            help ? "cursor-help" : "",
            wordWrap ? "break-words whitespace-pre-wrap" : "",
            wordWrap && copy
              ? "hover:text-opacity-20 hover:dark:text-opacity-20"
              : "",
            noWrap ? "whitespace-nowrap" : "",
            block ? "w-full" : "",
            overflow ? "overflow-auto" : "",
            ellipsis ? "truncate" : "",
          ])}
          title={(ellipsis && children) || undefined}
          {...rest}
          onClick={(e) => {
            if (!animating) {
              setAnimating(true);
              if (copy) {
                const text = copyText
                  ? copyText
                  : typeof children == "string"
                  ? children
                  : Array.isArray(children)
                  ? children?.join("")
                  : "";
                copyTextToClipboard(text);
              }
            }
            if (editProps && hideEditButton) {
              handleUncontrolledEditing(true);
            }
            rest?.onClick && rest?.onClick?.(e);
            if (isExternalLink) {
              return false;
            }
          }}
        >
          {!isEmpty(children) ? (
            children
          ) : editProps?.placeholder ? (
            <Typography flat size={size} type="secondary">
              {editProps?.placeholder}
            </Typography>
          ) : null}
          {editProps && !hideEditButton && (
            <div
              className="ml-2 cursor-pointer"
              onClick={() => handleUncontrolledEditing(true)}
            >
              <span>
                <FontAwesomeIcon icon={faEdit} />
              </span>
            </div>
          )}
          {/* <EllipsisWrapper>
                        { children }
                    </EllipsisWrapper> */}
          {copy && (
            <div
              className={classNames([
                "absolute",
                fontColorClass,
                wordWrap
                  ? "top-0 bottom-0 right-0 left-0 group-hover:flex items-center justify-center"
                  : "group-hover:block top-0 bottom-0 -right-10",
                animating && wordWrap ? "" : animating ? "-right-12" : "hidden",
              ])}
            >
              <span
                onAnimationEnd={() => setAnimating(false)}
                className={classNames([
                  `${animating ? "animate-fade-out-up" : ""} select-none`,
                  types(false).primary,
                ])}
              >
                {animating ? "Copied" : "Copy"}
              </span>
            </div>
          )}
        </Element>
      )}
      {confirmExternalLink && (
        <Modal.ExternalLink
          open={openExternalLinkModal}
          onCancel={() => {
            setOpenExternalLinkModal(false);
          }}
          afterClose={() => setConfirmExternalLink("")}
          url={confirmExternalLink}
        />
      )}
    </>
  );
}) as ICompoundedComponent;

const Paragraph = React.forwardRef<
  React.HTMLProps<HTMLParagraphElement>,
  ITypographyProps & { preserve?: boolean }
>((props, ref) => {
  const {
    children,
    size,
    bold,
    className,
    preserve,
    ellipsis,
    style = {},
    editable,
    wordWrap,
    type,
    hover,
    block,
    disabled,
    hideEditButton,
    onClick,
    inputProps = {},
    ...rest
  } = props;

  let fontWeight = "font-normal";
  let fontSize = sizes[size!] || sizes.md;
  let EllipsisWrapper: any = "div";
  let truncate = "";
  let fontColorClass = "";
  let Element: any = "p";

  const inputRef = React.useRef(null);
  const prevEditing = usePrevious((editable as IEditable)?.editing);
  const [editProps, setEditProps] = React.useState<Partial<IEditable>>();
  const [editingInput, setEditingInput] = React.useState<string | undefined>(
    typeof children == "string" ? children : ""
  );

  React.useEffect(() => {
    if (editable) {
      setEditProps(editable);
    }
  }, []);

  useClickAway(inputRef, () => {
    editProps?.onCancel?.();
    handleUncontrolledEditing(false);
  });

  const handleUncontrolledEditing = (editing) => {
    if (!editable?.controlled) {
      setEditProps({ ...editProps, editing });
    }
  };

  if (editable) {
    Element = "div";
  }

  if (bold) {
    fontWeight = fontWeights.bold;
  }

  if (type && types(hover)[type]) {
    fontColorClass = types(hover)[type];
  }

  const [showMore, setShowMore] = React.useState(false);

  if (ellipsis) {
    truncate = "truncate";
    if (typeof children == "string") {
      EllipsisWrapper = Tooltip;
      // ({ children: subChildren }) => (
      //     <Tooltip content={children}>
      //         {subChildren}
      //     </Tooltip>
      // );
      // @ts-ignore
      rest.content = { children };
    }
    style.display = "-webkit-box";
    style.WebkitLineClamp = "4";
    style.WebkitBoxOrient = "vertical";
    style.overflow = "hidden";
  }

  return (
    // <Tooltip
    //     content={typeof children == 'string' && ellipsis ? children : undefined}
    //     style={{ maxWidth: 300 }}
    //     disabled={!!editProps?.editing}
    // >
    // {
    editProps?.editing ? (
      <Input.TextArea
        ref={inputRef}
        {...(inputProps as any)}
        size={(size as any) || "md"}
        autoFocus
        block
        onChange={(e) => {
          const value = e.target.value;
          setEditingInput(value);
          editProps?.onChange?.(value);
        }}
        maxLength={editProps?.maxLength}
        defaultValue={typeof children == "string" ? children : undefined}
        onKeyUp={onEnterOrEscapeKeyPress(
          {
            onEnter: (e) => {
              editProps?.onEnd?.(e);
              handleUncontrolledEditing(false);
            },
            onEscape: (e) => {
              editProps?.onCancel?.(e);
              setEditingInput(typeof children == "string" ? children : "");
              handleUncontrolledEditing(false);
            },
          },
          { preventOnShiftKeyPress: true }
        )}
        footer={editProps?.maxLength ? true : null}
      />
    ) : EllipsisWrapper ? (
      <EllipsisWrapper
        content={children + ""}
        // disabled={!!editProps?.editing}
        disabled={true} //NOTE: 4-20-2022 Disabled for now
        style={style}
      >
        <Element
          className={classNames([
            fontWeight,
            fontSize,
            fontColorClass,
            type == "link" ? "hover:underline active:underline" : "",
            disabled
              ? `pointer-events-none ${THEME_DEFAULT_DISABLED_TEXT}`
              : "",
            editProps && hideEditButton
              ? `${THEME_DEFAULT_HOVER} px-1 border border-transparent dark:hover:border-gray-800 rounded cursor-text`
              : "",
            editProps && !hideEditButton ? "flex" : "",
            block ? "w-full" : "",
            className,
            preserve ? "whitespace-pre-wrap" : "",
          ])}
          style={style}
          {...rest}
          onClick={(e) => {
            if (editProps && hideEditButton) {
              handleUncontrolledEditing(true);
            }
            onClick?.(e);
          }}
          {...rest}
        >
          {!!children ? (
            children
          ) : editProps?.placeholder ? (
            <Typography flat size={size} type="secondary">
              {editProps?.placeholder}
            </Typography>
          ) : null}
          {editProps && !hideEditButton && (
            <div
              className="ml-2 cursor-pointer"
              onClick={() => handleUncontrolledEditing(true)}
            >
              <Typography flat size={size} type={"primary"}>
                <FontAwesomeIcon icon={faEdit} />
              </Typography>
            </div>
          )}
        </Element>
      </EllipsisWrapper>
    ) : (
      <Element
        className={classNames([
          fontWeight,
          fontSize,
          fontColorClass,
          type == "link" ? "hover:underline active:underline" : "",
          disabled ? `pointer-events-none ${THEME_DEFAULT_DISABLED_TEXT}` : "",
          editProps && hideEditButton
            ? `${THEME_DEFAULT_HOVER} px-1 border border-transparent dark:hover:border-gray-800 rounded cursor-text`
            : "",
          editProps && !hideEditButton ? "flex" : "",
          block ? "w-full" : "",
          className,
          preserve ? "whitespace-pre-wrap" : "",
        ])}
        style={style}
        {...rest}
        onClick={(e) => {
          if (editProps && hideEditButton) {
            handleUncontrolledEditing(true);
          }
          onClick?.(e);
        }}
        {...rest}
      >
        {!!children ? (
          children
        ) : editProps?.placeholder ? (
          <Typography flat size={size} type="secondary">
            {editProps?.placeholder}
          </Typography>
        ) : null}
        {editProps && !hideEditButton && (
          <div
            className="ml-2 cursor-pointer"
            onClick={() => handleUncontrolledEditing(true)}
          >
            <Typography flat size={size} type={type || "primary"}>
              <FontAwesomeIcon icon={faEdit} />
            </Typography>
          </div>
        )}
      </Element>
    )
  );
});

const Code = React.forwardRef<
  React.HTMLProps<HTMLSpanElement>,
  ITypographyProps & { color?: string }
>((props, ref) => {
  const {
    children,
    size,
    bold,
    className,
    ellipsis,
    type,
    copy,
    flat,
    hover,
    onClick,
    color,
    copyText,
    ...rest
  } = props;

  let fontWeight = "font-normal";
  let fontColorClass = THEME_DEFAULT_SECONDARY_TEXT;
  let fontSize = sizes[size!] || sizes.md;
  let truncate = "";
  const style: CSSProperties = {};
  // let lineHeight = lineHeights[size!] || lineHeights.md;
  let lineHeight = flat ? "" : lineHeights[size!] || lineHeights.md;

  const [animating, setAnimating] = React.useState(false);

  // useDebounce(() => {
  //     setAnimating(false);
  // }, 450, [animating]);

  if (type && types(hover)[type]) {
    fontColorClass = types(hover)[type];
  }

  if (bold) {
    fontWeight = fontWeights.bold;
  }

  if (color) {
    style.backgroundColor = color;
  }

  if (ellipsis) {
    truncate = "truncate";
  }

  return (
    <span
      className={classNames([
        fontWeight,
        className,
        fontColorClass,
        fontSize,
        "bg-gray-100",
        !truncate ? "inline-block" : "block", //NOTE: (6/17/2022) Added to fill vertial space, keep an eye on it
        THEME_DARK_BACKGROUND_BODY,
        THEME_DEFAULT_SECONDARY_TEXT,
        truncate,
        lineHeight,
        "px-1.5 shadow-inner rounded-sm relative group",
        copy ? "cursor-pointer" : "",
        // 'py-0.5', //NOTE: (6/10/2022) Check back on this
      ])}
      style={style}
      onClick={(e) => {
        if (!animating && copy) {
          setAnimating(true);
          const text = copyText
            ? copyText
            : typeof children == "string"
            ? children
            : "";
          copyTextToClipboard(text);
        }
        // @ts-ignore
        onClick && onClick(e);
      }}
      {...rest}
    >
      {children}
      {copy && (
        // <div className='absolute top-0 bottom-0 -right-10'>
        <div
          className={classNames([
            "hidden group-hover:block absolute top-0 bottom-0 right-0",
            animating ? "" : "hidden",
            // animating ? '-right-12' : '-right-10',
          ])}
        >
          <span
            onAnimationEnd={() => setAnimating(false)}
            className={`${animating ? "animate-fade-out-up" : ""} select-none ${
              types(false).primary
            } font-bold`}
          >
            {animating ? "Copied" : "Copy"}
          </span>
        </div>
      )}
    </span>
  );
});

// @ts-ignore
Typography.Paragraph = Paragraph;
// @ts-ignore
Typography.Code = Code;

export { Typography };
