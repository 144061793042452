import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    height: ${({ size }) => {
        switch(size) {
            case 'xs':
                return '12px';
            case 'md':
                return '16px';
            case 'lg':
                return '20px';
            case 'xl':
                return '24px';
            default:
                return '16px';
        }
    }};
    width: ${({ size }) => {
        switch(size) {
            case 'xs':
                return '12px';
            case 'md':
                return '16px';
            case 'lg':
                return '20px';
            case 'xl':
                return '24px';
            default:
                return '16px';
        }
    }};
`

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface IButtonLoader {
    size: SizeType;
    gutter?: boolean;
}

const sizes = {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
}

export const ButtonLoader = (props: Partial<IButtonLoader>) => {
    const { size = 'md', gutter = true } = props;
    const style = {
        width: sizes[size],
        height: sizes[size],
    }
    
    return (
        <Root size={size} className={gutter ? 'mr-2' : ''} style={style}>
            <svg className='animate-spin w-full h-full' style={{ ...style, color: 'inherit', }} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
            </svg>
        </Root>
    )
}