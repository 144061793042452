import React from "react";
import { LocalStorageService } from "services/LocalStorage.service";
import { io, Socket } from "socket.io-client";

interface IuseSocket {
  socket: Socket;
  connected: boolean;
}
const INTERVAL_TIMER = 1000; // Every 30 seconds
const SocketContext = React.createContext({} as any);
let intervalId, prevToken;
export const SocketContextProvider = ({ children, value = {} }) => {
  const [socket, setSocket] = React.useState<Socket>();
  const [connected, setConnected] = React.useState<boolean>(false);

  React.useEffect(() => {
    const storageEventHandler: any = () => {
      handleSocketRefresh();
    };
    if (window) {
      window.addEventListener("storage", storageEventHandler);
    }
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  React.useEffect(() => {
    if (!socket?.connected) {
      handleSocketCreation();
    }
  }, []);

  const handleSocketCreation = () => {
    const token = LocalStorageService.getAccessToken();
    const wsServerUrl =
      process.env.REACT_APP_ENV == "prod"
        ? window.location.origin
        : process.env.REACT_APP_API_BASE_URL;
    const socket = io(wsServerUrl!, {
      auth: { token },
      transports: ["websocket"],
    });
    setSocket(socket);
  };

  const handleSocketRefresh = () => {
    const token = LocalStorageService.getAccessToken();
    if (!token || !socket) return;
    socket.auth = { token };
    socket.on("connect", () => setConnected(true));
    socket.on("reconnect", () => setConnected(true));

    socket.on("disconnect", (reason) => {
      setConnected(false);
    });
    setSocket(socket);
  };

  return (
    <SocketContext.Provider
      value={{
        ...value,
        socket,
        connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket(): IuseSocket {
  const { socket, connected } = React.useContext(SocketContext);
  return {
    socket,
    connected,
  };
}
