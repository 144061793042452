import { useApi } from "hooks/api.hook";
import React from "react";
import { useAuth } from "services/auth";
import { ISettings } from "shared/interfaces/app-settings.interface";
import { ITypeAcasPluginSeverity } from "shared/interfaces/type-acas-plugin-severity.interface";
import { ITypeCompliance } from "shared/interfaces/type-compliance.interface";
import { ITypeDocument } from "shared/interfaces/type-document.interface";
import { ITypeNotification } from "shared/interfaces/type-notification.interface";
import { ITypeOperatingSystem } from "shared/interfaces/type-opertating-system.interface";
import { ITypeRemediationStatus } from "shared/interfaces/type-remediation-status.interface";
import { ITypeStigResultCheck } from "shared/interfaces/type-stig-result-check.interface";
import { ITypeStigResultStatus } from "shared/interfaces/type-stig-result-status.interface";
import { ITypeStigRuleSeverity } from "shared/interfaces/type-stig-rule-severity.interface";
import { ITypeUserRole } from "shared/interfaces/type-user-role.interface";
import { ApiRoutes } from "shared/routes/api";
import * as lookupTypes from "shared/routes/api/api-lookup-types";
import {
  mapArrayOfObjectsToObjectWithPropAsKey,
  maybeObject,
} from "utils/functions.utils";

const apiRoutes = new ApiRoutes();

const AppSettingsContext = React.createContext({} as any);

interface ILookupTypes {
  [lookupTypes.TYPE_ACAS_PLUGIN_SEVERITY]: ITypeAcasPluginSeverity[];
  [lookupTypes.TYPE_COMPLIANCE]: ITypeCompliance[];
  [lookupTypes.TYPE_OPERATING_SYSTEM]: ITypeOperatingSystem[];
  [lookupTypes.TYPE_STIG_RESULT_STATUS]: ITypeStigResultStatus[];
  [lookupTypes.TYPE_EStigRuleSeverity]: ITypeStigRuleSeverity[];
  [lookupTypes.TYPE_DOCUMENT]: ITypeDocument[];
  [lookupTypes.TYPE_NOTIFICATION]: ITypeNotification[];
  [lookupTypes.TYPE_REMEDIATION_STATUS]: ITypeRemediationStatus[];
  [lookupTypes.TYPE_STIG_RESULT_CHECK]: ITypeStigResultCheck[];
  [lookupTypes.TYPE_USER_ROLE]: ITypeUserRole[];
}

interface IAppSettings {
  settings: ISettings;
  types: ILookupTypes;
  loading: boolean;
  updating: boolean;
  reload: Function;
  update: (settings: { [x: string]: any }) => Promise<void>;
  updateState: (settings: IAppSettings[]) => void;
}

export const AppSettingsContextProvider = ({ children }) => {
  const AppSettingsService = useApi(
    apiRoutes.appSettings.toURL,
    { load: true },
    { data: [] }
  );
  const TypesService = useApi(apiRoutes.typesAll.toURL, {}, { data: [] });
  const [settingsState, setSettingsState] = React.useState({});
  const [typesState, setTypesState] = React.useState({});

  const { me } = useAuth();

  React.useEffect(() => {
    if (me) {
      AppSettingsService.get();
      TypesService.get();
    }
  }, [me]);

  React.useEffect(() => {
    if (AppSettingsService?.data) {
      const settings = AppSettingsService?.data?.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {});
      setSettingsState(settings);
    }
  }, [AppSettingsService?.data]);

  React.useEffect(() => {
    if (TypesService?.data) {
      setTypesState(TypesService?.data);
    }
  }, [TypesService?.data]);

  const handleReloadSettings = async () => {
    try {
      await AppSettingsService.get();
    } catch (error) {}
  };

  const updateState = async (newSettings) => {
    const settings = mapArrayOfObjectsToObjectWithPropAsKey(
      newSettings,
      "name"
    );
    setSettingsState(settings);
  };

  const handleUpdateSettings = async (settings: {
    [x: string]: string;
    value: any;
  }) => {
    try {
      return await AppSettingsService.update(settings);
    } catch (error) {}
  };

  return (
    <AppSettingsContext.Provider
      value={{
        updateState,
        settings: { ...maybeObject(settingsState) },
        types: { ...maybeObject(typesState) },
        loading: AppSettingsService.loading,
        updating: AppSettingsService.updating,
        reload: handleReloadSettings,
        update: handleUpdateSettings,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export function useAppSettings(): IAppSettings {
  const settings = React.useContext(AppSettingsContext);
  return { ...settings };
}
