import { Typography } from 'app/components/generics/Typography';
import classNames from 'classnames';
import React from 'react';
import { addAlpha } from 'utils/functions.utils';

type Types = 'secondary' | 'primary' | 'success' | 'warning' | 'error' | 'info';

interface IBadgeProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
    // align?: Alignments;
    // children?: any;
    // as?: keyof HTMLElementTagNameMap;
    // size?: Sizes;
    type?: Types;
    caps?: boolean;
    block?: boolean;
    bgColor?: string;
    dot?: boolean;
    rounded?: boolean;
    size?: 'sm' | 'lg';
    onDelete?: Function,
    subtitle?: string;
}

const colors = {
    primary: 'blue',
    success: 'green',
    warning: 'yellow',
    error: 'red',
    // primary: 'border border-blue-400 bg-blue-200 text-blue-600 dark:border-blue-600 dark:bg-blue-300 dark:text-blue-700',
    // success: 'border border-green-400 bg-green-200 text-green-600 dark:border-green-600 dark:bg-green-300 dark:text-green-700',
    // warning: 'border border-yellow-400 bg-yellow-200 text-yellow-600 dark:border-yellow-600 dark:bg-yellow-300 dark:text-yellow-700',
    // error: 'border border-red-400 bg-red-200 text-red-600 dark:border-red-600 dark:bg-red-300 dark:text-red-700',
}

const Tag = (props: IBadgeProps) => {
    const { 
        children,
        style = {},
        className = '',
        type,
        caps,
        dot,
        block,
        bgColor,
        rounded,
        size = 'sm',
        onDelete,
        subtitle,
    } = props;

    const generateColorScheme = (color: string) => `bg-${color}-200 text-${color}-600 dark:bg-${color}-300 dark:text-${color}-700`;
    // const generateColorScheme = (color: string) => `border border-${color}-400 bg-${color}-200 text-${color}-600 dark:border-${color}-600 dark:bg-${color}-300 dark:text-${color}-700`;
    let colorScheme = `bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-white`;
    // let colorScheme = `bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200`;
    // let colorScheme = `border border-gray-400 bg-gray-200 text-gray-800 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200`;
    let color = 'gray';
    let spacing = 'px-2 py-0.5';
    let textSize = 'text-xs';

    if (type && colors[type]) {
        colorScheme = generateColorScheme(colors[type]);
        color = colors[type];
    }

    if (size == 'lg') {
        spacing = 'px-2.5 py-0.5';
        textSize = 'text-sm';
    }

    if (onDelete) {
        if (size == 'lg') {
            spacing = 'py-0.5 pl-2.5 pr-1';
        } else {
            spacing = 'py-0.5 pl-2 pr-0.5';
        }
    }

    return (
        <span 
            className={classNames([
                'inline-flex space-x-2 items-center justify-center',
                `rounded${rounded ? '-full' : '-sm'}`, 
                'font-medium',
                block ? 'w-full' : '',
                spacing,
                textSize,
                colorScheme,
                className,
            ])} 
            style={{ textTransform: caps ? 'uppercase' : undefined, backgroundColor: bgColor, ...style }}
        >
            {
                dot && (
                    <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${color == 'gray' ? `${color}-400` : `${color}-600`}`} fill='currentColor' viewBox='0 0 8 8'>
                        <circle cx={4} cy={4} r={3} />
                    </svg>
                )
            }
            <div className='flex flex-col space-y-1'>
                {subtitle && (
                    <Typography type='secondary' size='xs'>
                        {subtitle}
                    </Typography>
                )}
                { children }
            </div>
            {
                onDelete && (
                    <button
                        type='button'
                        // @ts-ignore
                        onClick={onDelete}
                        className={classNames([
                            'flex-shrink-0',
                            'h-4',
                            'w-4',
                            'rounded-full',
                            'inline-flex',
                            'items-center',
                            'justify-center',
                            `hover:bg-${color}-50`,
                            `dark:hover:bg-${color}-400`,
                            `focus:bg-${color}-500`,
                            'focus:outline-none',
                            'focus:stroke-white',
                            'hover:bg-opacity-30',
                            'dark:hover:bg-opacity-30',
                        ])}
                    >
                        <span className='sr-only'>Remove tag</span>
                        <svg className='h-2 w-2' stroke='currentColor' fill='none' viewBox='0 0 8 8'>
                            <path strokeLinecap='round' strokeWidth='1.5' d='M1 1l6 6m0-6L1 7' />
                        </svg>
                    </button>
                )
            }
        </span>
    )
}

const Group = ({ children }) => {
    return (
        <div className='space-x-2'>
            {children}
        </div>
    )
}

Tag.Group = Group;

export {Tag}