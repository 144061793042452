import classNames from "classnames";
import { Input as GenericInput } from "../Input";

interface ISelectNative
  extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, "size"> {
  label?: string;
  hint?: string;
  labelFor?: string;
  block?: boolean;
  defaultValue?: any;
  options?: any[];
  size?: "sm" | "md" | "lg";
}

const DEFAULT_NATIVE_SIZE = "pl-3 pr-7 py-1.5 text-sm";

const sizes = {
  sm: "pl-3 pr-7 py-0.5 text-xs",
  md: DEFAULT_NATIVE_SIZE,
  lg: "pl-3 pr-7 py-2 text-base",
};

export default (props: ISelectNative) => {
  const {
    size,
    className,
    defaultValue,
    value,
    onChange,
    label,
    labelFor,
    hint,
    options = [],
    block,
    placeholder,
    ...rest
  } = props;
  let selectSize = sizes.md;
  let classSize = "size-medium";

  if (size == "sm") {
    classSize = "size-small";
  } else if (size == "lg") {
    classSize = "size-large";
  }

  if (size && sizes[size]) {
    selectSize = sizes[size];
  }

  return (
    <div className={classNames([className])}>
      {(label || hint) && (
        <div className="flex justify-between mb-1">
          {label && (
            <GenericInput.Label labelFor={labelFor || label}>
              {label}
            </GenericInput.Label>
          )}
          {hint && <GenericInput.Hint>{hint}</GenericInput.Hint>}
        </div>
      )}
      <div
        className={classNames([
          "border border-gray-300 dark:border-gray-700 rounded inline-block",
          block ? "w-full" : "w-24",
          classSize,
        ])}
      >
        <select
          className={classNames([
            selectSize,
            "rounded",
            "block",
            block ? "w-full" : "w-24",
            "text-sm bg-white dark:bg-transparent",
            "text-gray-800 dark:text-gray-200",
          ])}
          style={{ backgroundColor: rest.disabled ? "#334155" : undefined }}
          onChange={onChange}
          defaultValue={defaultValue || undefined}
          value={value || undefined}
          {...rest}
        >
          {placeholder && (
            <option
              key="placeholder"
              className="text-gray-800 bg-white dark:bg-gray-700 dark:text-gray-200"
              value=""
            >
              {placeholder}
            </option>
          )}
          {options.map((v, i) => (
            <option
              className="text-gray-800 bg-white dark:bg-gray-700 dark:text-gray-200"
              key={i}
              value={v?.value ?? v}
            >
              {v?.label ?? v}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
