import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { ButtonLoader } from 'app/components/generics/Loader';
import { DARK_THEME_DEFAULT_WEIGHT, LIGHT_THEME_DEFAULT_WEIGHT } from 'constants/color-weights.contants';
import { THEME_DEFAULT_WEIGHT, THEME_ERROR, THEME_INFO, THEME_PRIMARY, THEME_SECONDARY, THEME_SUCCESS, THEME_WARNING } from 'constants/theme.contants';
import { Typography } from 'app/components/generics/Typography';
import { TypeShadowSize } from 'shared/types/shadow-size.type';
import { useMountedState, useUpdateEffect } from 'react-use';
import { Input } from 'app/components/generics/Input';

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
type ButtonType = 'primary' | 'success' | 'secondary' | 'error' | 'warning' | 'info' | 'link' | undefined;
type ButtonShape = 'pill' | 'circle';

interface IBaseButton extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onClick'> {
    children?: string | React.ReactNode;
    icon?: React.ReactNode;
    size?: SizeType;
    type?: ButtonType | undefined;
    block?: boolean;
    loading?: boolean;
    fake?: boolean;
    disabled?: boolean;
    ghost?: boolean;
    noBubble?: boolean;
    htmlType?: string;
    align?: 'left' | 'center' | 'right';
    iconPosition?: 'left' | 'right';
    value?: any;
    href?: string;
    download?: string;
    innerRef?: any;
    shape?: ButtonShape;
    as?: string;
    elevation?: TypeShadowSize;
    onClick?: Function | React.MouseEventHandler<HTMLButtonElement> | undefined;
    [x:string]: any;
}

export interface IButton extends IBaseButton {}

interface IGroup extends IBaseButton {
    children: any;
    isEven?: boolean;
    label?: string;
    hint?: string;
    isOptional?: boolean;
    // value?: any;
    // size?: 'xs' | 'sm' | 'md' | 'lg';
    // disabled?: boolean;
}

interface ICompoundedComponent extends React.ForwardRefExoticComponent<IButton & React.RefAttributes<HTMLInputElement>> {
    Group: React.ForwardRefExoticComponent<IGroup & React.RefAttributes<HTMLInputElement>>
}

const pillSizes = {
    xs: 'w-14',
    sm: 'w-16',
    md: 'w-20',
    lg: 'w-24',
    xl: 'w-28',
}

const circleSizes = {
    xs: 20,
    sm: 24,
    md: 32,
    lg: 40,
    xl: 48,
}

const alignment = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
}

const paddingSizes = {
    // xs: 'px-1.5 py-0.5',
    xs: 'p-1.5',
    // sm: 'px-1.5 py-1',
    sm: 'px-2 py-1',
    // md: 'px-2 py-1',
    md: 'px-2 py-1',
    // lg: 'px-3 py-1',
    lg: 'px-2 py-1',
    // xl: 'px-3.5 py-2',
    xl: 'px-2.5 py-1.5',
}

const fontSizes = {
    xs: 'text-xs',
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
    xl: 'text-lg',
}

const heightSizes = {
    xs: 'h-5',
    sm: 'h-6',
    md: 'h-8',
    lg: 'h-10',
    xl: 'h-11',
}

const lineHeights = {
    xs: 'leading-5',
    sm: 'leading-6',
    md: 'leading-8',
    lg: 'leading-10',
    xl: 'leading-10',
}

const Button = React.forwardRef<React.HTMLProps<HTMLButtonElement>, IButton>((props, ref) => {
    const { 
        className, 
        size, 
        children, 
        type, 
        htmlType = 'button',
        block,
        loading,
        disabled,
        ghost,
        fake,
        iconPosition = 'left',
        href,
        download,
        elevation = 'sm',
        align = 'center',
        icon,
        innerRef = ref,
        shape,
        as,
        noBubble,
        onClick,
        isButtonGroup,
        ...rest
    } = props;

    const attributes: string[] = [];
    let bgColor: any = 'transparent';
    let bgColorClass: any = 'bg-transparent';
    // hover:text-gray-500 dark:hover:text-gray-400
    let fontColorClass: any = `text-gray-${LIGHT_THEME_DEFAULT_WEIGHT} dark:text-gray-${DARK_THEME_DEFAULT_WEIGHT}`;
    let borderColorClass: any = `border-gray-300 dark:border-gray-600`;
    let hoverBgColorClass: any = '';
    let hoverBorderColorClass: any = 'hover:border-gray-400 dark:hover:border-gray-700';
    let fullWidthClass: any = '';
    let color = 'gray';
    let addClasses: string[] = [
        'select-none',
    ];

    let Element = 'button';

    if (as) Element = as;

    if (href) {
        Element = 'a';
        rest.rel = 'noopener noreferrer';
    }
    if (fake) {
        Element = 'span';
    }

    const style: CSSProperties = {};

    const fontSize = fontSizes[size || 'md']
    const pillSize = pillSizes[size || 'md'];
    const circleSize = circleSizes[size || 'md'];
    const paddingSize = paddingSizes[size || 'md'];
    const heightSize = heightSizes[size || 'md'];
    const lineHeight = lineHeights[size || 'md'];

    // FULL WIDTH
    if (block) {
        fullWidthClass = 'w-full';
    }

    if (elevation) {
        addClasses.push(`shadow${elevation ? '-' + elevation : ''}`);
    }

    // SHAPE
    if (!isButtonGroup) {
        if (shape == 'pill') {
            addClasses.push(`rounded-full px-4 min-h-min min-w-max ${pillSize} ${paddingSize}`);
        } else if (shape == 'circle') {
            addClasses.push(`rounded-full`);
            style.height = circleSize;
            style.width = circleSize;
        } else {
            // addClasses.push(`rounded-sm ${paddingSize} ${heightSize}`);
            addClasses.push(`rounded-sm ${heightSize}`);
        }
    }

    // COLOR SELECTION
    switch (type) {
        case 'link':
            fontColorClass = THEME_PRIMARY;
            borderColorClass = 'border-transparent';
            break;
        case 'primary':
            color = THEME_PRIMARY;
            bgColorClass = `bg-blue-500`;
            fontColorClass = ghost ? `text-blue-400` : 'text-white';
            borderColorClass = `border-blue-500`;
            hoverBgColorClass = ghost ? 'transparent' : `hover:bg-blue-600`
            hoverBorderColorClass = ghost ? 'transparent' : `hover:border-blue-600`
            attributes.push(
                'focus:ring-blue-300',
                'dark:focus:ring-blue-600',
            )
            break;
        case 'success':
            color = THEME_SUCCESS;
            bgColorClass = `bg-green-500`;
            fontColorClass = ghost ? `text-green-400` : 'text-white';
            borderColorClass = `border-green-500`;
            hoverBgColorClass = ghost ? 'transparent' : `hover:bg-green-600`
            hoverBorderColorClass = ghost ? 'transparent' : `hover:border-green-600`
            attributes.push(
                'focus:ring-green-300',
                'dark:focus:ring-green-600',
            )
            break;
        case 'warning':
            color = THEME_WARNING;
            bgColorClass = `bg-yellow-500`;
            fontColorClass = ghost ? `text-yellow-400` : 'text-white';
            borderColorClass = `border-yellow-500`;
            hoverBgColorClass = ghost ? 'transparent' : `hover:bg-yellow-600`
            hoverBorderColorClass = ghost ? 'transparent' : `hover:border-yellow-600`
            attributes.push(
                'focus:ring-yellow-300',
                'dark:focus:ring-yellow-600',
            )
            break;
        case 'error':
            color = THEME_ERROR;
            bgColorClass = `bg-red-500`;
            fontColorClass = ghost ? `text-red-400` : 'text-white';
            borderColorClass = `border-red-500`;
            hoverBgColorClass = ghost ? 'transparent' : `hover:bg-red-600`
            hoverBorderColorClass = ghost ? 'transparent' : `hover:border-red-600`
            attributes.push(
                'focus:ring-red-300',
                'dark:focus:ring-red-600',
            )
            break;
        case 'secondary':
            color = THEME_SECONDARY;
            bgColorClass = `dark:bg-slate-600 bg-slate-500`;
            fontColorClass = ghost ? `text-slate-400` : 'text-white';
            borderColorClass = `border-slate-500 dark:border-slate-600`;
            hoverBgColorClass = ghost ? 'transparent' : `hover:bg-gray-600 dark:hover:bg-slate-700`
            hoverBorderColorClass = ghost ? 'transparent' : `hover:border-slate-500 dark:hover:border-slate-700`
            attributes.push(
                'focus:ring-slate-300',
                'dark:focus:ring-slate-600',
            )
            break;
        case 'info':
            color = THEME_INFO;
            bgColorClass = `bg-cyan-500`;
            fontColorClass = ghost ? `text-cyan-400` : 'text-white';
            borderColorClass = `border-cyan-500`;
            hoverBgColorClass = ghost ? 'transparent' : `hover:bg-cyan-600`
            hoverBorderColorClass = ghost ? 'transparent' : `hover:border-cyan-600`
            attributes.push(
                'focus:ring-cyan-300',
                'dark:focus:ring-cyan-600',
            )
            break;
        default:
            break;
    }

    if (ghost) {
        bgColorClass = 'bg-transparent';
    } else if (!isButtonGroup) {
        addClasses.push('border')
    }
    
    if (ghost) {
    // if (ghost && !type) {
        bgColorClass = 'hover:bg-gray-500 dark:hover:bg-gray-200 hover:bg-opacity-10 dark:hover:bg-opacity-10 focus:bg-gray-300 dark:focus:bg-gray-200 hover:bg-opacity-10 dark:focus:bg-opacity-10';
        borderColorClass = 'border-transparent dark:border-transparent hover:border-transparent dark:hover:border-transparent';
        hoverBorderColorClass = '';
        // addClasses.push('bg-opacity-50 dark:bg-opacity-50');
    }

    // if (ghost && type) {
    //     bgColorClass = `hover:bg-gray-500 dark:hover:bg-gray-200 hover:bg-opacity-10 dark:hover:bg-opacity-10`;
    //     borderColorClass = 'border-transparent dark:border-transparent hover:border-transparent dark:hover:border-transparent';
    //     hoverBorderColorClass = '';
    //     // addClasses.push('bg-opacity-50 dark:bg-opacity-50');
    // }

    if (disabled) {
        bgColorClass = 'bg-gray-300 dark:bg-gray-600 opacity-50';
        fontColorClass = 'text-gray-600 dark:text-gray-300';
        borderColorClass = 'border-gray-300 dark:border-gray-600';
        hoverBgColorClass = '';
        hoverBorderColorClass = '';
    }

    return (
        // @ts-ignore
        <Element
            ref={innerRef}
            type={htmlType as any}
            disabled={disabled}
            style={style}
            {...(href ? { href, target: '_blank', download } : {})}
            className={
                classNames(
                    [
                        addClasses,
                        'no-print',
                        'cursor-pointer',
                        'items-center',
                        'flex',
                        !align ? 'justify-center' : alignment[align],
                        // 'border-transparent',
                        // 'font-medium',
                        // 'focus:outline-none',
                        // 'ring-offset-transparent',
                        // 'focus:ring-1',
                        // 'focus:ring-offset-1',
                        // `focus:ring-gray-300`,
                        // `dark:focus:ring-gray-700`,
                        !disabled ? 'hover:shadow-none dark:hover:shadow-none' : '',
                        // !disabled ? shadowClass : '',
                        lineHeight,
                        fontSize,
                        fullWidthClass,
                        bgColorClass,
                        fontColorClass,
                        borderColorClass,
                        hoverBgColorClass,
                        hoverBorderColorClass,
                        paddingSize,
                        disabled ? 'cursor-not-allowed' : '',
                        ...attributes,
                        className,
                    ],
                )
            }
            onClick={e => {
                if (noBubble) {
                    e.stopPropagation();
                }
                // @ts-ignore
                onClick && onClick(e)
            }}
            {...rest}
            // style={{ textTransform: 'uppercase' }}
        >
            {loading && (<><ButtonLoader gutter={!!children} size={size}/>{' '}</>)} 
            { 
                (!loading && icon && iconPosition == 'left') ? (
                    <><div className={!children ? 'flex' : 'mr-1'}>{icon}</div>{children && <>&nbsp;</> }</>
                ) : ''
            } 
            {/* {
                typeof children == 'string' ? (
                    <Typography size={size} className={fontColorClass}>
                        {children}
                    </Typography>
                ): children
            } */}
            {children}
            { 
                (!loading && icon && iconPosition == 'right') ? (
                    <>{children && <>&nbsp;</> }<div className={!children ? 'flex' : 'ml-1'}>{icon}</div></>
                ) : ''
            } 
        </Element>
    )
}) as ICompoundedComponent;

const Group = (props: IGroup) => {
    const { children, hint, isOptional, defaultValue, value, className, label, isEven, onChange, ...rest } = props;
    const [internalValue, setInternalValue] = React.useState(value || defaultValue);

    useUpdateEffect(() => {
        setInternalValue(value);
    }, [value]);

    const Children = React.Children.map(children, (child, i) => {
        return React.cloneElement(child, {
            ...rest, 
            key: i, 
            isButtonGroup: true,
            type: (child.props.value == internalValue || internalValue?.includes?.(child.props.value)) ? 'secondary' : undefined,
            className: classNames([
                'flex-1 border-b border-t first:border-l border-r first:rounded-tl-sm first:rounded-bl-sm last:rounded-tr-sm last:rounded-br-sm',
                '!border-gray-300 dark:!border-gray-600'
            ]),
            onClick: e => {
                child.props?.onClick?.(e);
                // @ts-ignore
                onChange?.(e?.target?.value, internalValue)
            }
        });
    });

    return (
        <div>
            {
                (label || hint || isOptional) && (
                    <div className='flex justify-between mb-1 items-center'>
                        <div>
                            {
                                label && (
                                    <Input.Label labelFor={label}>
                                        {label}
                                    </Input.Label>
                                )
                            }
                        </div>
                        <div>
                            {
                                (hint || isOptional) && (
                                    <Input.Hint>
                                        { hint || isOptional && 'Optional' }
                                    </Input.Hint>
                                )
                            }
                        </div>
                    </div>                    
                )
            }
            <div
                className='flex'
            >
                {Children}    
            </div>
        </div>
    )
}

(Button as any).Group = Group;

export {Button};