import { faFolderOpen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "app/components/generics/Loader";
import { Typography } from "app/components/generics/Typography";
import classNames from "classnames";
import {
  THEME_DEFAULT_BACKGROUND,
  THEME_DEFAULT_BORDER,
  THEME_DEFAULT_DIVIDE_VERTICAL,
  THEME_DEFAULT_HOVER,
  THEME_PREFIX,
} from "constants/theme.contants";
import React, { HTMLAttributes } from "react";

type SizeTypes = "xs" | "sm" | "md" | "lg";

interface IList {
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  loading?: boolean;
  className?: string;
  emptyText?: string;
  data: any[];
  height?: number | string;
  border?: boolean;
  size?: SizeTypes;
  renderItem?: (item?: any, index?: number) => React.ReactNode;
  children?: Function;
}

const iconSizes = {
  xs: "1x",
  sm: "1x",
  md: "2x",
  lg: "3x",
};

const List = (props: IList) => {
  const {
    header,
    footer,
    data = [],
    renderItem,
    loading,
    className,
    children,
    height,
    size,
    border,
    emptyText,
  } = props;

  const style: React.CSSProperties = {};
  let headerElement = header;
  let footerElement = footer;

  if (typeof headerElement == "string") {
    headerElement = (
      <Typography size="lg" bold>
        {headerElement}
      </Typography>
    );
  }

  if (typeof footerElement == "string") {
    footerElement = <Typography>{footerElement}</Typography>;
  }

  if (height) {
    style.height = height;
  }

  return (
    <div
      className={classNames(
        `${THEME_PREFIX}-list`,
        `${THEME_PREFIX}-list-${size || "md"}`,
        border ? "border" : "",
        "rounded",
        THEME_DEFAULT_BORDER,
        THEME_DEFAULT_BACKGROUND,
        className
      )}
      style={style}
    >
      {headerElement && (
        <div
          className={classNames(
            `${THEME_PREFIX}-list-header`,
            "border-b",
            THEME_DEFAULT_BORDER
          )}
        >
          {headerElement}
        </div>
      )}
      {!loading && !data?.length ? (
        <div className="flex flex-col items-center justify-center flex-1 h-36">
          <div className="mb-2">
            <FontAwesomeIcon
              icon={faFolderOpen}
              // @ts-ignore
              size={iconSizes[size] || "2x"}
            />
          </div>
          <div>
            <Typography type="secondary" size={size}>
              {emptyText || "No Results"}
            </Typography>
          </div>
        </div>
      ) : (
        <ul
          className={classNames(
            `${THEME_PREFIX}-list-items`,
            "rounded",
            "overflow-y-auto",
            THEME_DEFAULT_DIVIDE_VERTICAL,
            loading ? "h-36" : ""
          )}
        >
          {loading ? (
            <Loader size="lg" className="w-full h-full" center />
          ) : renderItem ? (
            //@ts-ignore
            data.map((v, i) => renderItem({ item: v, size, index: i }))
          ) : (
            children &&
            //@ts-ignore
            data.map((v, i) => (
              <li key={i} className={classNames([THEME_DEFAULT_HOVER, "px-1"])}>
                {children({ index: i })}
              </li>
            ))
          )}
        </ul>
      )}
      {footerElement && (
        <div className={classNames([`${THEME_PREFIX}-list-footer`])}>
          {footerElement}
        </div>
      )}
    </div>
  );
};

interface IItem extends HTMLAttributes<HTMLLIElement> {
  children?: any;
  isSelected?: boolean;
  size?: SizeTypes;
  as?: any;
  [x: string]: any;
}

const Item = (props: IItem): any => {
  const { children, isSelected, as, size, className, ...rest } = props;

  let Element: any = "li";

  if (as) {
    Element = as;
  }

  return (
    <Element
      className={classNames([
        className,
        `${THEME_PREFIX}-list-item`,
        THEME_DEFAULT_HOVER,
        isSelected ? "selected" : "",
      ])}
      {...rest}
    >
      {children}
    </Element>
  );
};

List.Item = Item;

export { List };
