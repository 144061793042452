import classNames from "classnames";
import {
  THEME_DEFAULT_BORDER,
  THEME_DEFAULT_BORDER_HOVER,
  THEME_DEFAULT_PRIMARY_TEXT,
  THEME_DEFAULT_SECONDARY_TEXT,
  THEME_PRIMARY_BORDER,
  THEME_DEFAULT_PRIMARY_BACKGROUND,
  THEME_DEFAULT_SUCCESS_TEXT,
  THEME_SUCCESS_BORDER,
  THEME_SECONDARY_BORDER,
  THEME_DEFAULT_TEXT_COLOR,
  THEME_DEFAULT_TEXT_HOVER_COLOR,
} from "constants/theme.contants";
import React, { CSSProperties, HTMLAttributes, ReactNode } from "react";
import { getRandomUUID } from "utils/functions.utils";

interface ITabs {
  defaultActiveKey?: string | number;
  activeKey?: string | number;
  width?: string | number;
  children?: any;
  extra?: any;
  style?: { [x: string]: any };
  border?: boolean;
  fill?: boolean;
  navFill?: boolean;
  gutters?: boolean;
  position?: "top" | "bottom" | "left" | "right";
  size?: "sm" | "md" | "lg";
  onTabClick?: (key?: string) => void;
}

const initialState = {
  defaultActiveKey: undefined,
  activeKey: undefined,
};

const sizes = {
  sm: "py-2 px-2.5 text-xs",
  md: "py-2.5 px-3 text-sm",
  lg: "py-6 px-4 text-base",
};

const Tabs = (props: ITabs) => {
  const {
    defaultActiveKey,
    activeKey,
    extra,
    children,
    onTabClick,
    width,
    gutters = true,
    border = false,
    position = "top",
    size = "md",
    navFill,
    fill,
    ...rest
  } = props;
  const headerRef = React.useRef(null);

  // Allow sidescroll on wheel for tabs
  React.useEffect(() => {
    if (position == "bottom" || position == "top") {
      // @ts-ignore
      headerRef?.current?.addEventListener("wheel", (e) => {
        e.preventDefault();
        // @ts-ignore
        headerRef.current.scrollLeft += e.deltaY;
      });

      return () => {
        // @ts-ignore
        headerRef?.current?.removeEventListener("wheel", (e) => {
          e.preventDefault();
          // @ts-ignore
          headerRef.current.scrollLeft += e.deltaY;
        });
      };
    }
  }, [headerRef]);

  const [defaultKeys] = React.useState(
    children?.map?.((child, i) => child?.props?.tab)
  );
  const [state, setState] = React.useState({
    ...initialState,
    activeKey: activeKey ?? defaultActiveKey ?? defaultKeys?.[0],
  });
  const [viewedTabs, setViewedTabs] = React.useState<string[]>([]);
  let sizeClasses = sizes.md;

  if (sizes[size]) {
    sizeClasses = sizes[size];
  }

  React.useEffect(() => {
    if (activeKey !== undefined && activeKey != state.activeKey) {
      setState((state) => ({ ...state, activeKey }));
    }
  }, [activeKey]);

  React.useEffect(() => {
    // @ts-ignore
    if (!viewedTabs.includes(state.activeKey)) {
      // @ts-ignore
      setViewedTabs((state) => [...new Set([...state, state.activeKey])]);
    }
  }, [state.activeKey]);
  React.useEffect(() => {
    // @ts-ignore
    if (!state.activeKey) {
      setViewedTabs((state) =>
        // @ts-ignore
        [...new Set([...state, defaultKeys?.[0]])]?.filter(Boolean)
      );
    }
  }, [state.activeKey]);

  // @ts-ignore
  const style: CSSProperties = { ...(rest.style || {}) };

  if (width) {
    style.width = width;
  }

  let navClasses = `-mb-px flex`;
  // let navClasses = `-mb-px flex overflow-x-auto`;
  let navWrapperClasses = "border-b";
  // let navWrapperClasses = 'border-b overflow-y-hidden overflow-x-auto';
  let directionClasses = "flex flex-col flex-auto h-full";
  let navItemClasses = `border ${THEME_DEFAULT_BORDER} first:rounded-tl-sm last:rounded-tr-sm`;
  // let navItemClasses = `border-r last:border-l-0 border-t border-b first:border-l ${THEME_DEFAULT_BORDER} first:rounded-tl-sm last:rounded-tr-sm`;
  // let navItemClasses = 'border-b-2';
  let bodyClasses = gutters ? "mt-4" : "";

  switch (position) {
    case "left":
      directionClasses = "flex flex-1 h-0";
      navClasses = "flex flex-col";
      navWrapperClasses = "border-r overflow-y-auto overflow-x-hidden";
      navItemClasses = "border-r-2";
      bodyClasses = "ml-4";
      break;
    case "right":
      directionClasses = "flex flex-row-reverse h-0";
      navClasses = "flex flex-col ml-2";
      navWrapperClasses = "border-l overflow-y-auto overflow-x-hidden";
      navItemClasses = "border-l-2";
      bodyClasses = "mr-4";
      break;
    case "bottom":
      directionClasses = "flex flex-col-reverse flex-auto";
      navClasses = "flex overflow-x-auto";
      // navClasses = 'mt-2 flex';
      navWrapperClasses = "border-t overflow-y-hidden overflow-x-auto";
      navItemClasses = "border-t-2";
      bodyClasses = "mb-4";
      break;

    default:
      break;
  }

  if (!navFill) {
    if (["top", "bottom"].includes(position)) {
      // navClasses += ' space-x-6';
    } else {
      navClasses += " space-y-3";
    }
  }

  const navItems: any[] = [];
  const Children = React.Children.map(children || null, (child, i) => {
    if (!child) return null;
    // @ts-ignore
    // const _viewedTabs = [...new Set([...viewedTabs, child?.key == state.activeKey ? child?.key : false])].filter(Boolean);
    // if (child?.key == state.activeKey) {
    //     setViewedTabs(_viewedTabs);
    // }
    const childProps = { ...child?.props };
    const key = child?.key ?? child?.props?.tab;
    const isActive = state.activeKey == key || !!child?.props?.isActive;

    let Element = "button";

    if (child?.props.as) {
      Element = child?.props.as;
    }

    delete childProps.forceRender;
    delete childProps.focus;

    const navItem = (
      <Element
        {...childProps}
        key={key}
        type="button"
        onClick={() => {
          setState((state) => ({ ...state, activeKey: key }));
          onTabClick && onTabClick(child?.key);
          child?.props.onClick && child?.props.onClick(key, i);
        }}
        // style={{ maxWidth: ['right', 'left'].includes(position) ? 180 : undefined }}
        // style={{ minWidth: 180 }}
        title={
          typeof child?.props?.tab == "string" ? child?.props?.tab : undefined
        }
        style={{ minWidth: 80 }}
        className={classNames(
          sizeClasses,
          navItemClasses,
          // 'truncate',
          //   "whitespace-nowrap",
          "font-medium",
          navFill ? "flex-1 text-center" : "",
          isActive
            ? `${THEME_DEFAULT_TEXT_COLOR} dark:bg-gray-700 focus:z-10`
            : // ? `${THEME_DEFAULT_BORDER} ${THEME_DEFAULT_TEXT_COLOR} dark:bg-gray-800/80 font-bold`
              // ? `${THEME_PRIMARY_BORDER} ${THEME_DEFAULT_PRIMARY_TEXT}`
              // ? `${THEME_PRIMARY_BORDER} ${THEME_DEFAULT_PRIMARY_TEXT} ${THEME_DEFAULT_PRIMARY_BACKGROUND} dark:bg-opacity-10 bg-opacity-10`
              `bg:transparent dark:hover:bg-gray-800 ${THEME_DEFAULT_SECONDARY_TEXT} ${THEME_DEFAULT_BORDER_HOVER} ${THEME_DEFAULT_TEXT_HOVER_COLOR}`
          // : `dark:bg-gray-700/30 dark:hover:bg-gray-800 ${THEME_DEFAULT_SECONDARY_TEXT} ${THEME_DEFAULT_BORDER_HOVER} ${THEME_DEFAULT_TEXT_HOVER_COLOR}`,
        )}
        aria-current={isActive ? "page" : undefined}
      >
        {child?.props?.tab}
      </Element>
    );

    navItems.push(navItem);
    const canView = viewedTabs.includes(key) || isActive;

    return React.cloneElement(child, { canView, isActive });
  });

  return (
    <div style={style} className={`${directionClasses}`}>
      {/* <div style={style} className='flex flex-col flex-1 h-full'> */}
      <div className={`${navWrapperClasses} ${THEME_DEFAULT_BORDER}`}>
        {/* <div className={`${navWrapperClasses} ${THEME_DEFAULT_BORDER} overflow-auto`}> */}
        <nav className={`${navClasses}`} aria-label="Tabs" ref={headerRef}>
          {navItems}
          {extra && <div>{extra}</div>}
        </nav>
      </div>
      <div
        className={classNames([
          "flex-auto",
          bodyClasses,
          // fill && ["top", "bottom"].includes(position)
          //   ? "h-0 overflow-y-auto"
          //   : "h-full",
          // fill && ['top', 'bottom'].includes(position) ? 'h-0 overflow-y-auto' : 'h-full overflow-y-auto',
          border
            ? `${THEME_DEFAULT_BORDER} border border-t-0 rounded-br-sm rounded-bl-sm`
            : "",
        ])}
      >
        {Children}
      </div>
    </div>
  );
};

interface ITabPane extends HTMLAttributes<HTMLDivElement> {
  tab: any;
  isActive?: boolean;
  forceRender?: boolean;
  canView?: boolean;
  onClick?: any;
  children?: any;
  as?: ReactNode;
  to?: string;
}

const TabPane = (props: ITabPane) => {
  const {
    children,
    canView,
    isActive,
    forceRender,
    style = {},
    className,
    ...rest
  } = props;
  let tabIndex;

  if (!isActive) {
    tabIndex = -1;
    style.position = "absolute";
    style.display = "hidden";
    style.visibility = "hidden";
    style.height = 0;
    style.left = "-9999px";
    style.top = "-9999px";
  }

  // TODO: Keep an eye on these css changes to flex, may cause issues on the flex scrollbar for unset heights
  return (
    <div
      {...rest}
      className={[
        className,
        // 'flex-auto'
        "h-full",
      ].join(" ")}
      style={style}
      tabIndex={tabIndex}
    >
      {(canView || forceRender) && children}
    </div>
  );
};

Tabs.TabPane = TabPane;

export default Tabs;
