import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "app/components/generics/Typography";
import classNames from "classnames";
import { Button } from "app/components/generics/Button";
import {
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";

type Types = "success" | "warning" | "info" | "error" | "primary" | undefined;

interface IAlert {
  title?: string;
  description?: string;
  icon?: any;
  type: Types;
  closable?: boolean;
  ghost?: boolean;
  onClose?: Function;
  onAction?: Function;
  actionText?: string;
  action?: any;
}

const colors = {
  info: {
    root: [
      "bg-cyan-100",
      "dark:bg-cyan-200",
      "border-cyan-500",
      "dark:border-cyan-400",
    ],
    font: ["text-cyan-700", "dark:text-cyan-500"],
    action: ["bg-cyan-500", "hover:bg-cyan-600", "text-white"],
    close: [
      "bg-cyan-100",
      "hover:bg-cyan-200",
      "dark:bg-cyan-200",
      "dark:hover:bg-cyan-300",
      "text-cyan-500",
      "dark:text-cyan-600",
      "focus:ring-cyan-400",
    ],
  },
  primary: {
    root: [
      "bg-blue-100",
      "dark:bg-blue-200",
      "border-blue-500",
      "dark:border-blue-400",
    ],
    font: ["text-blue-700", "dark:text-blue-500"],
    action: ["bg-blue-500", "hover:bg-blue-600", "text-white"],
    close: [
      "bg-blue-100",
      "hover:bg-blue-200",
      "dark:bg-blue-200",
      "dark:hover:bg-blue-300",
      "text-blue-500",
      "dark:text-blue-600",
      "focus:ring-blue-400",
    ],
  },
  success: {
    root: [
      "bg-green-100",
      "dark:bg-green-200",
      "border-green-500",
      "dark:border-green-400",
    ],
    font: ["text-green-700", "dark:text-green-500"],
    action: ["bg-green-500", "hover:bg-green-600", "text-white"],
    close: [
      "bg-green-100",
      "hover:bg-green-200",
      "dark:bg-green-200",
      "dark:hover:bg-green-300",
      "text-green-500",
      "dark:text-green-600",
      "focus:ring-green-400",
    ],
  },
  warning: {
    root: [
      "bg-yellow-100",
      "dark:bg-yellow-200",
      "border-yellow-500",
      "dark:border-yellow-400",
    ],
    font: ["text-yellow-700", "dark:text-yellow-500"],
    action: ["bg-yellow-500", "hover:bg-yellow-600", "text-white"],
    close: [
      "bg-yellow-100",
      "hover:bg-yellow-200",
      "dark:bg-yellow-200",
      "dark:hover:bg-yellow-300",
      "text-yellow-500",
      "dark:text-yellow-600",
      "focus:ring-yellow-400",
    ],
  },
  error: {
    root: [
      "bg-red-100",
      "dark:bg-red-200",
      "border-red-500",
      "dark:border-red-400",
    ],
    font: ["text-red-700", "dark:text-red-500"],
    action: ["bg-red-500", "hover:bg-red-600", "text-white"],
    close: [
      "bg-red-100",
      "hover:bg-red-200",
      "dark:bg-red-200",
      "dark:hover:bg-red-300",
      "text-red-500",
      "dark:text-red-600",
      "focus:ring-red-400",
    ],
  },
};

const icons = {
  info: faCircleInfo,
  primary: faCircleInfo,
  success: faCircleCheck,
  warning: faTriangleExclamation,
  error: faCircleXmark,
};

export const Alert = (props: IAlert) => {
  const {
    title,
    description,
    type,
    closable,
    ghost,
    icon,
    action,
    onClose,
    onAction,
    actionText,
  } = props;
  const [show, setShow] = React.useState(true);

  const handleOnClose = () => {
    onClose && onClose();
    setShow(false);
  };

  let color = colors.info;
  let _icon = icons.info;

  if (type && !icon) {
    _icon = icons[type];
  }

  return (
    <>
      {show && (
        <div
          className={classNames(
            // @ts-ignore
            ...colors[type].root,
            ghost ? "!bg-transparent" : "",
            `relative `,
            `rounded-md `,
            `border`,
            `p-4 `,
            `w-full`
          )}
        >
          {closable && (
            <div className="absolute top-1 right-1">
              <button
                type="button"
                onClick={handleOnClose}
                className={classNames(
                  "inline-flex",
                  "rounded-lg",
                  "justify-center",
                  "p-1.5",
                  "focus:outline-none",
                  "focus:ring-2 ",
                  // 'focus:ring-offset-2',
                  "h-8 w-8",
                  // @ts-ignore
                  ...colors[type].close
                )}
              >
                <span className="sr-only">Dismiss</span>
                {/* @ts-ignore */}
                <FontAwesomeIcon className="w-5 h-5" icon={faXmark} size="lg" />
              </button>
            </div>
          )}
          <div className="flex">
            <div
              className={classNames(
                "flex-shrink-0 flex items-center",
                ...colors[type!].font
              )}
            >
              {icon || (
                <FontAwesomeIcon
                  className={classNames(...colors[type!].font)}
                  aria-hidden="true"
                  icon={_icon}
                  style={{ fontSize: 18 }}
                />
              )}
            </div>
            <div
              className={classNames(
                "ml-6 flex-1",
                !title || !description ? "flex items-center" : ""
              )}
            >
              {title && (
                <h2 className={classNames("font-bold", ...colors[type!].font)}>
                  {title}
                </h2>
              )}
              {description && (
                <div
                  className={classNames(
                    "text-sm",
                    ...colors[type!].font,
                    title ? "mt-1" : ""
                  )}
                >
                  <Typography.Paragraph>{description}</Typography.Paragraph>
                </div>
              )}
            </div>
            {action ||
              (onAction && (
                <div
                  className={classNames(
                    "flex items-center",
                    closable ? "mr-8" : ""
                  )}
                >
                  <Button
                    size="sm"
                    onClick={onAction as any}
                    type={type}
                    // @ts-ignore
                    // className={classNames('rounded cursor-pointer p-1 px-2', ...colors[type].action)}
                    style={{ height: "fit-content" }}
                  >
                    {actionText}
                  </Button>
                  {/* <a
                                            onClick={onAction as any}
                                            // @ts-ignore
                                            className={classNames('rounded cursor-pointer p-1 px-2', ...colors[type].action)}
                                            style={{ height: 'fit-content' }}
                                        >
                                            <span className='text-sm'>{ actionText }</span>
                                        </a> */}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};
