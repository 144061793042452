import React from "react";
import { BellIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import { Button, Typography } from "app/components/generics";
import { useSocket } from "services/socket/socket";
import { EVENT_CREATE_NOTIFICATION } from "services/socket/events/notification.event";
import { useApi } from "hooks/api.hook";
import { ApiRoutes } from "shared/routes/api";
import { INotification } from "shared/interfaces/notification.interface";
import { Link, useHistory } from "react-router-dom";
import { ClientRoutes } from "shared/routes/client";
import classNames from "classnames";
import {
  THEME_BACKGROUND_0,
  THEME_DARK_BACKGROUND_0,
  THEME_DARK_BACKGROUND_BODY,
  THEME_DEFAULT_BORDER,
  THEME_DEFAULT_DIVIDE_VERTICAL,
} from "constants/theme.contants";
import { Badge } from "app/components/generics/Badge";
import Loader from "app/components/generics/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateRandomKey, maybeArray } from "utils/functions.utils";
import { toast } from "react-toastify";
import { ToastError } from "app/components/ToastError";
import {
  faArrowsRotate,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";

const apiRoutes = new ApiRoutes();
const clientRoutes = new ClientRoutes();
let timeoutId;
let TIMEOUT_INTERVAL = 3000;

export const Notifications = () => {
  const { socket } = useSocket();
  const NotificationService = useApi<INotification[]>(
    apiRoutes.notifications.addQuery({ limit: 20, unread: true }).toURL,
    { load: true }
  );
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [open, setOpen] = React.useState(true);
  const [alert, setAlert] = React.useState(false);

  React.useEffect(() => {
    if (NotificationService.data?.length) {
      setNotifications(NotificationService.data);
    }
  }, [NotificationService?.data]);

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (alert) {
      timeoutId = setTimeout(() => {
        setAlert(false);
      }, TIMEOUT_INTERVAL);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [alert]);

  React.useEffect(() => {
    socket?.on(EVENT_CREATE_NOTIFICATION, (event) => {
      if (event.data) {
        setNotifications((state) => [event.data, ...state]);
        setAlert(true);
      }
    });
    return () => {
      socket?.off(EVENT_CREATE_NOTIFICATION);
    };
  }, [socket]);

  const handleClearAllNotifications = async () => {
    try {
      await NotificationService.instance.patch({
        url: apiRoutes.notificationsClear.toURL,
      });
    } catch (error) {
    } finally {
      setNotifications([]);
      setOpen(false);
    }
  };

  const handleClearNotification = async (id) => {
    let backupCopy = [...maybeArray(notifications)];
    try {
      setNotifications((state) => state.filter((v) => v.id != id));
      await NotificationService.instance.patch({
        url: apiRoutes.notificationRead.addParams([id]).toURL,
      });
    } catch (error) {
      // @ts-ignore
      if (error.response.status != 404) {
        setNotifications(backupCopy);
        toast.error(<ToastError error={error} />, {
          autoClose: false,
          closeOnClick: false,
        });
      }
    } finally {
      setOpen(false);
    }
  };

  return (
    <div className="flex items-center ml-4 md:ml-6">
      {/* <button className='p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none'>
                <span className='sr-only'>View notifications</span>
                <BellIcon className='w-6 h-6' aria-hidden='true' />
            </button> */}

      <Popover className="relative">
        <Popover.Button
          className={`
                      ${open ? "" : "text-opacity-90"}
                      h-8 px-1.5 rounded-sm inline-flex items-center text-base font-medium hover:bg-gray-100 dark:hover:bg-gray-700`}
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">View notifications</span>
          <div className={`relative ${alert ? "animate-wiggle" : ""}`}>
            <Typography>
              <BellIcon className="w-6 h-6" aria-hidden="true" />
            </Typography>
            <div className="absolute top-0 right-1">
              {!!notifications?.length && (
                <Badge pulse={alert} size="sm" type="error" />
              )}
            </div>
          </div>
        </Popover.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-1 transform -right-5">
            <div
              className={`overflow-hidden rounded-lg shadow-lg ${THEME_BACKGROUND_0} ${THEME_DARK_BACKGROUND_0}`}
            >
              <div
                className={`relative flex items-center justify-between p-3 bg-white ${THEME_DARK_BACKGROUND_BODY} ${THEME_DEFAULT_BORDER} border-b`}
              >
                <Typography size="lg" className="font-medium">
                  Notifications
                </Typography>
                <Link to={clientRoutes.notifications.toURL}>
                  <Button ghost type="primary" elevation="none">
                    View notifications
                  </Button>
                </Link>
              </div>
              <div
                className={`max-h-80 h-60 overflow-y-auto ${THEME_DEFAULT_DIVIDE_VERTICAL}`}
              >
                {NotificationService?.loading ? (
                  <div className="h-full">
                    <Loader center size="lg" />
                  </div>
                ) : !notifications?.length && !NotificationService?.loading ? (
                  <div className="flex items-center justify-center h-full">
                    <Typography align="center" size="lg" bold type="secondary">
                      No notifications
                    </Typography>
                  </div>
                ) : (
                  notifications?.map((v) => (
                    <div
                      key={generateRandomKey()}
                      onClick={() => setOpen(false)}
                    >
                      <NotificationItem
                        {...v}
                        onClear={() => handleClearNotification(v.id)}
                      />
                    </div>
                  ))
                )}
              </div>
              {!!notifications?.length && (
                <div
                  className={classNames([
                    "flex items-center justify-between p-2 border-t",
                    THEME_DEFAULT_BORDER,
                  ])}
                >
                  <div />
                  <Button
                    ghost
                    type="error"
                    elevation="none"
                    onClick={handleClearAllNotifications}
                  >
                    Clear All
                  </Button>
                </div>
              )}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

const NotificationItem = (props) => {
  const {
    id,
    title,
    message,
    type,
    link,
    isDownload,
    downloadName,
    createdAtDisplay,
    onClear,
  } = props;

  let icon = faCircleInfo;
  switch (type?.icon) {
    case "success":
      icon = faCircleCheck;
      break;
    case "warning":
      icon = faTriangleExclamation;
      break;
    case "error":
      icon = faCircleExclamation;
      break;
    case "working":
      icon = faArrowsRotate;
      break;
  }

  return (
    <div className="relative flow-root p-2 pt-4 transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-opacity-40">
      <div>
        <div className="flex">
          <div className="flex items-center justify-center w-8 mr-2">
            <Typography flat style={{ color: type.color }}>
              <FontAwesomeIcon icon={icon} size="lg" />
            </Typography>
          </div>
          <div className="flex-1 space-y-2">
            <div className="flex items-center">
              <Typography flat bold>
                {title}
              </Typography>
            </div>
            <div className="block text-gray-500">
              <Typography.Paragraph
                size="sm"
                className="leading-tight"
                type="secondary"
              >
                {message}
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-2">
        <div>
          {isDownload && (
            <Button
              elevation="none"
              noBubble
              download={downloadName}
              ghost
              type="primary"
              href={link}
              size="xs"
            >
              Download
            </Button>
          )}
        </div>
        <div>
          <Button
            elevation="none"
            type="error"
            ghost
            noBubble
            onClick={onClear}
            size="xs"
          >
            Clear
          </Button>
        </div>
      </div>
      <div className="absolute right-1 top-1">
        <Typography flat type="secondary" style={{ fontSize: 8 }}>
          {createdAtDisplay}
        </Typography>
      </div>
    </div>
  );
};
