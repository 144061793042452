import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { THEME_DARK_BACKGROUND_1 } from "constants/theme.contants";
import { usePrevious } from "react-use";
import { json } from "utils/functions.utils";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";

const DEFAULT_ITEM_INDENT = 4;

interface IMenu extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  openKeys: any[];
  selectedKeys: any[];
  defaultOpenKeys: any[];
  defaultSelectedKeys: any[];
  title?: string;
  icon?: any;
  extra?: any;
  expandIcon?: any;
  children?: any;
  className?: string;
  onClick?: (props: any) => {};
}
const menuInitialValues = {
  openKeys: [],
  selectedKeys: [],
  defaultOpenKeys: [],
  defaultSelectedKeys: [],
};
interface ISubMenu extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  _key?: string;
  isOpen?: boolean;
  exact?: boolean;
  title?: string;
  icon?: any;
  extra?: any;
  expandIcon?: any;
  children?: any;
  childKeys?: string;
  as?: any;
  onClick?: (props?: any) => void;
  // onClick?: (props: any) => {};
  [x: string]: any;
}
interface IMenuItem extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  _key?: string;
  isActive?: boolean;
  exact?: boolean;
  // as?: keyof HTMLElementTagNameMap | React.ReactNode;
  as?: any;
  url?: string;
  title?: string;
  icon?: any;
  extra?: any;
  onClick?: (props?: any) => void;
  [x: string]: any;
}

const MenuContext = React.createContext<[IMenu, any]>([
  menuInitialValues,
  null,
]);

const Menu = (props: Partial<IMenu>) => {
  const { children, className = "", multiple, selectedKeys, ...rest } = props;

  const prevSelectedKeys = usePrevious(selectedKeys);

  React.useEffect(() => {
    if (json.stringify(prevSelectedKeys!) != json.stringify(selectedKeys!)) {
      handleUpdateState({ selectedKeys });
    }
  }, [prevSelectedKeys, selectedKeys]);

  const [state, setState] = React.useState({
    ...menuInitialValues,
    ...rest,
    selectedKeys,
    multiple,
  });

  const Children = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as any, { _key: child.key });
    }
    return child;
  });

  const handleUpdateState = (newState: {}) => {
    setState((oldState) => ({ ...oldState, ...newState }));
  };

  return (
    //@ts-ignore
    <MenuContext.Provider value={[state, handleUpdateState]}>
      <div
        className={classNames([
          className,
          "flex flex-col mt-3 overflow-y-auto w-full py-0.5",
          // THEME_DEFAULT_DIVIDE_VERTICAL
        ])}
      >
        {Children}
      </div>
    </MenuContext.Provider>
  );
};

const SubMenu = ({
  _key,
  title,
  icon,
  extra,
  expandIcon,
  onClick,
  as,
  children,
  exact,
  disabled,
  gutters = true,
  isChild,
  level = 1,
  ...rest
}: ISubMenu) => {
  const [state, setState] = React.useContext(MenuContext);
  const [isOpen, setIsOpen] = React.useState(
    state?.defaultOpenKeys?.includes(_key)
  );
  const [isActive, setIsActive] = React.useState(
    state?.defaultSelectedKeys?.includes(_key)
  );

  React.useEffect(() => {
    if (Array.isArray(state.openKeys)) {
      if (exact) {
        setIsOpen(state?.openKeys?.some((v) => _key == v));
      } else {
        setIsOpen(state?.openKeys?.some((v) => _key?.includes(v)));
      }
    }
  }, [state.openKeys]);

  React.useEffect(() => {
    if (state.defaultOpenKeys?.length) {
      setIsOpen(state?.defaultOpenKeys?.some((v) => _key?.includes(v)));
    }
  }, []);

  React.useEffect(() => {
    if (Array.isArray(state.selectedKeys)) {
      if (exact) {
        setIsActive(state?.selectedKeys?.some((v) => v == _key));
      } else {
        setIsActive(state?.selectedKeys?.some((v) => v?.includes(_key)));
      }
    }
  }, [state.selectedKeys]);

  React.useEffect(() => {
    if (
      children &&
      !state.openKeys?.length &&
      !state?.defaultOpenKeys?.length
    ) {
      let isOpen = false;
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (
            state.selectedKeys?.some((v) => v?.includes(child.key)) ||
            state.defaultSelectedKeys?.some((v) => v?.includes(child.key))
          ) {
            setState({ openKeys: [_key] });
            isOpen = true;
          }
        }
      });
      setIsOpen(isOpen);
    }
  }, [state.selectedKeys]);

  let Element: any = "div";

  if (as) {
    Element = as;
  }

  const Children = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      //@ts-ignore
      return React.cloneElement(child as any, {
        _key: child.key,
        //@ts-ignore
        className: `${gutters ? "pl-6" : ""} ${child.props?.className ?? ""}`,
        isChild: true,
        level: level * DEFAULT_ITEM_INDENT,
      });
    }
    return child;
  });

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (disabled) return;
    if (!onClick) {
      handleExpandOnClick(e);
    } else {
      onClick?.({ key: _key, event: e });
    }

    onClick && onClick({ key: _key, event: e });
  };

  const handleExpandOnClick = (e) => {
    e.stopPropagation();
    if (disabled) return;
    let openKeys = [...state.openKeys];

    if (!openKeys?.includes(_key)) {
      if (state?.multiple) {
        openKeys?.push(_key);
      } else {
        openKeys = [_key];
      }
    } else {
      openKeys = openKeys?.filter((v) => v != _key);
    }

    setState({ openKeys });
  };

  return (
    <Element {...rest} className="block cursor-pointer" onClick={handleOnClick}>
      <button
        className={classNames(
          isOpen ? "" : "",
          // isChild ? 'pl-2' : '',
          "flex",
          "items-center",
          "my-0.5",
          "py-2",
          `pl-${
            level === undefined
              ? DEFAULT_ITEM_INDENT
              : DEFAULT_ITEM_INDENT * level
          }`,
          "pr-4",
          "h-9",
          "w-full",
          "text-xs",
          "leading-4",
          "font-medium",
          !disabled
            ? "hover:text-green-500 hover:bg-gray-200 dark:hover:bg-gray-900/30 hover:bg-opacity-30 dark:hover:bg-opacity-30 cursor-pointer"
            : "",
          isActive
            ? "bg-gray-200/70 dark:bg-gray-900/30 text-green-400 dark:text-green-400"
            : "text-gray-800 dark:text-gray-300"
        )}
      >
        {icon && <div className="mr-4">{icon}</div>}
        <div>{title}</div>
        <div className="ml-auto">
          {extra && <div className="ml-2">{extra}</div>}
          <div
            onClick={handleExpandOnClick}
            className="flex items-center justify-center w-6 h-6 ml-2 rounded-full dark:hover:bg-gray-600 dark:hover:bg-opacity-30"
          >
            {expandIcon || (
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            )}
          </div>
        </div>
      </button>
      {isOpen && (
        <div
          className={classNames([
            "max-h-80 overflow-y-auto transition-all bg-gray-100 bg-opacity-60 dark:bg-gray-900 dark:bg-opacity-40 py-0.5",
            // 'dark:divide-gray-700/50 divide-y'
          ])}
        >
          {Children}
        </div>
      )}
    </Element>
  );
};

const Item = ({
  _key,
  isActive: forcedIsActive,
  url,
  title,
  exact,
  icon,
  extra,
  as,
  level,
  isChild,
  className,
  onClick,
  children,
  disabled,
  ...rest
}: IMenuItem) => {
  const [state, setState] = React.useContext(MenuContext);
  const [isActive, setIsActive] = React.useState(
    state?.defaultSelectedKeys?.includes(_key) || forcedIsActive
  );

  React.useEffect(() => {
    if (forcedIsActive) return;
    if (Array.isArray(state.selectedKeys)) {
      if (exact) {
        setIsActive(state?.selectedKeys?.some((v) => v == _key));
      } else {
        setIsActive(state?.selectedKeys?.some((v) => v?.includes(_key)));
      }
    }
  }, [state.selectedKeys]);

  React.useEffect(() => {
    if (forcedIsActive) return;
    if (state.defaultSelectedKeys?.length) {
      if (exact) {
        setIsActive(state?.defaultSelectedKeys?.some((v) => v == _key));
      } else {
        setIsActive(state?.defaultSelectedKeys?.some((v) => v?.includes(_key)));
      }
    }
  }, []);

  let buttonProps: any = { type: "button" };
  let Element: any = "button";

  if (as) {
    Element = as;
    buttonProps = {};
  } else if (url) {
    Element = "a";
    buttonProps = {};
  }

  const handleOnClick = (e) => {
    e.stopPropagation();
    let selectedKeys = [_key];

    setState({ selectedKeys });

    onClick && onClick(_key);
  };

  return (
    <Element
      // className={classNames([
      //     isActive ? 'bg-gray-200 dark:bg-gray-900 bg-opacity-30 dark:bg-opacity-30 text-green-400' : 'text-gray-800 dark:text-gray-300',
      //     `flex items-center py-2 px-2 w-full text-xs leading-4 font-medium hover:text-green-400 dark:hover:text-green-400 hover:bg-gray-200 dark:hover:bg-gray-900 hover:bg-opacity-30 dark:hover:bg-opacity-30 cursor-pointer ${className}`
      // ])}
      {...buttonProps}
      className={classNames([
        isActive || forcedIsActive
          ? "bg-gray-200/70 dark:bg-gray-900/30 text-green-400 dark:text-green-400"
          : "text-gray-800 dark:text-gray-300",
        // isActive ? 'bg-gray-200 dark:bg-gray-900 bg-opacity-30 dark:bg-opacity-30 text-green-400 dark:text-green-400' : 'text-gray-800 dark:text-gray-300',
        "flex",
        "items-center",
        "py-2",
        // 'my-0.5',
        "pr-4",
        `pl-${
          level === undefined
            ? DEFAULT_ITEM_INDENT
            : DEFAULT_ITEM_INDENT * level
        }`,
        "w-full",
        "text-xs",
        "leading-4",
        isChild ? "h-8" : "",
        "font-medium",
        "text-gray-800",
        !disabled
          ? "hover:text-green-500 hover:bg-gray-200 dark:hover:bg-gray-900/30 hover:bg-opacity-30 dark:hover:bg-opacity-30 cursor-pointer"
          : "",
        disabled
          ? `${THEME_DARK_BACKGROUND_1} opacity-70 cursor-not-allowed pointer-events-none`
          : "",
        className,
      ])}
      onClick={!disabled ? handleOnClick : undefined}
      disabled={disabled}
      {...rest}
    >
      {icon && <div className="mr-4">{icon}</div>}
      <div>{title}</div>
      {true && <div className="ml-auto">{extra}</div>}
    </Element>
  );
};

Menu.Item = Item;
Menu.SubMenu = SubMenu;

export { Menu };
