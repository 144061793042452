import { throttle } from 'lodash';
import React from 'react';

export const BREAKPOINTS = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
}

export const BREAKPOINT_NUMBERS = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
}

const getDeviceConfig = (width) => {
    if(width < 640) {
      return 'xs';
    } else if(width >= BREAKPOINT_NUMBERS.sm && width < BREAKPOINT_NUMBERS.md ) {
      return 'sm';
    } else if(width >= BREAKPOINT_NUMBERS.md && width < BREAKPOINT_NUMBERS.lg) {
      return 'md';
    } else if(width >= BREAKPOINT_NUMBERS.lg && width < BREAKPOINT_NUMBERS.xl) {
      return 'lg';
    } else if(width >= BREAKPOINT_NUMBERS.xl) {
      return 'xl';
    }
};

const BreakpointContext = React.createContext({} as any);

export const BreakpointContextProvider = ({ children }) => {

    const [currentBreakpoint, setCurrentBreakpoint] = React.useState(() => getDeviceConfig(window.innerWidth));
  
    React.useEffect(() => {
        const calcInnerWidth = throttle(function() {
            setCurrentBreakpoint(getDeviceConfig(window.innerWidth))
        }, 200); 
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return (
        <BreakpointContext.Provider value={{ currentBreakpoint }}>
            { children }
        </BreakpointContext.Provider>   
    )
}

export function useBreakpoint() {
    const { currentBreakpoint } =  React.useContext(BreakpointContext);
    return { currentBreakpoint };
}