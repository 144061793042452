import { Switch as HeadlessSwitch } from '@headlessui/react';
import { Input } from 'app/components/generics/Input';
import { Typography } from 'app/components/generics/Typography';
import classNames from 'classnames';
import { THEME_DEFAULT_ERROR_BACKGROUND, THEME_DEFAULT_INFO_BACKGROUND, THEME_DEFAULT_PRIMARY_BACKGROUND, THEME_DEFAULT_SECONDARY_BACKGROUND, THEME_DEFAULT_SUCCESS_BACKGROUND, THEME_DEFAULT_TEXT_COLOR_INVERTED, THEME_DEFAULT_WARNING_BACKGROUND } from 'constants/theme.contants';
import React from 'react';

type Types = 'secondary' | 'primary' | 'success' | 'warning' | 'error' | undefined;
type Sizes = 'sm' | 'md' | 'lg';
interface ISwitch {
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    onChange?: Function;
    className?: string;
    offText?: string;
    onText?: string;
    labelClassName?: string;
    label?: string;
    labelPosition?: 'left' | 'right' | 'top' | 'both';
    type?: Types;
    size?: Sizes;
}

const types = {
    secondary: THEME_DEFAULT_SECONDARY_BACKGROUND,
    primary: THEME_DEFAULT_PRIMARY_BACKGROUND,
    success: THEME_DEFAULT_SUCCESS_BACKGROUND,
    warning: THEME_DEFAULT_WARNING_BACKGROUND,
    error: THEME_DEFAULT_ERROR_BACKGROUND,
    info: THEME_DEFAULT_INFO_BACKGROUND,
}

const buttonSizes = {
    sm: 'h-3 w-3',
    md: 'h-3.5 w-3.5',
    lg: 'h-4 w-4',
}

const containerSizes = {
    sm: 'h-4 w-8',
    md: 'h-5 w-10',
    lg: 'h-6 w-12',
}
const translateSizes = {
    sm: 'translateX(1.1rem)',
    md: 'translateX(1.4rem)',
    lg: 'translateX(1.75rem)',
}

export const Switch = React.forwardRef<HTMLButtonElement, ISwitch>((props, ref) => {
    const { 
        defaultChecked,
        checked,
        onChange,
        autoFocus,
        className = '',
        labelClassName = '',
        labelPosition = 'left',
        disabled,
        label,
        offText,
        onText,
        type = 'primary',
        size = 'md',
        ...rest
    } = props;

    const [isChecked, setIsChecked] = React.useState<boolean>(checked || !!defaultChecked);

    const _type = types[type] || types.primary;
    const buttonSize = buttonSizes[size] || buttonSizes.md;
    const containerSize = containerSizes[size] || containerSizes.md;
    const translateSize = translateSizes[size] || translateSizes.md;

    return (
        <HeadlessSwitch.Group>
            <div className={`flex ${labelPosition == 'top' ? 'flex-col' : 'items-center'} ${className}`}>
                {(label || offText) && ['left', 'both', 'top'].includes(labelPosition) && (
                    <HeadlessSwitch.Label className={`${labelPosition == 'top' ? 'mb-1.5' : ''} ${labelClassName} ${labelPosition == 'left' && label ? 'mr-4' : onText ? 'mr-2' : ''}`}>
                        <Input.Label size={size}>
                            {label || offText}
                        </Input.Label>
                    </HeadlessSwitch.Label>
                )}
                <HeadlessSwitch
                    ref={ref}
                    disabled={disabled}
                    checked={checked || !!isChecked}
                    autoFocus={autoFocus}
                    onChange={checked => {
                        setIsChecked(checked);
                        if (onChange) onChange(checked);
                    }}
                    className={classNames([
                        'relative inline-flex items-center rounded-full shadow-inner dark:shadow-inner',
                        !disabled ? isChecked ? _type : 'bg-gray-200 dark:bg-gray-600' : isChecked ? `opacity-70 ${_type}` : 'dark:bg-gray-400/70',
                        containerSize,
                    ])}
                    {...rest}
                >
                    <span
                        style={{ transform: isChecked ? translateSize : 'translateX(.25rem)' }}
                        className={classNames([
                            `flex items-center justify-center transform rounded-full`,
                            buttonSize,
                            disabled ? 'bg-white/70' : 'bg-white'
                        ])}
                    />
                </HeadlessSwitch>
                {(label || onText) && ['right', 'both'].includes(labelPosition) && (
                    <HeadlessSwitch.Label className={`${labelClassName} ${label ? 'ml-4' : onText ? 'ml-2' : ''}`}>
                        <Input.Label size={size}>
                            {label || onText}
                        </Input.Label>    
                    </HeadlessSwitch.Label>
                )}
            </div>
        </HeadlessSwitch.Group>
    )
});