import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken,
} from "axios";
import { LocalStorageService } from "./LocalStorage.service";
// import { setupCache } from "axios-cache-adapter";
import { ClientRoutes } from "shared/routes/client";

// Create `axios-cache-adapter` instance
// const cache = setupCache({
//   maxAge: 0,
//   //   maxAge: 15 * 1000
// });

export interface IBaseGet {
  url: string;
  signal?: AbortSignal;
  config?: AxiosRequestConfig;
}

export interface IBaseAction {
  url: string;
  data?: {};
  config?: AxiosRequestConfig;
}

interface IBaseAPI {
  cancel: Function;
  cancelAll: Function;
  cancelLatest: Function;
}

// REEFRESH
let isTokenRefreshing = false;
let subscribers: Function[] = [];

const HTTP_CODE_UNAUTHORIZED = 401;

const clientRoutes = new ClientRoutes();

function onTokenRefreshed(token, originalRequest) {
  // Issue occurred where the retry would not auth the user,
  // check back later if issue persists
  // if (!subscribers.length && originalRequest) {
  //     originalRequest(token)
  // } else {
  // }
  subscribers = subscribers.filter((callback) => callback(token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export class AppAPI {
  private BASE_URL =
    process.env.REACT_APP_ENV == "prod"
      ? `${window.location.protocol}//${window.location.host}${(
          "/" + process.env.REACT_APP_API_BASE_PATH
        )?.replace?.(/\/\/+/g, "/")}`
      : process.env.REACT_APP_API_FULL_URL;
  // private BASE_URL = `${window.location.origin}/${process.env.REACT_APP_API_BASE_URL}`;
  public service!: AxiosInstance;

  constructor() {
    let service = axios.create({
      baseURL: this.BASE_URL,
      // adapter: cache.adapter,
    });
    service.interceptors.request.use(
      this.handleRequest as any,
      this.handleError
    );
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  // public cancel() {
  //     if (this.cancelTokens?.length && this.cancelTokens?.length > 1) {
  //         this.cancelTokens?.forEach(token => {
  //             token && token()
  //         });
  //     }
  // }

  // public cancelAll() {
  //     if (this.cancelTokens?.length && this.cancelTokens?.length > 1) {
  //         this.cancelTokens?.forEach(token => {
  //             token && token()
  //         });
  //     }
  // }

  // cancelLatest() {
  //     if (this.cancelTokens?.length && this.cancelTokens?.length > 1) {
  //         if (this.cancelTokens[this.cancelTokens.length - 1]) {
  //             this.cancelTokens[this.cancelTokens.length - 1]?.()
  //         }
  //     }
  // }

  // static get instance() {
  //     return (globalThis[Symbol.for(`PF_${BaseAPI.name}`)] || new this());
  // }

  private handleRequest(config: AxiosRequestConfig) {
    // if (session.csrf) {
    //     config.headers.common['x-csrf-token'] = session.csrf;
    // }
    if (!clientRoutes.logout.isMatch() || config.url?.includes("logout")) {
      const token = LocalStorageService.getAccessToken();

      if (token && config?.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    }
  }

  private handleSuccess(response: AxiosResponse) {
    return response;
  }

  private handleError = async (error: AxiosError) => {
    const originalRequest = error.config;

    if (
      error.response?.status == HTTP_CODE_UNAUTHORIZED &&
      !clientRoutes.login.isMatch()
    ) {
      window.location.href = clientRoutes.logout.toURL;
    }
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((token) => {
        if (originalRequest?.headers) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
        }
        resolve(this.service(originalRequest!));
      });
    });
    return Promise.reject(error);
  };

  redirectTo = (path: string) => {
    document.location.assign(path);
  };

  async delete(props: IBaseGet): Promise<AxiosResponse> {
    const { url, config } = props;
    return this.service.delete(url, {
      ...config,
    });
  }

  public async instance(args: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.service(args);
  }

  public async get(props: IBaseGet): Promise<AxiosResponse> {
    const { url, config, signal } = props;
    return this.service.get(url, {
      signal,
      ...config,
    });
  }

  public async getDownload(props: IBaseGet): Promise<AxiosResponse> {
    const { url, config } = props;
    return this.service.get(url, config);
  }

  public async put(props: IBaseAction): Promise<AxiosResponse> {
    const { url, data, config } = props;

    return this.service.request({
      url,
      data,
      method: "PUT",
      ...config,
    });
  }

  public async putForm(props: IBaseAction): Promise<AxiosResponse> {
    const { url, data, config } = props;

    return this.service.request({
      url,
      data,
      method: "PUT",
      responseType: "json",
      ...config,
      // headers: form.getHeaders(),
      // headers: {
      //     'content-type': `multipart/form-data; boundary=${form._boundary}`,
      // },
    });
  }

  public async patch(props: IBaseAction): Promise<AxiosResponse> {
    const { url, data, config } = props;

    return this.service.patch(url, data, {
      ...config,
    });
  }

  public async patchForm(props: IBaseAction): Promise<AxiosResponse> {
    const { url, data, config } = props;

    return this.service.request({
      url,
      data,
      method: "PATCH",
      ...config,
      // headers: form.getHeaders(),
      // headers: {
      //     'content-type': `multipart/form-props; boundary=${form._boundary}`,
      // },
    });
  }

  // public async post(path: string, payload: any): Promise<any>
  public async post(props: IBaseAction): Promise<AxiosResponse> {
    const { url, data, config } = props;

    return this.service.request({
      ...config,
      url,
      data,
      method: "POST",
    });
    // return this.service.post(
    //     url,
    //     data,
    //     {
    //         cancelToken: new axios.CancelToken(token => { this.cancelTokens.push(token) }),
    //         ...config,
    //     }
    // )
  }
}
