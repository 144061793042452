import { Typography } from "app/components/generics";
import { useAppSettings } from "hooks/app-settings.hook";
import { LocalStorageService } from "services/LocalStorage.service";

export const Banner = () => {
  const { settings } = useAppSettings();
  let color: string = LocalStorageService.getBannerColor()!;
  let text: string = LocalStorageService.getBannerText()!;

  if (
    !color ||
    color != settings?.UI_BANNER_COLOR?.value ||
    color != settings?.UI_BANNER_COLOR?.defaultValue
  ) {
    color =
      settings?.UI_BANNER_COLOR?.value ||
      settings?.UI_BANNER_COLOR?.defaultValue;
    LocalStorageService.setBannerColor(color);
  }

  if (!text || text != settings?.UI_BANNER?.value) {
    text = settings?.UI_BANNER?.value;
    LocalStorageService.setBannerText(text);
  }

  if (!color || !text) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-center h-6"
      style={{ background: color }}
    >
      <Typography size="md" inline flat bold ellipsis>
        {text}
      </Typography>
    </div>
  );
};
