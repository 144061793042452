import { ApexOptions } from 'apexcharts';
import { Typography } from 'app/components/generics';
import { IChart } from 'app/components/generics/Charts/types';
import Loader from 'app/components/generics/Loader';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { maybeObject } from 'utils/functions.utils';

export const RadialBar = (props: IChart) => {
    const { loading, series = [], colors = [], options = {}, labels = [], title, height, maxLabelLength, type } = props;
    
    const [state, setState] = React.useState<{ series: any[], options: ApexOptions }>({
        series: [...series],
        options: {
            ...options,
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    // hollow: {
                    //   size: '70%',
                    // }
                    // track: {
                    //     background: "#e7e7e7",
                    //     strokeWidth: '97%',
                    //     margin: 5, // margin is in pixels
                    //     // dropShadow: {
                    //     //     enabled: true,
                    //     //     top: 2,
                    //     //     left: 0,
                    //     //     color: '#999',
                    //     //     opacity: 1,
                    //     //     blur: 2
                    //     // }
                    // },
                },
                ...maybeObject(options.plotOptions),
                dataLabels: {                    
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    } 
                },
            },
            grid: {
                padding: {
                //   top: -10
                }
            },
            // fill: {
            //     type: 'gradient',
            //     // gradient: {
            //     //     shade: 'light',
            //     //     shadeIntensity: 0.4,
            //     //     inverseColors: false,
            //     //     opacityFrom: 1,
            //     //     opacityTo: 1,
            //     //     stops: [0, 50, 53, 91]
            //     // },
            // },
            chart: {
                type: 'radialBar',
                offsetY: -10,
                sparkline: {
                  enabled: true
                },
                height,
            },
            labels,
            title: {
                text: title,
            },
            legend: {
                ...maybeObject(options.legend),
                formatter: (value) => {
                    if (maxLabelLength && value?.length > maxLabelLength) {
                        return value.slice(0, maxLabelLength) + '...';
                    }
                    return value;
                }
            },
            colors: colors?.length ? colors : undefined,
        },
    });

    React.useEffect(() => {
        if (series?.length && labels?.length) {
            setState({
                ...state,
                series: [...series],
                options: {
                    ...maybeObject(options),
                    labels,
                    chart: {
                    ...maybeObject(state.options.chart),
                        height,
                    },
                    title: {
                        text: title,
                    },
                    legend: {
                        ...maybeObject(options.legend),
                        formatter: (value) => {
                            if (maxLabelLength && value?.length > maxLabelLength) {
                                return value.slice(0, maxLabelLength) + '...';
                            }
                            return value;
                        }
                    },
                    colors: colors?.length ? colors : undefined,
                },
            });
        }
    }, [series, labels]);
    
    return (
        loading ? (
            <div className='relative h-full'>
                <Loader abs center/>
            </div>
        ) : !series?.length || !series ? (
            <div className='flex items-center justify-center'>
                <Typography type='secondary' size='lg'>No chart data</Typography>
            </div>
        ) : (
            <ReactApexChart options={state.options} series={state.series} type='radialBar' height={height || '100%'} />
        )
    )
}