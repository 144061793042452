import { ApexOptions } from 'apexcharts';
import { Typography } from 'app/components/generics';
import { IChart } from 'app/components/generics/Charts/types';
import Loader from 'app/components/generics/Loader';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

export const Line = (props: IChart) => {
    const { loading, series = [], options = {}, labels = [], title, height = 350, type = 'line', colors } = props;
    const [state, setState] = React.useState<{ series: any[], options: ApexOptions }>({
        series: [...series],
        options: {
            ...options,
            colors,
            labels,
            chart: {
                height: height,
                type: 'line',
                stacked: false,
            },
            stroke: {
                width: 3,
                curve: 'smooth',
            },
            markers: {
                size: 4,
                strokeOpacity: 1,
            },
            title: {
                text: title,
            },
        },
    });

    React.useEffect(() => {
        if (series?.length && labels?.length) {
            setState({
                ...state,
                series: [...series],
                options: {
                    ...state.options,
                    ...options,
                    colors,
                    labels: labels,
                    chart: {
                        ...state.options.chart,
                        ...options,
                        height: height,
                    },
                    title: {
                        text: title,
                    },
                },
            });
        }
    }, [series, labels, colors, loading]);

    return (
        loading ? (
            <div className='relative h-full'>
                <Loader abs center/>
            </div>
        ) : !series?.length || !series ? (
            <div className='flex flex-1 items-center justify-center'>
                <Typography type='secondary' size='lg'>No chart data</Typography>
            </div>
        ) : (
            <ReactApexChart options={state.options} series={state.series} type="line" height={height} />
        )
    )
}