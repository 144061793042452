import React from 'react'
import { usePopper } from 'react-popper'
import { Paper } from 'app/components/generics/Paper';
import ReactDOM from 'react-dom';
import { Placement } from '@popperjs/core';
import { Popover } from '@headlessui/react'
import {useClickAway, useDebounce} from 'react-use';
import { Typography } from 'app/components/generics/Typography';
import { THEME_BACKGROUND_1, THEME_DARK_BACKGROUND_BODY, THEME_DEFAULT_BORDER } from 'constants/theme.contants';
import classNames from 'classnames';

export interface ITooltip {
    element?: string | HTMLElement | null;
    show?: boolean;
    isOpen?: boolean;
    disabled?: boolean;
    persist?: boolean;
    manual?: boolean;
    outsideClickClosable?: boolean;
    placement?: Placement;
    children?: any;
    content?: any;
    onClose?: Function;
    onOpen?: Function;
    onClick?: Function;
    width?: number;
    offset?: [number, number];
    className?: any;
    tigger?: ['click'|'hover'|'context'|'hover'];
    style?: React.CSSProperties;
}

export const Tooltip = (props: ITooltip) => {
    let {
        element,
        placement = 'top',
        className,
        children,
        content,
        onClose,
        onOpen,
        disabled,
        width,
        show,
        isOpen,
        onClick,
        persist,
        manual,
        offset = [0, 5],
        tigger = ['hover'],
        style = {},
        outsideClickClosable = true,
    } = props;

    const ref = React.useRef();
    const PERSIST_TIME = persist ? 150 : 0;
    
    if (typeof children != 'string' && children?.length > 1) {
        throw new Error('Popover only accepts one child');
    }

    if (width) {
        style.maxWidth = width;
    }

    const offsetModifier = React.useMemo(
        () => ({
            name: 'offset',
            options: {
                offset,
            },
        }),
        [offset]
    );

    const arrowModifier = React.useMemo(
        () => ({
            name: 'arrow',
            options: {
            //   element: arrow,
            },
        }),
        []
    );
    
    const outsideClickRef = React.useRef(null);
    const [open, setOpen] = React.useState(show || isOpen);    
    const [referenceElement, setReferenceElement] = React.useState();
    const [popperElement, setPopperElement] = React.useState();
    const [hovering, setHovering] = React.useState(false);
    const { styles, attributes, } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            offsetModifier,
            arrowModifier,
        ]
    });

    React.useEffect(() => {
        if (isOpen !== undefined && open != isOpen) {
            setOpen(isOpen);
        }
        if (isOpen) {
            onOpen && onOpen();
        }
    }, [isOpen])

    React.useEffect(() => {
        if (!open) {
            onClose && onClose();
        }
    }, [open])

    //@ts-ignore
    useClickAway(outsideClickRef, () => {
        outsideClickClosable && setOpen(false)
    }, ['mouseup']);

    if (typeof element == 'string') {
        element = document.getElementById(element);
    }

    const [_, cancel] = useDebounce(() => {
        if (!hovering && isOpen === undefined) {
            setOpen(false);
        }
    }, PERSIST_TIME, [hovering]);

    const child = React.cloneElement(children, {
        // ref,
        // onMouseOver: (e) => {
        //     e.stopPropagation();
        //     if (typeof show != 'boolean' && typeof isOpen != 'boolean') {
        //         setOpen(true);
        //     }
        // },
        // onMouseOut: (e) => {
        //     e.stopPropagation();
        //     if (typeof show != 'boolean' && typeof isOpen != 'boolean') {
        //         setOpen(false);
        //     }
        // }
    });

    const handleOnClick = () => {
        if (disabled) return;
        setOpen(!open);
        onClick && onClick(!open)
        cancel();
    }

    const handleMouseOver = () => {
        if (disabled) return;
        setOpen(true);
        setHovering(true);
        cancel();
    }

    const handleMouseOut = () => {
        cancel();
        setHovering(false);
    }

    const rootActions: React.HTMLAttributes<HTMLDivElement> = {};

    if (tigger?.includes('click')) {
        rootActions.onClick = handleOnClick;
    }
    if (tigger?.includes('hover')) {
        rootActions.onMouseOver = handleMouseOver;
        rootActions.onMouseOut = handleMouseOut;
    }

    const clonedChild = React.cloneElement(child, { ref: setReferenceElement, ...rootActions})

    return (        
        <>
            {/* @ts-ignore */}
            {/* <div ref={setReferenceElement} {...rootActions}>
                {child}
            </div> */}
            {/* NOTE: 4-13-2022 - Change fixed position issue, may cause other issues */}
            {clonedChild}
            
            {open && content && !disabled && ReactDOM.createPortal(
                <div
                    id="tooltip"
                    role="tooltip"
                    onClick={e => e.stopPropagation()}
                    onMouseOver={rootActions.onMouseOver}
                    onMouseOut={rootActions.onMouseOut}
                    //@ts-ignore
                    ref={ref => {
                        //@ts-ignore
                        setPopperElement(ref);
                        //@ts-ignore
                        outsideClickRef.current = ref;
                    }}
                    style={styles.popper}
                    className={['z-50', className].join(' ')}
                    {...attributes.popper}
                >
                    { typeof content == 'string' ? (
                        <div 
                            className={classNames([
                                'p-1 px-2 shadow-2xl border rounded',
                                THEME_DEFAULT_BORDER,
                                THEME_DARK_BACKGROUND_BODY,
                                THEME_BACKGROUND_1,
                            ])}
                            style={style}
                        >
                            <Typography flat size='xs'>{content}</Typography>
                        </div>
                    ) : content}
                </div>,
                //@ts-ignore
                element ? document.querySelector(element) : document.body
            )}
        </>
    )
}