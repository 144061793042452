import { ApexOptions } from 'apexcharts';
import { Typography } from 'app/components/generics';
import { IChart } from 'app/components/generics/Charts/types';
import Loader from 'app/components/generics/Loader';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { maybeObject } from 'utils/functions.utils';

export const Bar = (props: IChart) => {
    const { loading, series = [], colors = [], options = {}, labels = [], title, height, maxLabelLength, seriesName } = props;
    
    const [state, setState] = React.useState<{ series: any[], options: ApexOptions }>({
        series,
        // series: [{data: [...series], name: seriesName}],
        options: {
            ...options,
            // plotOptions: {
            //     bar: {
            //         barHeight: '100%',
            //         distributed: true,
            //         horizontal: true,
            //         dataLabels: {
            //             position: 'bottom'
            //         },
            //     }
            // },
            // dataLabels: {
            //     enabled: true,
            //     textAnchor: 'start',
            //     style: {
            //         colors: ['#fff']
            //     },
            //     formatter: function (val, opt) {
            //         return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            //     },
            //     offsetX: 0,
            //         dropShadow: {
            //         enabled: true
            //     }
            // },
            // stroke: {
            //     width: 1,
            //     colors: ['#fff']
            // },
            chart: {
                ...(options.chart || {}),
                type: 'bar',
                height,
            },
            labels: labels,
            title: {
                text: title,
            },
            legend: {
                ...options.legend,
                formatter: (value) => {
                    if (maxLabelLength && value?.length > maxLabelLength) {
                        return value.slice(0, maxLabelLength) + '...';
                    }
                    return value;
                }
            },
            colors: colors?.length ? colors : undefined,
            // title: {
            //     text: title,
            // },
            // legend: {
            //     ...options.legend,
            //     formatter: (value) => {
            //         if (maxLabelLength && value?.length > maxLabelLength) {
            //             return value.slice(0, maxLabelLength) + '...';
            //         }
            //         return value;
            //     }
            // },
            // xaxis: {
            //     categories: labels,
            //     labels: {
            //       show: false
            //     }
            // },
            // yaxis: {
            //     labels: {
            //       show: false
            //     }
            // },
        },
    });

    React.useEffect(() => {
        if (series?.length && labels?.length) {
            setState({
                ...state,
                series,
                options: {
                    ...state.options,
                    ...options,
                    labels: labels,
                    title: {
                        text: title,
                    },
                    legend: {
                        ...options.legend,
                        formatter: (value) => {
                            if (maxLabelLength && value?.length > maxLabelLength) {
                                return value.slice(0, maxLabelLength) + '...';
                            }
                            return value;
                        }
                    },
                    colors: colors?.length ? colors : undefined,
                    // plotOptions: {
                    //     bar: {
                    //         barHeight: '100%',
                    //         distributed: true,
                    //         horizontal: true,
                    //         dataLabels: {
                    //             position: 'bottom'
                    //         },
                    //     }
                    // },
                    // dataLabels: {
                    //     enabled: true,
                    //     textAnchor: 'start',
                    //     style: {
                    //         // colors: [],
                    //         fontSize: '10px'
                    //     },
                    //     formatter: function (val, opt) {
                    //         return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                    //     },
                    //     offsetX: 0,
                    // },
                    // stroke: {
                    //     width: 5,
                    //     colors: ['transparent']
                    // },
                    chart: {
                        ...maybeObject(options.chart),
                        type: 'bar',
                        height,
                    },
                    // title: {
                    //     text: title,
                    // },
                    // legend: {
                    //     show: false,
                    // },
                    // grid: {
                    //     show: false,
                    // },
                    // xaxis: {
                    //     categories: labels,
                    //     labels: {
                    //       show: false
                    //     },
                    //     axisBorder: {
                    //         show: false
                    //     },
                    //     axisTicks: {
                    //         show: false,
                    //     },
                    // },
                    // yaxis: {
                    //     labels: {
                    //       show: false
                    //     },
                    //     axisBorder: {
                    //         show: false
                    //     },
                    //     axisTicks: {
                    //         show: false,
                    //     },
                    // },
                    // theme: {
                    //     monochrome: {
                    //         enabled: true
                    //     }
                    // },
                },
            });
        }
    }, [series, labels]);
    
    return (
        loading ? (
            <div className='relative h-full'>
                <Loader abs center/>
            </div>
        ) : !series?.length || !series ? (
            <div className='flex items-center justify-center'>
                <Typography type='secondary' size='lg'>No chart data</Typography>
            </div>
        ) : (
            <ReactApexChart options={state.options} series={state.series} type="bar" height={height || '100%'} />
        )
    )
}