import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { PageLoader } from "app/components/generics/Loader";
import { PrivateLayout } from "app/layouts/PrivateLayout";
import { useAuth } from "services/auth";
import NotFoundPage from "app/pages/not-found";
import { ClientRoutes } from "shared/routes/client";

const clientRoutes = new ClientRoutes();

interface IPrivateRoute extends RouteProps {
  requiresAdmin?: boolean;
}

export const PrivateRoute = (props: IPrivateRoute) => {
  const { component: Component, children, requiresAdmin, ...rest } = props;
  const { me } = useAuth();

  if (!Component && !children) return <div />;

  return (
    <Route
      {...rest}
      render={(props) =>
        !me ? (
          <Redirect
            to={{
              pathname: clientRoutes.login.toURL,
              state: { from: props.location },
            }}
          />
        ) : (requiresAdmin && !!me.$isAdmin) || !requiresAdmin ? (
          <PrivateLayout>
            <React.Suspense fallback={<PageLoader />}>
              {children ? (
                <Switch>{children}</Switch>
              ) : (
                //@ts-ignore
                <Component {...props} />
              )}
            </React.Suspense>
          </PrivateLayout>
        ) : (
          <Route path="*" component={NotFoundPage} />
        )
      }
    />
  );
};
