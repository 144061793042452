import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "app/components/generics/Button";

interface IPaginationParams {
  size: "sm" | "md" | "lg";
  onPrevious: Function;
  onNext: Function;
  disableNext: boolean;
  disablePrevious: boolean;
  disabled: boolean;
  pageIndex: number;
  pageCount: number;
  gotoPage: (num: number) => void;
  defaultPageSize: number;
  onSizeChange: (size: number) => void;
}

export const PaginationButtons = (props: IPaginationParams) => {
  const {
    size,
    onPrevious,
    onNext,
    disableNext,
    disablePrevious,
    pageIndex,
    pageCount,
    gotoPage,
    disabled,
    defaultPageSize,
    onSizeChange,
  } = props;

  return (
    <div className="flex flex-row-reverse items-center">
      <Button
        disabled={disableNext || disabled}
        onClick={onNext}
        className="ml-2"
        style={{ width: 90 }}
        icon={<FontAwesomeIcon icon={faArrowRight} />}
        iconPosition="right"
      >
        Next
      </Button>
      <Button
        disabled={disablePrevious || disabled}
        onClick={onPrevious}
        style={{ width: 90 }}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
      >
        Previous
      </Button>
    </div>
  );
};
