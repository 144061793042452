import queryString from 'query-string';

export const queryStringToObject = (query = ''): { [x: string]: any } => queryString.parse(query, { arrayFormat: 'bracket-separator', arrayFormatSeparator: ',' }) as {[x:string]: any};
export const objectToQueryString = (obj): string => queryString.stringify(obj, { arrayFormat: 'bracket-separator', arrayFormatSeparator: ',' });

export const queryToPagination = input => {
    const query = queryStringToObject(input);
    return {
        pageIndex: isNaN(query.page - 1) ? undefined : query.page - 1,
        pageSize: query.results,
    }
}