import { PageLoader } from 'app/components/generics/Loader';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { PublicLayout } from 'app/layouts/PublicLayout';
import { AuthContext } from 'services/auth';
import { ClientRoutes } from 'shared/routes/client';

const clientRoutes = new ClientRoutes();

export const PublicRoute = (props: RouteProps) => {
    const { component: Component, ...rest } = props;
    const [{me}] = React.useContext(AuthContext)
    
    if (!Component) return null;

    return (
        <Route
            {...rest}
            render={(props) => (
                !!me && !clientRoutes.logout.isMatch()
                ? <Redirect to={{ pathname: clientRoutes.dashboards.toURL, state: { from: props.location }}} />
                : (
                    <PublicLayout>
                        <Suspense fallback={<PageLoader abs/>}>
                            <Component {...props} />
                        </Suspense>
                    </PublicLayout>
                )
            )}
        />
    )
  }