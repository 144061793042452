import { AxiosResponse } from "axios";
import empty from "is-empty";
import queryString from "query-string";
import { IPagination } from "shared/interfaces/pagination.interface";
import { minutesToMs } from "utils/date.utils";
import { v4 as uuidv4 } from "uuid";

const NO_TIMEZONE_CHECKER = "T00:00:00.000Z";

// Helpers
export const preventClickThrough = (e: any, fn?: Function) => {
  e?.stopPropagation?.();
  fn?.();
};

export const maybeArray = (array): any[] => (Array.isArray(array) ? array : []);
export const maybeObject = <T>(obj: T): any =>
  !Array.isArray(obj) && typeof obj === "object" && obj !== null ? obj : {};

export const mapArrayOfObjectsToObjectWithPropAsKey = <T>(
  arr: T[],
  prop: keyof T,
  options: { seperator?: string; case?: "LOWER" | "UPPER" } = {}
): { [x: string]: T } => {
  const { seperator = "." } = options;

  let newObj: any = {};
  const requiresPathResolution = (prop as string)?.includes?.(seperator);

  if (!arr || !Array.isArray(arr)) {
    return newObj;
  }

  newObj = arr.reduce((acc: any, cur: any) => {
    const valueKey = requiresPathResolution
      ? objectResolvePath(prop as string, cur)
      : cur[prop as string];
    if (options.case == "LOWER") {
      acc[valueKey?.toLowerCase?.()] = cur;
    } else if (options.case == "UPPER") {
      acc[valueKey?.toUpperCase?.()] = cur;
    } else {
      acc[valueKey] = cur;
    }
    return acc;
  }, {});

  return newObj;
};

export const objectResolvePath = (str: string, obj: {}) =>
  str.split(".").reduce((p, c) => (p && p[c]) || null, obj);

// Time
export const hasTimezone = (input) => {
  if (isEmpty(input)) return false;
  if (input?.split?.("T")?.length != 2) return false;
  if ((input as Date)?.toISOString?.()?.includes?.(NO_TIMEZONE_CHECKER))
    return false;
  return true;
};
export const addTimezoneOffset = (date) => {
  let now = new Date();
  if (hasTimezone(date)) {
    now = new Date(date);
  }

  return new Date(
    new Date(date!).getTime() + minutesToMs(new Date().getTimezoneOffset())
  );
};
export const removeTimezoneOffset = (date) => {
  let now = new Date();
  if (hasTimezone(date)) {
    now = new Date(date);
  }
  return new Date(
    new Date(date!).getTime() - minutesToMs(new Date().getTimezoneOffset())
  );
};

// Truthy
export const isUndefined = (value) => typeof value == "undefined";
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (Array.isArray(value) && !value.length);
export const isUrl = (url: string) => {
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

  if (typeof url !== "string") {
    return false;
  }

  const match = url.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }
  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true;
  }

  return false;
};

// Convertions
export const emptyToNull = (value: any) =>
  value === "undefined" || empty(value) ? null : value;
export const emptyToUndefined = (value: any) =>
  value === "undefined" || empty(value) ? undefined : value;
export const nanToNull = (value: any) => (isNaN(value) ? null : value);
export const transformEmptyFormValueToUndefined = (value: any) =>
  value === "undefined" || isNaN(value) || empty(value) ? undefined : value;
export const intToBin = (int: number) =>
  (int >>> 0)
    ?.toString(2)
    ?.padStart(32, "0")
    ?.match(/.{1,8}/g)
    ?.join(".");
export const jsonToBoolean = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return false;
  }
};
export const intToIp4 = (int: number) =>
  [
    (int >>> 24) & 0xff,
    (int >>> 16) & 0xff,
    (int >>> 8) & 0xff,
    int & 0xff,
  ].join(".");
export const ip4ToInt = (ip: string) =>
  ip.split(".").reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) >>> 0;
export const addAlpha = (color: string, opacity: number) => {
  if (!color) {
    return undefined;
  }
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const isColorBright = (rgb: any) => {
  if (!rgb) return rgb;

  const hexTest = new RegExp(/^#[0-9a-f]{3,6}$/i);
  const rgbTest = new RegExp(/(\d{1,3}), (\d{1,3}), (\d{1,3})/);

  if (hexTest.test(rgb)) {
    rgb = hexToRgb(rgb);
  }

  if (typeof rgb == "string" && rgb?.includes("rgb")) {
    rgb = rgbTest.test(rgb);
  }

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) /
      1000
  );
  return brightness > 125;
};

// Randoms
export const getRandomUUID = () => uuidv4();
export const generateRandomKey = () =>
  (Math.random() + 1).toString(36).substring(5);
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

// Calculations
export const calculatePercentage = (
  numerator: number,
  denominator: number,
  decimal: number | boolean = 0,
  round?: boolean
): number => {
  if (
    !numerator ||
    typeof numerator == "string" ||
    denominator == 0 ||
    typeof denominator == "string"
  ) {
    return 0;
  }

  if ((typeof decimal == "boolean" && !!decimal) || round) {
    round = true;
    decimal = 2;
  }

  // @ts-ignore
  let output = parseFloat(((numerator / denominator) * 100).toFixed(decimal));

  if (round) {
    output = Math.ceil(output);
  }

  return output;
};
export const calculateCidrRange = (cidr: string) => {
  const [range, bits = 32] = cidr.split("/");
  const mask = ~(2 ** (32 - (bits as number)) - 1);
  return [intToIp4(ip4ToInt(range) & mask), intToIp4(ip4ToInt(range) | ~mask)];
};

// Validations
export const isPromise = (p) =>
  (p && Object.prototype.toString.call(p) === "[object Promise]") ||
  Boolean(p && typeof p.then === "function");
export const isIp4InCidrs = (ip: string, cidrs: string[]) =>
  cidrs.some(isIp4InCidr(ip));
export function isNumberKey(evt) {
  const charCode = evt.which ? evt.which : evt.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
}
export const isIp4InCidr = (ip: string) => (cidr: string) => {
  const [range, bits = 32] = cidr.split("/");
  const mask = ~(2 ** (32 - (bits as number)) - 1);
  return (ip4ToInt(ip) & mask) === (ip4ToInt(range) & mask);
};

// DOM Actions
export const onEnterKeyPress =
  (fn: Function, preventOnShiftKeyPress = false) =>
  (e: React.KeyboardEvent) => {
    if (
      (!preventOnShiftKeyPress || (preventOnShiftKeyPress && !e.shiftKey)) &&
      e.key === "Enter"
    ) {
      fn && fn(e);
      e.preventDefault();
    }
  };
export const onEnterOrEscapeKeyPress =
  (
    actions: { onEnter?: Function; onEscape?: Function },
    options: { preventOnShiftKeyPress: boolean } = {
      preventOnShiftKeyPress: false,
    }
  ) =>
  (e: React.KeyboardEvent) => {
    if (
      (!options.preventOnShiftKeyPress ||
        (options.preventOnShiftKeyPress && !e.shiftKey)) &&
      e.key === "Enter"
    ) {
      actions.onEnter && actions.onEnter(e);
      e.preventDefault();
    }
    if (typeof actions.onEscape == "function" && e.key === "Escape") {
      actions.onEscape(e);
      e.preventDefault();
    }
  };
export const onEscapeKeyPress = (fn: Function) => (e: React.KeyboardEvent) => {
  if (e.key === "Escape") {
    fn && fn(e);
  }
};
export const isElementScrollable = (element: any) =>
  element?.scrollWidth > element?.offsetWidth;

// Object Parsers
export const deepParseInt = (input: any) => {
  if (input != 0 && !input) return input;
  if (
    (typeof input == "number" || typeof input == "string") &&
    !isNaN(+input)
  ) {
    return parseInt(input + "");
  } else if (Array.isArray(input)) {
    return input.map((v) => deepParseInt(v));
  } else if (typeof input == "object") {
    const _input = { ...input };
    Object.keys(_input).forEach((key) => {
      _input[key] = deepParseInt(_input[key]);
    });
    return _input;
  }
  return input;
};
export const query = {
  toObject: (val: string) => queryString.parse(val),
  toQuery: (val: {}, options: {} = {}) =>
    queryString.stringify(val, { arrayFormat: "comma", ...options }),
};
export const json = {
  parse: (val: string) => {
    try {
      return JSON.parse(val);
    } catch (error) {
      return {};
    }
  },
  stringify: (obj: any[] | {}) => {
    try {
      return JSON.stringify(obj);
    } catch (error) {
      return "";
    }
  },
};
export const parseAxiosErrorForMessage = (
  error: any
): { id: string; timestamp: string; message: string } => {
  let id, timestamp;
  let message = "Unknown error occured";

  if (error?.response?.data?.message && error?.response?.data?.id) {
    id = error?.response?.data?.id;
    message = error?.response?.data?.message;
    timestamp = error?.response?.data?.timestamp;
  }
  // else if (typeof error?.response?.data?.error == 'string') {
  //     message = error?.response?.data?.error;
  // }
  else if (error?.response?.data?.response?.message) {
    message = error?.response?.data?.response?.message;
    if (Array.isArray(message)) {
      message = message[message.length - 1];
    }
  } else if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
    if (Array.isArray(message)) {
      message = message[message.length - 1];
    }
  } else if (typeof error === "string") {
    message = error;
  } else if (error?.message) {
    message = error.message;
  } else if (typeof error?.error == "string") {
    message = error.error;
  }

  if (
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "dev"
  ) {
    console.error("Parse Axios Error Function: ", error, message);
  }

  return { id, timestamp, message };
};
export const getInitialsFromName = (name: string | undefined) => {
  if (!name) return;
  name = name.trim();
  if (name.includes(" ")) {
    name = name
      .split(" ")
      .map((v) => v[0])
      .join("");
  } else {
    name = name[0];
  }

  return name.toUpperCase();
};

// Pagination
export const extractPagination = (input): IPagination | null => {
  if (
    !input?.results ||
    isNaN(+input?.results) ||
    !input?.page ||
    isNaN(+input?.page)
  )
    return null;

  const output: any = {
    pageIndex: +input.page - 1 < 0 ? 0 : +input.page - 1,
    pageSize: +input.results,
    pageCount: -1,
    total: -1,
    nextPage: -1,
    prevPage: -1,
    page: +input.page,
  };

  return output;
};
export const buildPagination = (
  input: IPagination,
  total: number
): IPagination | null => {
  if (
    !input?.pageSize ||
    isNaN(+input?.pageSize) ||
    !input?.page ||
    isNaN(+input?.page) ||
    !total
  )
    return null;
  const pagination: IPagination = {
    ...input,
    pageCount: 1,
    total,
  };

  pagination.pageCount = Math.ceil(total / input.pageSize);
  pagination.nextPage =
    pagination.page + 1 < pagination.pageCount
      ? pagination.page + 1
      : pagination.pageCount;
  pagination.prevPage = pagination.page - 1 > 1 ? pagination.page - 1 : 1;

  return pagination;
};
export function createPagination(cur, total): any[] {
  if (isNaN(cur) || cur == 0) cur = 1;
  // @ts-ignore
  if (typeof c == "string" && !isNaN(c)) c = Number(c);

  let current = cur,
    last = total,
    delta = 4,
    left = current - delta + 3,
    right = current + delta,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      //@ts-ignore
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        //@ts-ignore
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        //@ts-ignore
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

// General
export const sanitizeObject = (obj) => {
  if (!obj || typeof obj != "object") return null;
  const newObj = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (isEmpty(obj[key]) || (Array.isArray(obj[key]) && !obj[key]?.length)) {
      delete newObj[key];
    }
  });

  return newObj;
};
export const getFilenameFromHeader = (response: AxiosResponse) => {
  const filename = response.headers?.["content-disposition"]
    ?.split(";")?.[1]
    ?.trim();
  return filename;
};

export const clearStoreItems = (
  initialState: { [x: string]: any },
  currentState: { [x: string]: any },
  action: { payload: string[] }
): any => {
  const newState = { ...currentState };

  if (!action.payload.length) return newState;

  for (const key of action.payload) {
    newState[key] = initialState[key];
  }

  return newState;
};

export const sleep = async (ms: number, fn?: Function) =>
  await new Promise((resolve) =>
    setTimeout(() => {
      fn && fn();
      resolve(null);
    }, ms)
  );

export const undoActionCheck = (type, id) => (action) =>
  action.type === type && action.payload.undoId === id;

export const getValues = (obj: {}, keys: string | string[]) => {
  if (typeof keys == "string") {
    if (keys.includes(".")) {
      return getValues(obj, keys.split("."));
    } else {
      return obj[keys];
    }
  } else if (Array.isArray(keys)) {
    return keys.reduce((acc, item) => {
      if (acc) {
        return acc[item];
      }
      return null;
    }, obj);
  }
};

export const splitString = (
  input: string | undefined,
  char: string[] = [";", ",", "\n"]
): string[] => {
  if (Array.isArray(input)) return input;
  for (const c of char) {
    if (input?.includes?.(c)) {
      return input?.split?.(c);
    }
  }
  return !!input ? [input as any] : [];
};

export const zeroIfEmpty = (input) => {
  if (isEmpty(input) || isNaN(input)) {
    return 0;
  }
  return input;
};

export const lazySearchString = (target: string, input: string) => {

  let i = 0;
  let matchIndices: number[] = [];

  for (let j = 0; j < target.length; j++) {
    const tChar = target[j];
    if (tChar === input[i]) {
      i++;
      matchIndices.push(j);

      if (i === input.length) return matchIndices;
    }
  }
  
  // for (const char of target) {
  //   if (char === input[i]) {
  //     matchIndices.push(i++);
  //     if (i === input.length) return matchIndices;
  //   }
  // }
  
}
