import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react"
import { Typography } from "app/components/generics/Typography";
import classNames from "classnames"
import { THEME_DEFAULT_BORDER, THEME_DEFAULT_BORDER_HOVER, THEME_DEFAULT_PRIMARY_BACKGROUND, THEME_DEFAULT_TEXT_COLOR, THEME_DEFAULT_TEXT_HOVER_COLOR } from "constants/theme.contants";
import React from "react";

interface IBaseRadioGroup {
    label?: any;
    value?: any;
    className?: any;
    defaultValue?: any;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    children?: any;
    onChange?: Function;
    disabled?: boolean;
}

interface IOption {
    name?: string;
    value?: any;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    disabled?: boolean;
}

interface IRadioGroup extends IBaseRadioGroup {}

interface ICompoundedComponent extends React.ForwardRefExoticComponent<IRadioGroup & React.RefAttributes<HTMLInputElement>> {
    Option: React.ForwardRefExoticComponent<IOption & React.RefAttributes<HTMLInputElement>>
}

const sizes = {
    xs: 'px-1.5',
    sm: 'px-2',
    md: 'px-3',
    lg: '',
}

const RadioGroup = React.forwardRef<HTMLInputElement, IRadioGroup>((props, ref) => {
    const { label, size = 'md', onChange, className, value, defaultValue, disabled, children } = props;
    const [internalValue, setInternalValue] = React.useState(value || defaultValue);

    React.useEffect(() => {
        setInternalValue(value);
    }, [value])

    const _size = sizes[size];

    const Children = React.Children.map(children, (child, i) => {        
        return React.cloneElement(child, { 
            disabled, 
            size, 
            key: i,
        });
    })

    return (
        <HeadlessRadioGroup 
            value={internalValue}
            onChange={(value) => {
                onChange?.(value);
                if (value === undefined) {
                    setInternalValue(value)
                }
            }} 
            className="mt-2"
            ref={ref}
        >
            {label && (
                <HeadlessRadioGroup.Label className={'mb-1 block'}>
                    <HeadlessRadioGroup.Label className="sr-only">{label}</HeadlessRadioGroup.Label>
                    <Typography>
                    {/* <Typography size={size}> */}
                        {label}
                    </Typography>
                </HeadlessRadioGroup.Label>
            )}
            <div className={classNames([
                className,
                'flex space-x-2'
            ])}>
            {/* <div className="grid grid-cols-3 gap-3 sm:grid-cols-6"> */}
            {/* <div className={`grid grid-cols-${cols} gap-2`}> */}
                {Children}
            </div>
        </HeadlessRadioGroup>
    )
}) as ICompoundedComponent;

const Option = (props: IOption) => {
    const { 
        name,
        value,
        disabled,
        size = 'md',
    } = props;

    const _size = sizes[size];

    return (
        <HeadlessRadioGroup.Option
            value={value}
            className={({ active, checked }) => {
                return (
                    classNames(
                        'cursor-pointer',
                        THEME_DEFAULT_BORDER,
                        _size,
                        // active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                        checked
                            ? `${THEME_DEFAULT_PRIMARY_BACKGROUND} border-transparent ${THEME_DEFAULT_TEXT_COLOR}`
                            : `${THEME_DEFAULT_BORDER_HOVER} ${THEME_DEFAULT_TEXT_HOVER_COLOR}`,
                            // : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                            'border rounded-sm flex items-center justify-center text-sm uppercase'
                            // ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                            // : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                            // 'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                        )
                )
            }}
            disabled={disabled}
        >
            <HeadlessRadioGroup.Label as="span">
                <Typography size={size}>
                    {name}
                </Typography>
            </HeadlessRadioGroup.Label>
        </HeadlessRadioGroup.Option>
    )
}

(RadioGroup as any).Option = Option;

export default RadioGroup;