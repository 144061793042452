import {
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Select, Typography } from "app/components/generics";
import classNames from "classnames";
import {
  addMonths,
  format as dateFnsFormat,
  getYear,
  getMonth,
  isValid,
  startOfMonth,
  isEqual,
  isSameWeek,
} from "date-fns";
import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { usePrevious } from "react-use";
import {
  addTimezoneOffset,
  getRandomUUID,
  hasTimezone,
} from "utils/functions.utils";

interface IDatePickerChildren {
  setDate: (date: Date | [Date, Date] | undefined) => void;
  currentDate: Date;
  currentDateRange: [Date, Date];
}

interface IDatePicker extends Omit<ReactDatePickerProps, "onChange"> {
  isRange?: boolean;
  block?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  showMonthYearDropdown?: boolean;
  showWeekPicker?: boolean;
  showRangePicker?: boolean;
  hideIcon?: boolean;
  hint?: string;
  placeholder?: string;
  label?: string;
  message?: string;
  format?: string;
  defaultValue?: Date;
  size?: "lg" | "md" | "sm";
  // value?: Date;
  error?: string;
  className?: string;
  footer?: any;
  onChange?: (date: Date) => void | ((date: [Date, Date]) => void);
  // onChange?: ((date: Date) => void) | ((date: [Date,Date]) => void);
  children?: React.ReactNode;
  content?:
    | string
    | (({
        setDate,
        currentDate,
        currentDateRange,
      }: IDatePickerChildren) => React.ReactNode)
    | React.ReactNode;
}

const MAX_MONTHS = 12;
let breakpoint = 0;
const buildMonthYearDropdown = (date = new Date()) => {
  const monthWithYear: any = [];
  let monthCount = -12;
  const start = addMonths(date, monthCount);
  const end = addMonths(date, MAX_MONTHS);
  let current = start;
  while (current <= end) {
    const loopDate = addMonths(startOfMonth(current), monthCount);
    const label = dateFnsFormat(current, "MMMM yyyy");
    monthCount++;

    current = addMonths(date, monthCount);
    monthWithYear.push({
      label,
      date: loopDate,
      year: getYear(loopDate),
      month: getMonth(loopDate),
      value: loopDate.toISOString(),
    });
  }

  return monthWithYear;
};

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

const formatIncomingDate = (date: any) => {
  if (date === null || !isValid(new Date(date!))) return undefined;

  return !hasTimezone(date) ? addTimezoneOffset(date!) : new Date(date!);
};

export const DatePicker = (props: IDatePicker) => {
  const {
    isRange,
    showMonthYearDropdown,
    children,
    label,
    hint,
    message,
    footer,
    error,
    content,
    format,
    disabled,
    block,
    showWeekPicker,
    autoFocus,
    showTimeSelectOnly,
    dateFormat,
    className,
    placeholderText,
    placeholder,
    allowClear,
    defaultValue,
    hideIcon,
    size = "md",
    onChange,
    value,
    ...rest
  } = props;

  const [key, setKey] = React.useState<string | undefined>(undefined);
  const [startDate, setStartDate] = React.useState<any>(
    formatIncomingDate(defaultValue || value)
  );
  const [endDate, setEndDate] = React.useState<any>(undefined);
  const prevDate = usePrevious(startDate);

  React.useEffect(() => {
    if (startDate && !isEqual(prevDate!, startDate)) {
      setKey(getRandomUUID());
    }
  }, [startDate]);

  React.useEffect(() => {
    setStartDate(formatIncomingDate(value) as any);
  }, [value]);

  const handleOnChange = (date) => {
    onChange && onChange(date);
    if (date?.length) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(date);
    }
  };

  const clearValue = () => {
    setStartDate(undefined);
    setEndDate(undefined);
  };

  return (
    <div className={classNames([block ? "w-full" : "", className])}>
      {(label || hint) && (
        <div className="flex items-center justify-between mb-1">
          {label && <Input.Label htmlFor={label}>{label}</Input.Label>}
          {hint && <Input.Hint>{hint}</Input.Hint>}
        </div>
      )}
      <ReactDatePicker
        {...rest}
        key={key}
        autoFocus={autoFocus}
        selected={startDate}
        onChange={handleOnChange}
        selectsRange={isRange}
        showTimeSelectOnly={showTimeSelectOnly}
        disabled={disabled}
        dateFormat={dateFormat}
        wrapperClassName={`optica-date-picker ${
          showTimeSelectOnly ? "optica-time-only" : ""
        }`}
        placeholderText={placeholder ?? placeholderText}
        // startDate={startDate}
        // endDate={endDate}
        // minDate={subMonths(new Date(), 15)}
        // maxDate={addMonths(new Date(), 15)}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName={`react-datepicker-left shadow-xl react-datepicker-${size}`}
        fixedHeight
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ]}
        calendarClassName={showWeekPicker ? "is-week-selector" : undefined}
        dayClassName={(date: Date) =>
          showWeekPicker && isSameWeek(date, startDate!)
            ? "react-datepicker__day--selected"
            : ""
        }
        customInput={
          children || (
            <ButtonInput
              block
              format={dateFormat}
              size={size}
              autoFocus={autoFocus}
              readOnly={disabled}
              hideIcon={hideIcon}
              clearValue={clearValue}
              allowClear={allowClear}
              placeholder={placeholder ?? placeholderText}
            />
          )
        }
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          monthDate,
          ...rest
        }) => {
          return (
            <div className="flex items-center justify-between px-2 py-2">
              {showMonthYearDropdown ? (
                <Select
                  options={buildMonthYearDropdown(date)}
                  block
                  className="mr-2"
                  // getOptionLabel={(option: any) => option.display}
                  // getOptionValue={(option: any) => option.date}
                  isSearchable={false}
                  // defaultValue={{ label: format(date, 'MMMM yyyy'), value: date.toISOString() }}
                  value={{
                    label: dateFnsFormat(date, "MMMM yyyy"),
                    value: date.toISOString(),
                  }}
                  footer={null}
                  // blurInputOnSelect
                  // @ts-ignore
                  onChange={({ year, month }) => {
                    changeYear(year);
                    changeMonth(month);
                  }}
                />
              ) : (
                <Typography>{dateFnsFormat(date, "MMMM yyyy")}</Typography>
              )}
              <div className="flex space-x-2">
                <Button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  size="sm"
                  htmlType="button"
                  className={
                    prevMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>

                <Button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  size="sm"
                  htmlType="button"
                  className={
                    nextMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </div>
          );
        }}
      >
        {typeof content == "function"
          ? content({
              setDate: handleOnChange,
              currentDate: startDate!,
              currentDateRange: [startDate!, endDate!],
            })
          : content}
      </ReactDatePicker>
      {footer !== null &&
        (!!message ? (
          <div className="flex justify-between mt-0.5">
            <div>
              {message && (
                <p
                  className={classNames([
                    "text-xs",
                    error
                      ? "text-red-500 dark:text-red-400"
                      : "text-gray-600 dark:text-gray-300",
                  ])}
                >
                  {message}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div style={{ minHeight: 16 }} />
        ))}
    </div>
  );
};

const ButtonInput = React.forwardRef((props: any, ref: any) => {
  const {
    value,
    format = DEFAULT_DATE_FORMAT,
    onClick,
    block,
    clearValue,
    allowClear,
    size,
    placeholder,
    hideIcon,
    ...rest
  } = props;

  return (
    <Button
      onClick={onClick}
      ref={ref}
      htmlType="button"
      size={size}
      block={block}
      disabled={rest.disabled || rest.readOnly}
      className="group shadow-none !cursor-default"
      // className='inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500'
    >
      <div className="flex items-center justify-between w-full">
        <Typography
          size={size}
          type={isValid(new Date(value)) ? undefined : "secondary"}
        >
          {isValid(new Date(value))
            ? dateFnsFormat(new Date(value), format)
            : placeholder ?? "Select a date"}
        </Typography>
        <div className="flex">
          {allowClear && value && (
            <a
              onClick={(e) => {
                clearValue();
                e.stopPropagation();
              }}
              className="hidden group-hover:block"
            >
              <Typography inline type="secondary" className="flex">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ fontSize: 16 }}
                />
              </Typography>
            </a>
          )}
          {!hideIcon && (
            <FontAwesomeIcon
              className={classNames(
                allowClear && value ? "block group-hover:hidden" : ""
              )}
              icon={faCalendarDays}
              style={{ fontSize: 18 }}
            />
          )}
        </div>
      </div>
    </Button>
  );
});
