import { ApexOptions } from 'apexcharts';
import { Typography } from 'app/components/generics';
import { IChart } from 'app/components/generics/Charts/types';
import Loader from 'app/components/generics/Loader';
import classNames from 'classnames';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { isEmpty, maybeArray, maybeObject } from 'utils/functions.utils';

export const Pie = (props: IChart) => {
    const { 
        loading, 
        series = [], 
        colors = [], 
        options = {}, 
        labels = [], 
        title, 
        height, 
        maxLabelLength, 
        type, 
        width,
        fill,
        flexFill,
        center,
    } = props;

    const ref = React.useRef(null);
    
    const checkArray = [...maybeArray(series)?.filter?.(v => !isEmpty(v))];

    const [state, setState] = React.useState<{ series: any[], options: ApexOptions }>({
        series: [...maybeArray(series)?.filter?.(v => !isEmpty(v))],
        options: {
            ...maybeObject(options),
            chart: {
                ...maybeObject(options?.chart),
                type: type as any || 'pie',
                height,
            },
            labels: labels || options?.labels,
            title: {
                text: title,
                ...maybeObject(options.title),
            },
            legend: {
                formatter: (value) => {
                    if (maxLabelLength && value?.length > maxLabelLength) {
                        return value.slice(0, maxLabelLength) + '...';
                    }
                    return value;
                },
                ...maybeObject(options.legend),
            },
            colors: options?.colors?.length ? options?.colors : colors?.length ? colors : undefined,
        },
    });

    React.useEffect(() => {
        if (series?.length && labels?.length) {
            setState({
                ...state,
                series: [...maybeArray(series)?.filter?.(v => !isEmpty(v))],
                options: {
                    ...maybeObject(options),
                    labels: labels || state?.options?.labels,
                    chart: {
                        ...maybeObject(state.options.chart),
                        height,
                    },
                    title: {
                        text: title,
                        ...maybeObject(state.options.title),
                    },
                    legend: {
                        formatter: (value) => {
                            if (maxLabelLength && value?.length > maxLabelLength) {
                                return value.slice(0, maxLabelLength) + '...';
                            }
                            return value;
                        },
                        ...maybeObject(options.legend),
                    },
                    colors: state?.options?.colors?.length ? state?.options?.colors : colors?.length ? colors : undefined,
                },
            });
        }
    }, [series, labels]);
    
    // @ts-ignore
    const _height = height || (ref?.current?.clientHeight - 40) || '100%'

    return (
        loading ? (
            <div className='relative h-full'>
                <Loader abs center/>
            </div>
        ) : !checkArray?.length || !checkArray ? (
            <div className='flex items-center justify-center'>
                <Typography type='secondary' size='lg'>No chart data</Typography>
            </div>
        ) : (
            <div
                className={classNames([
                    'relative',
                    fill ? 'h-full' : '',
                    flexFill ? 'flex-1' : '',
                ])}
                ref={ref}
            >
                <ReactApexChart 
                    className={classNames([
                        center ? 'top-0 bottom-0 left-0 right-0 absolute flex items-center' : '',
                    ])} 
                    options={state.options || []} 
                    series={state.series || []} 
                    type={type as any || 'pie'} 
                    height={_height} width={width}
                />
            </div>
        )
    )
}