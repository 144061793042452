import { Button } from "app/components/generics/Button";
import { Typography } from "app/components/generics/Typography";
import classNames from "classnames";
import {
  THEME_BACKGROUND_2,
  THEME_DARK_BACKGROUND_1,
  THEME_DEFAULT_BORDER,
} from "constants/theme.contants";
import React from "react";

interface IDividerProps extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  align?: "left" | "right" | "center";
  size?: "xs" | "sm" | "md" | "lg";
  type?: "title" | "label" | "button";
  onButtonClick?: () => void;
  gutters?: boolean;
  flat?: boolean;
  orientation?: "vertical" | "horizontal";
  // children?: any;
}

const positions = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
};

const paddings = {
  left: "pr-2",
  center: "px-2",
  right: "pl-2",
};

const ySizes = {
  xs: "my-1",
  sm: "my-2",
  md: "my-4",
  lg: "my-6",
};

const xSizes = {
  xs: "mx-1",
  sm: "mx-2",
  md: "mx-4",
  lg: "mx-6",
};

const DividerButton = (props) => {
  const { children, onClick } = props;
  return (
    <Button shape="pill" onClick={() => onClick && onClick()}>
      {children}
    </Button>
  );
};

export default (props: IDividerProps) => {
  const {
    className = "",
    style,
    flat,
    children,
    type = "label",
    align = "center",
    size,
    gutters = true,
    orientation = "horizontal",
    onButtonClick,
  } = props;

  let position = "";
  let padding = "";
  let textSize = "";
  let textType = "";
  let _size = gutters
    ? orientation == "horizontal"
      ? ySizes.md
      : xSizes.md
    : "";

  if (size && ySizes[size] && gutters) {
    if (orientation == "horizontal") {
      _size = ySizes[size];
    } else {
      _size = xSizes[size];
    }
  }

  if (flat) {
    _size = "";
  }

  if (align && positions[align]) {
    position = positions[align];
  }

  if (type != "button") {
    if (align && paddings[align]) {
      padding = paddings[align];
    }

    if (type == "title") {
      textSize = "lg";
      textType = "";
    }
  }

  let divider = (
    <div
      className={classNames([
        orientation == "horizontal"
          ? `w-full border-b ${_size} ${className} ${THEME_DEFAULT_BORDER}`
          : `w-px ${_size} ${className} ${THEME_DARK_BACKGROUND_1} ${THEME_BACKGROUND_2}`,
      ])}
      style={style}
    />
  );

  if (children) {
    divider = (
      <div
        className={classNames([
          "relative",
          "flex",
          // 'w-full border-b border-gray-300 dark:border-gray-700',
          _size,
          className,
        ])}
        style={style}
      >
        {["right", "center"].includes(align) && (
          <div className="flex items-center flex-1" aria-hidden="true">
            <div className="w-full border-t border-gray-300 dark:border-gray-700" />
          </div>
        )}
        <div className={classNames([padding])}>
          {type == "button" ? (
            <Button
              shape="pill"
              onClick={() => onButtonClick && onButtonClick()}
              size="sm"
            >
              {children}
            </Button>
          ) : typeof children == "string" ? (
            <Typography
              //@ts-ignore
              size={textSize}
              //@ts-ignore
              type={textType}
              bold
            >
              {children}
            </Typography>
          ) : (
            children
          )}
        </div>
        {["left", "center"].includes(align) && (
          <div className="flex items-center flex-1" aria-hidden="true">
            <div className="w-full border-t border-gray-300 dark:border-gray-700" />
          </div>
        )}
      </div>
    );
  }

  return divider;
};
