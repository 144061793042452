import { AppAPI } from 'services/AppApi.service';
import React from 'react';
import { Context, IUpdateState } from './context';

interface IUpdateHook extends IUpdateState {
}

export const useUpdate = () => {
    const [state, actions] = React.useContext(Context);

    return ([
        state,
        actions,
    ]) as any;
}