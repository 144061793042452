import React, { CSSProperties } from "react";
import classNames from "classnames";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Button as GenericButton } from "app/components/generics";
import {
  THEME_DEFAULT_DISABLED,
  THEME_DEFAULT_DISABLED_TEXT,
  THEME_DEFAULT_SECONDARY_TEXT,
  THEME_DEFAULT_PRIMARY_TEXT,
  THEME_DEFAULT_SUCCESS_TEXT,
  THEME_DEFAULT_WARNING_TEXT,
  THEME_DEFAULT_ERROR_TEXT,
  THEME_DEFAULT_INFO_TEXT,
  THEME_DEFAULT_TEXT_COLOR,
} from "constants/theme.contants";
import { usePopper } from "react-popper";
import { Options } from "@popperjs/core";

type SizeType = "sm" | "md" | "lg";
type TextTypes =
  | "secondary"
  | "primary"
  | "success"
  | "warning"
  | "error"
  | "info"
  | "link"
  | undefined;

interface IDropdownMenuItem {
  onClick?: Function;
  disabled?: boolean;
  className?: string;
  as?: any;
  icon?: any;
  type?: TextTypes;
  [x: string]: any;
}

interface IDropdown {
  children?: any;
  className?: any;
  button?: any;
  icon?: any;
  overlay?: any;
  isOpen?: boolean;
  ghost?: boolean;
  fixedWidth?: boolean;
  contentWidth?: boolean;
  disabled?: boolean;
  size?: SizeType;
  width?: number | string;
  type?: "error" | "primary" | "secondary" | "warning";
}
interface IDropdownButton extends IDropdown {
  onClick?: Function;
  as?: string;
  type?: "error" | "primary" | "secondary" | "warning";
  block?: boolean;
  hideMenu?: boolean;
  className?: string;
  width?: number;
  fixedWidth?: boolean;
}

const types = {
  secondary: THEME_DEFAULT_SECONDARY_TEXT,
  primary: THEME_DEFAULT_PRIMARY_TEXT,
  link: THEME_DEFAULT_PRIMARY_TEXT,
  success: THEME_DEFAULT_SUCCESS_TEXT,
  warning: THEME_DEFAULT_WARNING_TEXT,
  error: THEME_DEFAULT_ERROR_TEXT,
  info: THEME_DEFAULT_INFO_TEXT,
};

const Dropdown = ({
  children,
  overlay,
  icon,
  size,
  type,
  disabled,
  isOpen,
  ghost,
  className,
  fixedWidth,
  contentWidth,
  ...rest
}: IDropdown) => {
  let [referenceElement, setReferenceElement] = React.useState();
  let [popperElement, setPopperElement] = React.useState();
  const ref = React.useRef(null);

  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    // @ts-ignore
    setWidth(ref?.current?.clientWidth || 0);

    // @ts-ignore
  }, [ref?.current]);

  const [open, setOpen] = React.useState(!!isOpen);

  const element = document.body;

  const popperOptions: Partial<Options> = {
    // strategy: "fixed",
    placement: "bottom-end",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: "clippingParents",
        },
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["bottom-end"],
          fallbackPlacements: ["bottom-end", "top-start"],
          altBoundary: true,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  };

  let { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    popperOptions
  );

  const Overlay = React.Children.map(overlay, (child) => {
    return React.cloneElement(child, {
      width: fixedWidth ? width : undefined,
      contentWidth,
    });
  });

  return (
    <HeadlessMenu
      as="div"
      className="relative"
      onClick={(e) => e?.stopPropagation?.()}
    >
      {/* @ts-ignore */}
      <HeadlessMenu.Button as="div" ref={setReferenceElement}>
        <GenericButton
          onDoubleClick={(e) => e?.stopPropagation?.()}
          elevation="none"
          size={size}
          type={type}
          ghost={ghost}
          disabled={disabled}
          // className='px-3.5 py-2'
          ref={ref}
          icon={icon}
          className={classNames([
            className,
            // 'px-3'
          ])}
        >
          {children}
        </GenericButton>
        {/* {
                    typeof children == 'string' ? (
                    ) : children
                } */}
      </HeadlessMenu.Button>
      <HeadlessMenu.Items
        // @ts-ignore
        ref={setPopperElement}
        style={styles.popper}
        className="z-50"
        onDoubleClick={(e) => e?.stopPropagation?.()}
        {...attributes.popper}
      >
        {!disabled && Overlay}
      </HeadlessMenu.Items>
    </HeadlessMenu>
  );
};

// const Items = React.forwardRef((props: any, ref) => {
//     const { children, className, rest } = props;
//     return (
//         <HeadlessMenu.Items
//             {...rest}
//             static
//             ref={ref}
//             // className={classNames('right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 focus:outline-none', className)}
//             className='absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
//             onClick={e => e?.stopPropagation?.()}
//         >
//             { children }
//         </HeadlessMenu.Items>
//     )
// });

const Item = (props: IDropdownMenuItem) => {
  const { children, className, onClick, disabled, as, type, icon, ...rest } =
    props;

  // let Element: any = 'button';

  // if (as) {
  //     Element = as;
  // }

  let textType = THEME_DEFAULT_TEXT_COLOR;

  if (types[type!]) {
    textType = types[type!];
  }

  return (
    <HeadlessMenu.Item
      as={as}
      onClick={(e) => {
        if (!disabled) {
          onClick && onClick(e);
        }
      }}
      disabled={disabled}
      style={{ cursor: "default" }}
      {...rest}
    >
      {({ active }) => (
        <div
          className={classNames([
            "flex items-center text-sm bg-white dark:bg-gray-800 h-8 w-full text-left px-2 focus:outline-none dark:focus:ringoutline-none space-x-2",
            disabled
              ? `cursor-not-allowed ${THEME_DEFAULT_DISABLED_TEXT} ${THEME_DEFAULT_DISABLED}`
              : `hover:bg-gray-100 dark:hover:bg-gray-700 ${textType}`,
            active ? "bg-gray-100 dark:bg-gray-700" : "",
            className,
          ])}
        >
          {icon && (
            <div className="flex items-center justify-center px-1 w-7">
              {icon}
            </div>
          )}
          <div>{children}</div>
        </div>
      )}
    </HeadlessMenu.Item>
  );
};

const Button = ({
  overlay,
  children,
  hideMenu,
  className,
  fixedWidth,
  width,
  onClick,
  type,
  size,
  ...rest
}: IDropdownButton) => {
  const ref = React.useRef(null);
  const [$width, setWidth] = React.useState<number>(width || 0);

  React.useEffect(() => {
    if (width === undefined) {
      // @ts-ignore
      setWidth(ref?.current?.clientWidth || 0);
    }

    // @ts-ignore
  }, [ref?.current]);

  const handleButtonClick = (e) => {
    onClick && onClick();
    e?.stopPropagation?.();
  };

  const Overlay = React.Children.map(overlay, (child) => {
    return React.cloneElement(child, {
      width: fixedWidth ? $width : undefined,
    });
  });

  return (
    <div ref={ref} className={`relative flex ${className}`}>
      <div className="flex-1">
        <GenericButton
          {...rest}
          type={type}
          onClick={handleButtonClick}
          className={classNames([
            !hideMenu ? "rounded-tr-none rounded-br-none" : "",
          ])}
        >
          {children}
        </GenericButton>
      </div>
      <HeadlessMenu
        as="div"
        className="block"
        onClick={(e) => e?.stopPropagation?.()}
      >
        <HeadlessMenu.Button as="div">
          {hideMenu ? (
            <div />
          ) : (
            <>
              <span className="sr-only">Open options</span>
              <GenericButton
                type={type}
                className={classNames([
                  "border-l-0 rounded-tl-none rounded-bl-none",
                ])}
              >
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </GenericButton>
            </>
          )}
        </HeadlessMenu.Button>
        <Transition
          // as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          // static
        >
          {/* <div onClick={e => e?.stopPropagation?.()} style={{ width: $width }} className='absolute left-0'> */}
          {Overlay}
          {/* </div> */}
        </Transition>
      </HeadlessMenu>
    </div>
  );
};

const Menu = React.forwardRef<any, any>(
  (
    props: {
      className?: string;
      style?: CSSProperties;
      width?: number | string;
      children: any;
      contentWidth?: boolean;
    },
    ref
  ) => {
    const { className, width, style = {}, contentWidth } = props;

    if (width && !style?.minWidth && !contentWidth) {
      style.minWidth = width;
      style.maxWidth = width;
    } else if (!contentWidth) {
      style.minWidth = 200;
    }

    return (
      <HeadlessMenu.Items
        ref={ref}
        className={classNames([
          "absolute right-0 shadow-lg mt-1 origin-top-right bg-white dark:bg-gray-800 ring-1 ring-black dark:ring-1 dark:ring-gray-900 ring-opacity-5 dark:ring-opacity-70 focus:outline-none z-10",
          // 'absolute right-0 shadow-lg mt-1 w-56 origin-top-right divide-y divide-gray-100 rounded-sm bg-white dark:bg-gray-800 ring-1 ring-black dark:ring-1 dark:ring-gray-900 ring-opacity-5 dark:ring-opacity-70 focus:outline-none z-10',
          className,
          contentWidth ? "min-w-max" : "w-56",
        ])}
        style={style}
      >
        {props?.children}
      </HeadlessMenu.Items>
    );
  }
);

const Divider = ({ className, ...rest }: any) => {
  return (
    <div
      className={`w-full border-b border-gray-300 dark:border-gray-700 ${className}`}
      {...rest}
    />
  );
};

// Dropdown.Items = Items;
Dropdown.Item = Item;
Dropdown.Button = Button;
Dropdown.Menu = Menu;
Dropdown.Divider = Divider;

export default Dropdown;
