import { formatISO, parseISO, formatISO9075 } from "date-fns";

const MS_IN_MINUTE = 60000;
const MS_IN_SECOND = 1000;

export const getMonth = (month: number, startAtZero = false) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month - 1];
export const getDay = (day: number, startAtZero = false) =>
  [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][day - 1];

export const formatISODate = (date: string | Date) => {
  if (!date) return "Unknown";
  if (typeof date == "string") {
    date = parseISO(date);
  }
  return formatISO(date, { representation: "date" });
};
export const formatISODateTime = (date: string | Date) => {
  if (!date) return "Unknown";
  if (typeof date == "string") {
    date = parseISO(date);
  }
  return formatISO9075(date);
};
export const minutesToMs = (minutes) => (!minutes ? 0 : minutes * MS_IN_MINUTE);
export const secondsToMs = (seconds) =>
  !isValid(seconds) ? 0 : seconds * MS_IN_SECOND;
export const msToSeconds = (ms) => (!isValid(ms) ? 0 : ms / MS_IN_SECOND);
export const msToMinutes = (ms) => (!isValid(ms) ? 0 : ms / MS_IN_MINUTE);

export const formatYearMonthDay = (date: string | Date) => {
  if (!date) return "Unknown";
  if (typeof date == "string") {
    date = new Date(date);
  }
  return `${date.getFullYear()}-${(date.getMonth() + 1 + "").padStart(
    2,
    "0"
  )}-${(date.getDate() + "").padStart(2, "0")}`;
};

const isValid = (integer) => {
  if (
    integer === undefined ||
    integer === null ||
    integer < 0 ||
    isNaN(integer) ||
    integer == Infinity
  ) {
    return false;
  }
  return true;
};

export const friendlyDate = (date: string | Date) => {
  if (!date) return "";
  return new Date(date).toLocaleString();
};
