import {
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Select, Typography } from "app/components/generics";
import classNames from "classnames";
import {
  addMonths,
  format as dateFnsFormat,
  getYear,
  getMonth,
  isValid,
  startOfMonth,
  isWithinInterval,
  isBefore,
} from "date-fns";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { usePrevious } from "react-use";
import { addTimezoneOffset, hasTimezone, isEmpty } from "utils/functions.utils";

interface IDatePickerChildren {
  setDate: (date: [Date | undefined | null, Date | undefined | null]) => void;
  currentDate: Date;
  currentDateRange: [Date, Date];
}

interface IDatePicker {
  isRange?: boolean;
  block?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  showMonthYearDropdown?: boolean;
  showWeekPicker?: boolean;
  showRangePicker?: boolean;
  hint?: string;
  label?: string;
  message?: string;
  format?: string;
  defaultValue?: Date;
  value?: Date;
  error?: string;
  className?: string;
  footer?: any;
  onChange?: (date: [Date | null | undefined, Date | null | undefined]) => void;
  children?:
    | string
    | (({
        setDate,
        currentDate,
        currentDateRange,
      }: IDatePickerChildren) => React.ReactNode)
    | React.ReactNode;
}

const MAX_MONTHS = 12;
let breakpoint = 0;
const buildMonthYearDropdown = (date = new Date()) => {
  const monthWithYear: any = [];
  let monthCount = -12;
  const start = addMonths(date, monthCount);
  const end = addMonths(date, MAX_MONTHS);
  let current = start;
  while (current <= end) {
    const loopDate = addMonths(startOfMonth(current), monthCount);
    const label = dateFnsFormat(current, "MMMM yyyy");
    monthCount++;

    current = addMonths(date, monthCount);
    monthWithYear.push({
      label,
      date: loopDate,
      year: getYear(loopDate),
      month: getMonth(loopDate),
      value: loopDate.toISOString(),
    });
  }

  return monthWithYear;
};

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

const formatIncomingDate = (date) => {
  return !isValid(new Date(date!))
    ? undefined
    : !hasTimezone(date)
    ? addTimezoneOffset(date!)
    : new Date(new Date(date!));
};

export const RangePicker = (props: IDatePicker) => {
  const {
    isRange,
    showMonthYearDropdown,
    children,
    label,
    hint,
    message,
    footer,
    error,
    format,
    disabled,
    block,
    className,
    allowClear,
    value,
    defaultValue,
    onChange,
  } = props;
  const [key, setKey] = React.useState<string | undefined>(undefined);
  const [startDate, setStartDate] = React.useState<Date | null | undefined>(
    formatIncomingDate(defaultValue || value)
  );
  const [endDate, setEndDate] = React.useState<Date | null | undefined>(
    undefined
  );
  const prevDate = usePrevious(startDate);

  // React.useEffect(() => {
  //     if (startDate && !isEqual(prevDate!, startDate)) {
  //         setKey(getRandomUUID());
  //     }
  // }, [startDate])

  React.useEffect(() => {
    if (value?.[0]) {
      setStartDate(formatIncomingDate(value?.[0]));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
    if (value?.[1]) {
      setEndDate(formatIncomingDate(value?.[1]));
    }
  }, [value]);

  const handleOnChange = (
    date: [Date | null | undefined, Date | null | undefined]
  ) => {
    onChange && onChange(date);
    const [start, end] = date;
    if ((startDate && endDate) || isBefore(start!, startDate!)) {
      setStartDate(start);
      setEndDate(undefined);
    } else if (!startDate) {
      setStartDate(start);
    } else if (startDate && !endDate) {
      setEndDate(end);
    }
  };

  const clearValue = () => {
    setStartDate(undefined);
    setEndDate(undefined);
  };

  return (
    <div className={classNames([block ? "w-full" : "", className])}>
      {(label || hint) && (
        <div className="flex items-center justify-between mb-1">
          {label && <Input.Label htmlFor={label}>{label}</Input.Label>}
          {hint && <Input.Hint>{hint}</Input.Hint>}
        </div>
      )}
      <ReactDatePicker
        key={key}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        // @ts-ignore
        onChange={handleOnChange}
        selectsRange
        disabled={disabled}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        fixedHeight
        dateFormat="yyyy-MM-dd"
        // calendarClassName={'is-range-selector'}
        // popperClassName="react-datepicker-left"
        popperClassName={"is-range-selector"}
        // monthsShown={2}
        shouldCloseOnSelect={false}
        dayClassName={(date: Date) => {
          if (
            !startDate ||
            !endDate ||
            !isValid(new Date(startDate)) ||
            !isValid(new Date(endDate))
          )
            return "";
          const interval: any = { start: startDate, end: endDate };
          if (endDate?.getTime() < startDate?.getTime()) {
            interval.start = endDate;
            interval.end = startDate;
          }
          if (isWithinInterval(date, interval)) {
            return "react-datepicker__day--selected";
          }
          return "";
        }}
        // dayClassName={(date:Date) => showWeekPicker && isSameWeek(date, startDate!) ? 'react-datepicker__day--selected' : ''}
        customInput={
          <ButtonInput
            block
            readOnly={disabled}
            clearValue={clearValue}
            allowClear={allowClear}
          />
        }
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          monthDate,
          ...rest
        }) => {
          return (
            <div className="flex items-center justify-between px-2 py-2">
              {showMonthYearDropdown ? (
                <Select
                  options={buildMonthYearDropdown(date)}
                  block
                  className="mr-2"
                  // getOptionLabel={(option: any) => option.display}
                  // getOptionValue={(option: any) => option.date}
                  isSearchable={false}
                  // defaultValue={{ label: format(date, 'MMMM yyyy'), value: date.toISOString() }}
                  value={{
                    label: dateFnsFormat(date, "MMMM yyyy"),
                    value: date.toISOString(),
                  }}
                  footer={null}
                  // blurInputOnSelect
                  // @ts-ignore
                  onChange={({ year, month }) => {
                    changeYear(year);
                    changeMonth(month);
                  }}
                />
              ) : (
                <Typography>{dateFnsFormat(date, "MMMM yyyy")}</Typography>
              )}
              <div className="flex space-x-2">
                <Button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  size="sm"
                  htmlType="button"
                  className={`
                                        ${
                                          prevMonthButtonDisabled &&
                                          "cursor-not-allowed opacity-50"
                                        }
                                    `}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>

                <Button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  size="sm"
                  htmlType="button"
                  className={`
                                        ${
                                          nextMonthButtonDisabled &&
                                          "cursor-not-allowed opacity-50"
                                        }
                                    `}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </div>
          );
        }}
      >
        {typeof children == "function"
          ? children({
              setDate: handleOnChange,
              currentDate: startDate!,
              currentDateRange: [startDate!, endDate!],
            })
          : children}
      </ReactDatePicker>
      {footer !== null &&
        (!!message ? (
          <div className="flex justify-between mt-0.5">
            <div>
              {message && (
                <p
                  className={classNames([
                    "text-xs",
                    error
                      ? "text-red-500 dark:text-red-400"
                      : "text-gray-600 dark:text-gray-300",
                  ])}
                >
                  {message}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div style={{ minHeight: 16 }} />
        ))}
    </div>
  );
};

const ButtonInput = React.forwardRef(
  (
    {
      value,
      format = DEFAULT_DATE_FORMAT,
      onClick,
      block,
      clearValue,
      allowClear,
      ...rest
    }: any,
    ref: any
  ) => (
    <Button
      onClick={onClick}
      ref={ref}
      htmlType="button"
      block={block}
      disabled={rest.disabled || rest.readOnly}
      className="shadow-none group"
      // className='inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500'
    >
      <div className="flex items-center justify-between w-full">
        <span>
          {!isEmpty(value?.[0]) &&
          !isEmpty(value?.[1]) &&
          isValid(new Date(value?.[0])) &&
          isValid(new Date(value?.[1]))
            ? value
            : "Select a date"}
        </span>
        <div className="flex">
          {allowClear && value && (
            <a
              onClick={(e) => {
                clearValue();
                e.stopPropagation();
              }}
              className="hidden group-hover:block"
            >
              <Typography inline type="secondary" className="flex">
                {/* @ts-ignore */}
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ fontSize: 16 }}
                />
              </Typography>
            </a>
          )}
          {/* @ts-ignore */}
          <FontAwesomeIcon
            className={classNames(
              allowClear && value ? "block group-hover:hidden" : ""
            )}
            icon={faCalendarDays}
            style={{ fontSize: 18 }}
          />
        </div>
      </div>
    </Button>
  )
);
