import React, { CSSProperties } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useRowSelect,
  useBlockLayout,
  useGlobalFilter,
  useExpanded,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "app/components/generics/Typography";
import classNames from "classnames";
import { isEmpty, isUndefined, json } from "utils/functions.utils";
import { Checkbox, Pagination } from "app/components/generics";
import styled from "styled-components";
import { usePrevious, useUpdateEffect } from "react-use";
import Loader from "app/components/generics/Loader";
import { useHistory, useLocation } from "react-router";
import { objectToQueryString, queryStringToObject } from "utils/query.util";
import { MultiSelectColumnFilter } from "app/components/generics/Table/components/filters/MultiSelectColumnFilter";
import { TableColumn } from "shared/types/table-column.type";
import VirtualTable, { IVirtualTable } from "./virtual-table";
import {
  faInbox,
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/pro-duotone-svg-icons";

const Root = styled.div`
  /* This is required to make the table full-width */
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;

  table {
    th,
    td {
      /* The secret sauce */
      /* Each cell should grow equally */

      /* width: 1%; */
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
    }
  }
`;

interface IPagination {
  currentPage: number;
  nextPage: number;
  prevPage: number;

  showAll: boolean;
  disabled: boolean;

  pageIndex: number;
  pageSize: number;
  page: number;

  options: number[];

  total: number;
  pageCount: number;

  hideOnSinglePage: boolean;
}

interface IPaginationParams {
  showSizeChanger: boolean;
  size: "sm" | "md" | "lg";
  onPrevious: Function;
  onNext: Function;
  disableNext: boolean;
  disablePrevious: boolean;
  pageIndex: number;
  pageCount: number;
  gotoPage: (num: number) => void;
  defaultPageSize: number;
  onSizeChange: (size: number) => void;
}

interface IOnRow {
  onClick?: (id?: any, row?: any) => any;
  onDoubleClick?: (id?: any, row?: any) => any;
  onContextMenu?: (id?: any, row?: any) => any;
  onMouseEnter?: (id?: any, row?: any) => any;
  onMouseLeave?: (id?: any, row?: any) => any;
}

interface IRowSelection {
  onChange: Function;
  selectAll: boolean;
  onSelect: Function;
  onSelectAll: Function;
  onSelectPageAll: Function;
  hideSelectAll: boolean;
  preserveSelectedKeys: boolean;
  selectedFlatRows: any[];
  selectedRowIds: string[] | number[];
}

interface IInitialState {
  globalFilter: any;
  pageIndex: any;
  pageSize: any;
  filters: any;
  sortBy: any;
}

interface ITable {
  persist?: boolean;
  rounded?: boolean;
  fill?: boolean;
  bordered?: boolean;
  striped?: boolean;
  enableSort?: boolean;
  loading?: boolean;
  rowKey?: Function | string;
  footer?: boolean;
  header?: boolean;
  block?: boolean;
  allowExpand?: boolean;
  disableExpandAll?: boolean;
  manual?: boolean;
  pageCount?: number;
  data?: any;
  columns: TableColumn[];
  defaultSort?: any[];
  defaultFilters?: any[];
  size?: "sm" | "md" | "lg";
  paginationMode?: "buttons" | "default";
  variant?: "unstyled" | "simpled" | "striped";
  onChange?: Function;
  initialState?: Partial<IInitialState>;
  onRow?(index?: any, row?: any): IOnRow;
  getSubRows?: Function;
  rowSelection?: Partial<IRowSelection>;
  pagination?: Partial<IPagination> | null;
  Pagination?: React.FC<IPaginationParams>;
}

interface ICompoundedInputComponent
  extends React.ForwardRefExoticComponent<
    ITable & React.RefAttributes<HTMLTableElement>
  > {
  Label: any;
  Hint: any;
  Virtual: React.ForwardRefExoticComponent<
    IVirtualTable & React.RefAttributes<HTMLDivElement>
  >;
}

interface ITableOptions {
  getRowId?: Function;
  pageCount?: number;
  manualPagination?: boolean;
  manualFilters?: boolean;
  manualSortBy?: boolean;
  autoResetSelectedRows?: boolean;
}

const textAlign = {
  left: "text-left justify-start",
  center: "text-center justify-center",
  right: "text-right justify-end",
};

const bodySizes = {
  sm: "px-1.5 py-1 text-xs font-normal",
  md: "px-2.5 py-2 text-sm font-normal",
  lg: "p-3 text-sm font-normal",
};
const footerSizes = {
  sm: "p-1.5",
  md: "p-2",
  lg: "p-3",
};
const paginationSizes = {
  sm: "px-2 py-1",
  md: "px-3 py-1.5",
  lg: "px-3.5 py-2",
};
const headerSizes = {
  sm: "px-1.5 py-1 text-sm font-medium",
  md: "px-2.5 py-2 text-sm font-medium",
  lg: "p-3 text-md font-medium",
};
const headerSizesBorderless = {
  sm: "px-1.5 py-0.5 my-0.5",
  md: "px-2.5 py-1 my-1",
  lg: "px-3 py-1.5 my-1.5",
};

const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_SIZE_ARRAY = [20, 50, 100];
const DEFAULT_COLUMN_WIDTH = 125;

const IndeterminateCheckbox = React.forwardRef(
  // @ts-ignore
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef(null);
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      //   @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      //   @ts-ignore
      <input type="checkbox" ref={resolvedRef} {...rest} />
    );
  }
);

const buildColGroups = (column, fixed, index) => {
  if (!!column?.columns?.length) {
    return column?.columns?.map((v, i) => buildColGroups(v, fixed, i));
  }

  return fixed ? (
    <col key={index} style={{ width: column.width || DEFAULT_COLUMN_WIDTH }} />
  ) : column.width ? (
    <col key={index} style={{ width: column.width }} />
  ) : (
    <col key={index} />
  );
};

const Table = React.forwardRef(
  (
    {
      // CUSTOM
      size,
      variant,
      pagination,
      manual,
      fill,
      rowSelection,
      enableSort,
      paginationMode = "default",
      loading,
      rowKey,
      block = true,
      onChange,
      initialState: initialStateFromProps = {} as any,
      disableExpandAll,
      allowExpand,
      footer = true,
      header = true,
      bordered,
      onRow,
      persist,

      getSubRows,
      columns,
      data,
    }: ITable,
    ref
  ) => {
    const location = useLocation();
    const history = useHistory();
    let tableOptions: ITableOptions = {};
    const hookArray: any[] = [useGlobalFilter];
    const [toggleLeftFixedShadow, setToggleLeftFixedShadow] =
      React.useState(false);

    // ###########
    // HOOKS (Order matters)
    // ###########
    // Filter
    if (columns?.some((v) => v.canFilter)) {
      hookArray.push(useFilters);
    }
    // Sort by
    if (enableSort) {
      hookArray.push(useSortBy);
    }
    // Sort by
    if (allowExpand) {
      hookArray.push(useExpanded);
      // tableOptions.getSubRows = (row, relativeIndex) => typeof row?.[rowKey || 'id'] != 'undefined' ? row?.[rowKey || 'id'] : relativeIndex;
      if (getSubRows) {
        // @ts-ignore
        tableOptions.getSubRows = getSubRows;
      }
    }
    // Pagination
    if (!pagination?.showAll) {
      hookArray.push(usePagination);
    }
    // Row selection
    if (rowSelection && typeof rowSelection == "object") {
      hookArray.push(useRowSelect);
      tableOptions = { ...tableOptions, ...rowSelection };
    }

    // Set row key
    tableOptions.getRowId = React.useCallback((row, relativeIndex, parent) => {
      if (typeof rowKey == "function") {
        return rowKey(row, relativeIndex, parent);
      } else if (typeof row?.[rowKey || "id"] != "undefined") {
        if (parent) {
          return `${parent?.[rowKey || "id"]}.${relativeIndex}`;
        } else {
          return row?.[rowKey || "id"];
        }
      }
      return relativeIndex;
    }, []);

    const initialState: any = React.useMemo(() => {
      let incomingState = { ...initialStateFromProps };

      if (typeof pagination == "object") {
        incomingState = { ...incomingState, ...pagination };
      }

      const query = queryStringToObject(location.search);
      let pageIndex = DEFAULT_PAGE_INDEX;
      let pageSize = pagination?.options?.[0] || DEFAULT_PAGE_SIZE;
      if (!isEmpty(query.page)) {
        pageIndex =
          parseInt(query.page) - 1 > DEFAULT_PAGE_INDEX
            ? parseInt(query.page) - 1
            : DEFAULT_PAGE_INDEX;
      }
      if (!isEmpty(query.results)) {
        pageSize = DEFAULT_PAGE_SIZE_ARRAY.includes(query.results)
          ? parseInt(query.results)
          : pagination?.options?.[0] ?? DEFAULT_PAGE_SIZE;
      }
      incomingState.pageIndex = pageIndex;
      incomingState.pageSize = pageSize;
      if (!manual && persist) {
      }
      return incomingState;
    }, []);

    React.useEffect(() => {
      if (persist) {
        const query = queryStringToObject(location.search);
        let updateQuery = false;
        if (isEmpty(query.page)) {
          query.page = DEFAULT_PAGE_INDEX + 1;
          updateQuery = true;
        }
        if (isEmpty(query.results)) {
          query.results = pagination?.options?.[0] ?? DEFAULT_PAGE_SIZE;
          updateQuery = true;
        }
        if (updateQuery) {
          history.push({ search: objectToQueryString(query) });
        }
      }
    }, []);

    const defaultEmptyArray = React.useMemo(() => [], []);

    if (manual) {
      tableOptions.manualPagination = true;
      tableOptions.manualFilters = true;
      tableOptions.manualSortBy = true;
      tableOptions.autoResetSelectedRows = false;
      if (pagination?.pageCount) {
        tableOptions.pageCount = pagination.pageCount;
      }
    }

    const {
      // Table options
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      state,

      // Pagination options
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      pageIndex,
      pageSize,
      setPageSize,

      // Selection options
      selectedFlatRows,
      toggleAllPageRowsSelected,
      toggleAllRowsSelected,
      toggleRowSelected,

      // Filter options
      setGlobalFilter,

      ...rest
    }: { [x: string]: any } = useTable(
      {
        ...tableOptions,
        columns,
        initialState,
        data: React.useMemo(
          () => (Array.isArray(data) ? data : defaultEmptyArray),
          [data]
        ),
        defaultColumn: {
          // @ts-ignore
          Filter: MultiSelectColumnFilter,
          filter: "includesSome",
        },
      },
      ...hookArray,
      (hooks) => {
        hooks.visibleColumns.push((columns) => {
          const cols: any = [];

          if (allowExpand) {
            cols.push({
              id: `expander`,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({
                getToggleAllRowsExpandedProps,
                isAllRowsExpanded,
              }) => {
                if (disableExpandAll) return null;
                return (
                  <div>
                    <Typography
                      type="primary"
                      hover
                      flat
                      {...getToggleAllRowsExpandedProps()}
                    >
                      <FontAwesomeIcon
                        icon={isAllRowsExpanded ? faSquareMinus : faSquarePlus}
                        size="lg"
                      />
                    </Typography>
                  </div>
                );
              },
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row, ...rest }) => {
                // if (!row.subRows?.length) return <span/>;
                if (!row.subRows?.length) return null;
                return (
                  <button disabled={!row.subRows?.length}>
                    <Typography
                      type="primary"
                      flat
                      hover
                      disabled={!row.subRows?.length}
                      {...row.getToggleRowExpandedProps({
                        disabled: !row.subRows?.length,
                        style: {
                          // We can even use the row.depth property
                          // and paddingLeft to indicate the depth
                          // of the row
                          paddingLeft: `${row.depth * 2}rem`,
                        },
                      })}
                    >
                      <FontAwesomeIcon
                        icon={row.isExpanded ? faSquareMinus : faSquarePlus}
                        size="lg"
                      />
                    </Typography>
                  </button>
                );
              },
              collapse: true,
            });
          }

          if (rowSelection) {
            cols.push({
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({
                getToggleAllPageRowsSelectedProps,
                onSelectPageAll,
                page,
                rowsById,
                ...rest
              }) => {
                if (rowSelection?.hideSelectAll) {
                  return null;
                }

                const handleOnClick = (event) => {
                  getToggleAllPageRowsSelectedProps().onChange(event);
                  if (onSelectPageAll) {
                    onSelectPageAll({
                      isAllSelected: !rest.isAllPageRowsSelected,
                      selectedIds: Object.keys(rowsById).map((v) =>
                        parseInt(v)
                      ),
                      selectedRows: rest?.rows?.map((v) => v?.original),
                    });
                  }
                };

                const rowProps: any = {};
                if (manual && Array.isArray(rowSelection?.selectedRowIds)) {
                  let allSelected = false;
                  let someSelected = false;

                  if (rowSelection?.selectedRowIds?.length) {
                    allSelected = Object.keys(rowsById).every((v) => {
                      // @ts-ignore
                      if (rowSelection?.selectedRowIds?.includes(parseInt(v))) {
                        someSelected = true;
                        return true;
                      }
                      return false;
                    });
                  }

                  if (allSelected) {
                    rowProps.checked = true;
                  } else if (!allSelected && someSelected) {
                    rowProps.indeterminate = true;
                  }
                }

                return (
                  <div>
                    <Checkbox
                      {...getToggleAllPageRowsSelectedProps(rowProps)}
                      align="center"
                      onChange={handleOnClick}
                    />
                  </div>
                );
              },
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row, onSelect, rowsById, ...rest }) => {
                const handleOnClick = (event) => {
                  row.getToggleRowSelectedProps().onChange(event);
                  if (onSelect) {
                    onSelect({
                      isSelected: !row.isSelected,
                      selectedId: row.id,
                      selectedRow: row.original,
                    });
                  }
                };

                const rowProps: any = {};
                if (manual && Array.isArray(rowSelection?.selectedRowIds)) {
                  rowProps.checked = rowSelection?.selectedRowIds?.includes(
                    // @ts-ignore
                    row?.id
                  );
                }

                return (
                  <div>
                    <Checkbox
                      {...row.getToggleRowSelectedProps(rowProps)}
                      align="center"
                      onChange={handleOnClick}
                    />
                  </div>
                );
              },
              collapse: true,
            });
          }

          cols.push(
            ...columns.map((v) => ({
              ...v,
              // @ts-ignore
              disableFilters: !v.canFilter,
              disableSortBy: !v.Header,
            }))
          );

          return cols;
        });
      }
    );
    const prevState = usePrevious(state);
    const prevFilteredFlatRows = usePrevious(rest.filteredFlatRows);

    const showEmpty =
      !loading &&
      ((Object.hasOwnProperty("filteredRows") && !rest.filteredRows?.length) ||
        !rows?.length);

    // ###############################################################################
    // Ref pass through to allow for table instance methods to be called by the parent
    // ###############################################################################
    React.useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        toggleAllPageRowsSelected,
        toggleAllRowsSelected,
        clearFilters: () => rest.setAllFilters([]),
        clearSort: () => rest.setSortBy([]),
        reset: () => {
          gotoPage(DEFAULT_PAGE_INDEX);
          toggleAllRowsSelected?.(false);
        },
      }),
      [
        toggleAllPageRowsSelected,
        toggleAllRowsSelected,
        rest.setAllFilters,
        rest.setSortBy,
      ]
    );

    // TODO: Check back with this as it may cause issues on large data sets.
    // Possibly need a better way to toggle controlled selection
    // React.useEffect(() => {
    //     if (Array.isArray(rowSelection?.selectedRowIds)) {
    //         data?.forEach(row => {
    //             // @ts-ignore
    //             toggleRowSelected(row.id, rowSelection?.selectedRowIds?.includes(row?.id))
    //         });
    //     }
    // }, [rowSelection?.onSelect, rowSelection?.selectedRowIds]);

    // React.useEffect(() => {
    //     if (rowSelection?.selectAll !== undefined) {
    //         // toggleAllPageRowsSelected(rowSelection?.selectAll)
    //         // toggleAllRowsSelected(rowSelection?.selectAll)
    //     }
    // }, [rowSelection?.selectAll, data]);

    // ##############################################################################
    // Handle various on change effects and provide the action in the payload
    // ##############################################################################
    if (isNaN(state.pageIndex)) state.pageIndex = 0;
    const prevRows = usePrevious(selectedFlatRows);
    const prevFilters = usePrevious(state.filters);
    const prevSortBy = usePrevious(state.sortBy);
    const prevPageSize = usePrevious(state.pageSize);
    const prevPageIndex = usePrevious(state.pageIndex);
    const prevGlobalFilter = usePrevious(state.globalFilter);

    useUpdateEffect(() => {
      let action = "";
      if (
        Array.isArray(prevRows) &&
        prevRows?.length != selectedFlatRows?.length
      )
        action = "ROW";
      if (
        prevFilters &&
        json.stringify(state.filters) != json.stringify(prevFilters)
      )
        action = "FILTER";
      if (
        prevSortBy &&
        json.stringify(state.sortBy) != json.stringify(prevSortBy)
      )
        action = "SORT";
      if (
        !isUndefined(prevPageSize) &&
        !isUndefined(prevPageIndex) &&
        (prevPageSize != state.pageSize || prevPageIndex != state.pageIndex)
      )
        action = "PAGE";
      if (prevGlobalFilter != state.globalFilter) action = "FILTER";

      if (action) {
        if (state.sortBy?.length) {
          state.sortBy = state?.sortBy?.filter?.(
            (v) => !isEmpty(v.id) && v.id != "undefined"
          );
        }

        if (action != "ROW") {
          let pageIndex = state.pageIndex;
          if (persist) {
            const query = queryStringToObject(location.search);
            query.page = state.pageIndex + 1;
            query.results = state.pageSize;
            history.push({ search: objectToQueryString(query) });
          }
          if (action == "FILTER" && pageIndex != DEFAULT_PAGE_INDEX) {
            pageIndex = DEFAULT_PAGE_INDEX;
            gotoPage(pageIndex);
          }

          // NOTE: 8/19/2022 - Added filter and page check due to action being called on load and causing double fetching
          // NOTE: for paginated pages
          if (
            action == "PAGE" &&
            (rest.filteredFlatRows?.length != prevFilteredFlatRows?.length ||
              prevState?.pageIndex != state?.pageIndex)
          ) {
            onChange &&
              onChange?.(
                isEmpty(state.pageIndex) || isNaN(state.pageIndex)
                  ? DEFAULT_PAGE_INDEX
                  : state.pageIndex,
                isEmpty(state.pageSize) || isNaN(state.pageSize)
                  ? DEFAULT_PAGE_SIZE
                  : state.pageSize,
                {
                  ...state,
                  action,
                  next: prevPageIndex < state.pageIndex,
                  previous: prevPageIndex > state.pageIndex,
                  filteredData: rest.filteredFlatRows,
                  currentData: rest.filteredFlatRows,
                }
              );
          }
        } else {
          if (rowSelection?.onChange) {
            rowSelection.onChange(
              selectedFlatRows?.map((v) => v?.original),
              rest.isAllRowsSelected,
              {
                ...state,
                action,
                data: rest.flatRows,
                filteredData: rest.filteredFlatRows,
                currentData: rest.filteredRows,
              }
            );
          }
        }
      }
    }, [selectedFlatRows, state, onChange]);

    // NOTE: 5/4/2022
    // NOTE: Added previous page index and size check because it was causing
    // NOTE: issues when spreading in the pagination and only changing
    // NOTE: one property inside parent components
    // const prevPaginationPageIndex = usePrevious(pagination?.pageIndex);
    // const prevPaginationPageSize = usePrevious(pagination?.pageSize);
    React.useEffect(() => {
      if (manual && pagination && state) {
        if (state.pageIndex != pagination.pageIndex) {
          gotoPage(pagination.pageIndex);
        }
        if (state.pageSize != pagination.pageSize) {
          setPageSize(pagination.pageSize);
        }
      }
    }, [pagination?.pageIndex, pagination?.pageSize]);

    // #######################################
    // Set pagination items
    // #######################################
    // if (!!Object.keys(pagination)?.length) {
    //     if (isUndefined(pagination.total)) pagination.total = rest.filteredRows?.length || rows?.length;
    // }
    const maxToCountByPageSize = (state.pageIndex + 1) * state.pageSize;
    const viewFrom =
      state.pageIndex == DEFAULT_PAGE_INDEX
        ? 1
        : state.pageIndex * state.pageSize + 1;
    let viewTo =
      (rest.filteredRows?.length || rows?.length) < state.pageSize
        ? rest.filteredRows?.length || rows?.length
        : state.pageIndex == DEFAULT_PAGE_INDEX
        ? state.pageSize
        : maxToCountByPageSize;
    const resultsCount = manual ? pagination?.total : rest?.data?.length;

    if (
      !isEmpty(pagination?.page) &&
      !isEmpty(pagination?.pageCount) &&
      pagination?.page == pagination?.pageCount
    ) {
      viewTo = pagination?.total;
    } else if (viewTo > resultsCount) {
      viewTo = resultsCount;
    }

    // #######################################
    // Table components
    // #######################################

    const _handleFixedPropsUpdateOnScroll = (e) => {
      setToggleLeftFixedShadow(!!e.target?.scrollLeft);
    };

    const headerRow = React.useMemo(() => {
      let fixedOffset = 0;
      let lastLeftFixedColumnIndex = -1;
      let firstRightFixedColumnIndex = -1;
      columns.forEach((item, index) => {
        if (item.fixed == "left") lastLeftFixedColumnIndex = index;
        if (item.fixed == "right" && firstRightFixedColumnIndex == -1)
          firstRightFixedColumnIndex = index;
      });
      return headerGroups.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps({ style: { width: "100%" } })}
          className={classNames([
            // bordered ? 'divide-x divide-gray-200 dark:divide-gray-700' : '',
            "dark:bg-gray-700 bg-gray-50",
          ])}
        >
          {headerGroup.headers.map((column, i) => {
            const headerStyle = {
              ...column.getHeaderProps(
                enableSort &&
                  (column.sortable || typeof column.sortable == "undefined")
                  ? column.getSortByToggleProps()
                  : {}
              ).style,
            };

            if (column.id == "selection" || column.id == "expander")
              headerStyle.width = 60;

            const classes: any = [];

            if (["left", "right"].includes(column.fixed) && column.width) {
              headerStyle[column.fixed] = `${fixedOffset}px`;
              headerStyle.position = "sticky";
              headerStyle.zIndex = 2;
              fixedOffset += column.width;
              classes.push("cell-fixed");
              if (
                column.fixed == "left" &&
                i == lastLeftFixedColumnIndex &&
                toggleLeftFixedShadow
              )
                classes.push("cell-fixed-left-last");
              if (column.fixed == "right" && i == firstRightFixedColumnIndex)
                classes.push("cell-fixed-right-first");
            }

            return (
              <th
                //@ts-ignore
                {...column.getHeaderProps({
                  ...(enableSort &&
                  column.canSort &&
                  (column.sortable || typeof column.sortable == "undefined")
                    ? column.getSortByToggleProps()
                    : {}),
                  className: classNames([
                    ...classes,
                    !column.disableSortBy &&
                    column.canSort &&
                    (column.sortable ||
                      typeof column.sortable == "undefined") &&
                    !column.originalId?.includes("_placeholder")
                      ? "hover:bg-gray-200 dark:hover:bg-gray-700"
                      : "",
                    "bg-transparent border-b border-gray-300 dark:border-gray-700 relative group",
                    headerGroup.headers.length - 1 != i ? "header-cell" : "",
                    //@ts-ignore
                    column?.isSorted ? "dark:bg-gray-700" : "",
                  ]),
                  style: headerStyle,
                })}
              >
                <div
                  className={classNames([
                    column.id == "selection" || column.id == "expander"
                      ? "justify-center text-center"
                      : `${textAlign[column?.align] || "text-left"}`,
                    "flex flex-auto items-center h-full",
                    bordered
                      ? //@ts-ignore
                        headerSizes[size] || headerSizes.md
                      : //@ts-ignore
                        headerSizesBorderless[size] || headerSizesBorderless.md,
                    "text-gray-800",
                    "dark:text-gray-200",
                    "uppercase",
                    // 'tracking-wider',
                    "w-full",
                  ])}
                >
                  <div className="flex-auto inline-block">
                    <Typography className="font-medium" bold size="xs">
                      {column.render("Header") ?? null}
                    </Typography>
                  </div>
                  {(column.id != "selection" || column.id != "expander") &&
                    ((!!column.Header &&
                      !column.disableFilters &&
                      column.canFilter &&
                      column.Filter) ||
                      (!!column.Header &&
                        column.canSort &&
                        (column.sortable ||
                          typeof column.sortable == "undefined"))) && (
                      <div
                        className={classNames([
                          "float-right flex",
                          column.canFilter && column.Filter ? "w-14" : "",
                        ])}
                      >
                        <div className="flex items-center w-4 pl-2">
                          {!!column.Header &&
                            column.canSort &&
                            (column.sortable ||
                              typeof column.sortable == "undefined") && (
                              <div
                                className={classNames([
                                  "items-center group-hover:flex",
                                  column.isSorted ? "flex" : "hidden",
                                ])}
                              >
                                {column.isSorted ? (
                                  <FontAwesomeIcon
                                    className="text-blue-400"
                                    icon={
                                      column.isSortedDesc
                                        ? faSortDown
                                        : faSortUp
                                    }
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faSort}
                                    className="opacity-30"
                                  />
                                )}
                              </div>
                            )}
                        </div>
                        {!!column.Header &&
                          !column.disableFilters &&
                          column.canFilter &&
                          column.Filter && (
                            <div className="ml-2 mr-1">
                              {column.render("Filter")}
                            </div>
                          )}
                      </div>
                    )}
                </div>
              </th>
            );
          })}
        </tr>
      ));
    }, [headerGroups]);

    // Decide if table requires a fixed layout which then will cause us to ensure columns have a width
    let tableLayout = "table-auto";
    const requiresFixedTable = React.useMemo(
      () => columns.some((v) => v.fixed) || columns.every((v) => v.width),
      []
    );

    if (requiresFixedTable) {
      tableLayout = "table-fixed";
    }

    const colGroup = React.useMemo(() => {
      return (
        <colgroup>
          {rowSelection && <col />}
          {allowExpand && <col />}
          {columns.map((column, i) =>
            buildColGroups(column, requiresFixedTable, i)
          )}
        </colgroup>
      );
    }, [headerGroups]);

    const style: CSSProperties = {};

    if (loading) {
      style.minHeight = 200;
    }

    // Render the UI for your table
    return (
      <Root
        className={classNames([
          // 'overflow-hidden rounded bg-transparent',
          "rounded bg-transparent",
          bordered ? "border border-gray-200 dark:border-gray-700" : "",
          fill ? "flex-1" : "",
        ])}
        // style={{ maxWidth: 700 }}
        style={style}
      >
        {header && (
          <div
            className={classNames([
              `w-full flex items-end justify-between bg-transparent ${
                footerSizes[size!] || footerSizes.md
              }`,
              // showEmpty ? '' : 'flex-1'
            ])}
          >
            <div>
              {loading ? (
                <Typography type="secondary" size={size}>
                  Loading...
                </Typography>
              ) : (
                !pagination?.showAll &&
                ((Object.hasOwnProperty("filteredRows") &&
                  !rest.filteredRows?.length) ||
                !rows?.length ? (
                  <Typography type="secondary" size={size}>
                    No results
                  </Typography>
                ) : (
                  <Typography type="secondary" size={size}>
                    Showing <span className="font-medium">{viewFrom}</span> to{" "}
                    <span className="font-medium">{viewTo}</span> of{" "}
                    {/* Showing <span className='font-medium'>{viewFrom}</span> to <span className='font-medium'>{viewTo >= pagination.total! ? pagination.total : viewTo}</span> of{' '} */}
                    <span className="font-medium">
                      {pagination?.total ?? rest?.data?.length}
                    </span>{" "}
                    results
                    {/* <span className='font-medium'>{manual ? pagination?.total : rest?.data?.length }</span> results */}
                  </Typography>
                ))
              )}
            </div>

            {!pagination?.showAll &&
              !!rest?.data?.length &&
              (paginationMode == "buttons" ? (
                <Pagination.Buttons
                  // @ts-ignore
                  size={size}
                  onPrevious={previousPage}
                  onNext={nextPage}
                  disableNext={!canNextPage}
                  disablePrevious={!canPreviousPage}
                  pageIndex={state.pageIndex}
                  pageCount={pageCount || state.pageCount}
                  gotoPage={gotoPage}
                  showSizeChanger
                  pageSizeOptions={pagination?.options}
                  defaultPageSize={state.pageSize}
                  onSizeChange={(size) => setPageSize(size)}
                />
              ) : (
                <Pagination
                  size={size}
                  onPrevious={previousPage}
                  onNext={nextPage}
                  disableNext={!canNextPage}
                  disablePrevious={!canPreviousPage}
                  pageIndex={state.pageIndex}
                  pageCount={pageCount || state.pageCount}
                  gotoPage={gotoPage}
                  showSizeChanger
                  pageSizeOptions={pagination?.options}
                  defaultPageSize={state.pageSize}
                  onSizeChange={(size) => setPageSize(size)}
                />
              ))}
          </div>
        )}
        <div
          onScroll={_handleFixedPropsUpdateOnScroll}
          className={classNames([
            "relative",
            showEmpty && fill ? "flex-1" : "",
            // 'overflow-hidden',
            // 'overflow-x-auto',
          ])}
        >
          {loading && <Loader abs overlay center size="lg" />}
          <table
            className={`${tableLayout} ${block ? "w-full" : ""} ${
              bordered ? "table-bordered" : ""
            }`}
          >
            {colGroup}
            <thead className="">{headerRow}</thead>
            <tbody
              {...getTableBodyProps()}
              className="divide-y divide-gray-300 dark:divide-gray-700"
            >
              {(page || rows)?.map((row, i) => {
                prepareRow(row);
                let fixedOffset = 0;
                let lastLeftFixedColumnIndex = -1;
                let firstRightFixedColumnIndex = -1;
                columns.forEach((item, index) => {
                  if (item.fixed == "left") lastLeftFixedColumnIndex = index;
                  if (item.fixed == "right" && firstRightFixedColumnIndex == -1)
                    firstRightFixedColumnIndex = index;
                });
                // let bgClass = 'bg-white dark:bg-gray-800';
                let bgClass = "bg-transparent";
                const rowStyle: CSSProperties = { width: "100%" };

                if (onRow) {
                  rowStyle.cursor = "pointer";
                }

                if (variant == "striped") {
                  bgClass = "bg-gray-50 dark:bg-gray-800";
                  if (i % 2 === 0) bgClass = "bg-white dark:bg-gray-700";
                }

                if (!requiresFixedTable) {
                  bgClass = "bg-transparent dark:bg-transparent";
                }

                let rowFunctions: IOnRow = {};

                if (typeof onRow == "function") {
                  rowFunctions = onRow(row.id, row.original);
                }

                return (
                  <tr
                    className={classNames([
                      // variant == 'striped' ? i % 2 === 0 ? 'bg-white dark:bg-gray-700' : 'bg-gray-50 dark:bg-gray-800' : '',
                      // bordered ? 'divide-x divide-gray-200 dark:divide-gray-700' : '',
                      "hover:bg-gray-300 hover:bg-opacity-30 dark:hover:bg-blue-200 dark:hover:bg-opacity-5",
                    ])}
                    {...row.getRowProps({
                      style: rowStyle,
                    })}
                    {...(typeof rowFunctions == "object" ? rowFunctions : {})}
                  >
                    {row.cells.map((cell, j) => {
                      const tableCellStyle = {
                        ...cell.getCellProps().style,
                        // zIndex: 1
                      };
                      const classes: any = [];

                      if (
                        ["left", "right"].includes(cell.column.fixed) &&
                        cell.column.width
                      ) {
                        tableCellStyle[cell.column.fixed] = `${fixedOffset}px`;
                        fixedOffset += cell.column.width;
                        classes.push("cell-fixed");
                        if (
                          cell.column.fixed == "left" &&
                          j == lastLeftFixedColumnIndex &&
                          toggleLeftFixedShadow
                        )
                          classes.push("cell-fixed-left-last");
                        if (
                          cell.column.fixed == "right" &&
                          j == firstRightFixedColumnIndex
                        )
                          classes.push("cell-fixed-right-first");
                      }

                      return (
                        <td
                          //@ts-ignore
                          // className={classNames(
                          //     cell.column.id == 'selection' ? 'text-center' : textAlign[cell.column?.align] || 'text-left',
                          //     `${bodySizes[size] || bodySizes.md} text-sm font-medium text-gray-800 dark:text-gray-200`
                          // )}
                          {...cell.getCellProps({
                            className: classNames(
                              ...classes,
                              cell.column.id == "selection"
                                ? "text-center cursor-default"
                                : textAlign[cell.column?.align] || "text-left",
                              `${
                                bodySizes[size!] || bodySizes.md
                              } text-gray-800 dark:text-gray-200`,
                              bgClass,
                              cell.column?.id == "selection"
                                ? "dark:hover:bg-gray-700 hover:bg-gray-300 hover:bg-opacity-30 dark:hover:bg-opacity-30"
                                : ""
                              // bordered ? 'table-cell' : '',
                            ),
                            style: tableCellStyle,
                            // style: {
                            //     width: cell.column.id == 'selection' ? 60 /*: cell.column.width ? cell.column.width */: '100%',
                            // }
                          })}
                          onClick={(e) => {
                            // !cell.column?.Header || cell.column?.id == 'selection'  && e?.stopImmediatePropagation?.()
                            // !cell.column?.Header || cell.column?.id == 'selection'  && e?.preventDefault?.()
                            (!cell.column?.Header ||
                              cell.column?.preventClick ||
                              ["selection", "id", "expander"].includes(
                                cell.column?.id
                              )) &&
                              e?.stopPropagation?.();
                          }}
                        >
                          {cell.column.ellipsis ? (
                            <div
                              className={classNames(
                                "relative flex items-center h-5",
                                textAlign[cell.column?.align]
                                  ? `${textAlign[cell.column?.align]} flex-1`
                                  : ""
                              )}
                              title={
                                typeof cell.render("Cell")?.props?.value ==
                                "string"
                                  ? cell.render("Cell")?.props?.value
                                  : null
                              }
                            >
                              <div
                                className={classNames(
                                  "absolute",
                                  "flex",
                                  "left-0",
                                  "right-0"
                                )}
                              >
                                <div
                                  className={classNames(
                                    "flex-1",
                                    cell.column.ellipsis ? "truncate" : "",
                                    cell.column.id == "selection" ||
                                      cell.column.id == "expander"
                                      ? "flex justify-center"
                                      : ""
                                  )}
                                >
                                  {isEmpty(cell?.value)
                                    ? null
                                    : cell.render("Cell") ?? null}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={classNames(
                                cell.column.id == "selection" ||
                                  cell.column.id == "expander"
                                  ? "justify-center"
                                  : "",
                                "flex items-center h-full",
                                textAlign[cell.column?.align]
                                  ? `${textAlign[cell.column?.align]} flex-1`
                                  : ""
                              )}
                            >
                              {isEmpty(cell?.value) &&
                              cell.column.id != "selection" &&
                              cell.column.id != "expander"
                                ? null
                                : cell.render("Cell") ?? null}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {showEmpty && (
            <div
              className={classNames([
                "flex justify-center items-center flex-col py-8",
                fill ? "h-full" : "",
              ])}
            >
              <Typography type="secondary">
                <FontAwesomeIcon icon={faInbox} size="5x" />
              </Typography>
              <Typography type="secondary" className="mt-1" size="md">
                No results
              </Typography>
            </div>
          )}
        </div>
        {footer && (
          <div
            className={classNames([
              `w-full flex items-end justify-between bg-transparent border-t border-gray-300 dark:border-gray-700 ${
                footerSizes[size!] || footerSizes.md
              }`,
              // showEmpty ? '' : 'flex-1'
            ])}
          >
            <div>
              {loading ? (
                <Typography type="secondary" size={size}>
                  Loading...
                </Typography>
              ) : (
                !pagination?.showAll &&
                ((Object.hasOwnProperty("filteredRows") &&
                  !rest.filteredRows?.length) ||
                !rows?.length ? (
                  <Typography type="secondary" size={size}>
                    No results
                  </Typography>
                ) : (
                  <Typography type="secondary" size={size}>
                    Showing <span className="font-medium">{viewFrom}</span> to{" "}
                    <span className="font-medium">{viewTo}</span> of{" "}
                    {/* Showing <span className='font-medium'>{viewFrom}</span> to <span className='font-medium'>{viewTo >= pagination.total! ? pagination.total : viewTo}</span> of{' '} */}
                    <span className="font-medium">
                      {pagination?.total ?? rest?.data?.length}
                    </span>{" "}
                    results
                  </Typography>
                ))
              )}
            </div>
            {!pagination?.showAll &&
              !!rest?.data?.length &&
              (paginationMode == "buttons" ? (
                <Pagination.Buttons
                  // @ts-ignore
                  size={size}
                  onPrevious={previousPage}
                  onNext={nextPage}
                  disableNext={!canNextPage}
                  disablePrevious={!canPreviousPage}
                  disabled={!!pagination?.disabled}
                  pageIndex={state.pageIndex}
                  pageCount={pageCount || state.pageCount}
                  gotoPage={gotoPage}
                  showSizeChanger
                  pageSizeOptions={pagination?.options}
                  defaultPageSize={state.pageSize}
                  onSizeChange={(size) => setPageSize(size)}
                />
              ) : (
                <Pagination
                  size={size}
                  onPrevious={previousPage}
                  onNext={nextPage}
                  disableNext={!canNextPage}
                  disablePrevious={!canPreviousPage}
                  pageIndex={state.pageIndex}
                  pageCount={pageCount || state.pageCount}
                  gotoPage={gotoPage}
                  showSizeChanger
                  pageSizeOptions={pagination?.options}
                  defaultPageSize={state.pageSize}
                  onSizeChange={(size) => setPageSize(size)}
                />
              ))}
          </div>
        )}
      </Root>
    );
  }
) as ICompoundedInputComponent;

//@ts-ignore
Table.Virtual = VirtualTable;

export { Table };
