import { usePopper } from 'react-popper'
import React from 'react';
import ReactDOM from 'react-dom';
import { Paper, Typography } from 'app/components/generics';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import {useClickAway} from 'react-use';
import { Transition, Popover as HeadlessPopover } from '@headlessui/react';
import { THEME_DEFAULT_BORDER } from 'constants/theme.contants';

interface IPopover {
    element?: string | HTMLElement | null;
    open?: boolean;
    disabled?: boolean;
    placement?: Placement;
    children?: any;
    overlay?: any;
    title?: any;
    className?: string;
    width?: number;
    onClose?: Function;
}

export const Popover = (props: IPopover) => {

    let {
        element,
        placement = 'top',
        title,
        children,
        overlay,
        onClose,
        className,
        width,
        disabled,
    } = props;
    
    if (children?.length > 1) {
        throw new Error('Popover only accepts one child');
    }

    
    const offsetModifier = React.useMemo(
        () => ({
            name: 'offset',
            options: {
                offset: [0, 5],
            },
        }),
        []
    );
    
    const outsideClickRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);    
    const [referenceElement, setReferenceElement] = React.useState();
    const [popperElement, setPopperElement] = React.useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            //@ts-ignore
            offsetModifier,
        ]
    });

    React.useEffect(() => {
        if (!open) {
            onClose && onClose();
        }
    }, [open])

    //@ts-ignore
    useClickAway(outsideClickRef, () => setOpen(false), ['mouseup']);

    if (typeof element == 'string') {
        element = document.getElementById(element);
    }

    const child = React.cloneElement(children, {
        onClick: (e) => {
            e.stopPropagation();
            children.props.onClick && children.props.onClick();
            if (!disabled) {
                setOpen(!open);
            }
        }
    });
    
    return (
        <>
            {/* @ts-ignore */}
            <div ref={setReferenceElement}>
                {child}
            </div>
            
            {open && ReactDOM.createPortal(
                    // <Transition
                    //     show={open}
                    //     as={React.Fragment}
                    //     enter='transform ease-out duration-300 transition'
                    //     enterFrom='opacity-0'
                    //     enterTo='opacity-100'
                    //     leave='transition ease-in duration-100'
                    //     leaveFrom='opacity-100'
                    //     leaveTo='opacity-0'
                    //     // enter='transform ease-out duration-300 transition'
                    //     // enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:-translate-x-2'
                    //     // enterTo='translate-y-0 opacity-100 sm:-translate-x-full'
                    //     // leave='transition ease-in duration-100'
                    //     // leaveFrom='opacity-100'
                    //     // leaveTo='opacity-0'
                    // >
                    <div
                        onClick={e => e.stopPropagation()}
                        //@ts-ignore
                        ref={ref => {
                            //@ts-ignore
                            setPopperElement(ref);
                            //@ts-ignore
                            outsideClickRef.current = ref;
                        }}
                        style={styles.popper}
                        {...attributes.popper}
                        className={`${className} z-30`}
                    >
                        <Paper style={{ width }} gutters={false}>
                            {title && typeof title != 'string' ? title : typeof title == 'string' && (
                                <div className={classNames([
                                    'p-2 border-b',
                                    THEME_DEFAULT_BORDER
                                ])}>
                                    <Typography bold flat>
                                        {title}
                                    </Typography>
                                </div>                                
                            )}
                            <div className='p-2'>
                                { overlay }
                            </div>
                        </Paper>
                    </div>,
                // </Transition>,
                //@ts-ignore
                element ? document.querySelector(element) : document.body
            )}
        </>
    )
}