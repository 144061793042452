import { Typography } from 'app/components/generics';
import classNames from 'classnames';
import { THEME_DEFAULT_ERROR_BACKGROUND, THEME_DEFAULT_PRIMARY_BACKGROUND, THEME_DEFAULT_SECONDARY_BACKGROUND, THEME_DEFAULT_SUCCESS_BACKGROUND, THEME_DEFAULT_WARNING_BACKGROUND } from 'constants/theme.contants';
import React from 'react';
import { isColorBright } from 'utils/functions.utils';
type Types = 'secondary' | 'primary' | 'success' | 'warning' | 'error' | 'none' | undefined;
type Sizes = 'xs' | 'sm' | 'md' | 'lg';

interface IBadge {
    size?: Sizes;
    type?: Types;
    children?: string|number;
    className?: string;
    shape?: 'circle' | 'square';
    style?: any;
    pulse?: boolean;
}

const types = {
    secondary: THEME_DEFAULT_SECONDARY_BACKGROUND,
    primary: THEME_DEFAULT_PRIMARY_BACKGROUND,
    success: THEME_DEFAULT_SUCCESS_BACKGROUND,
    warning: THEME_DEFAULT_WARNING_BACKGROUND,
    error: THEME_DEFAULT_ERROR_BACKGROUND,
    none: false,
}

const sizes = {
    xs: 'h-1 w-1',
    sm: 'h-2 w-2',
    md: 'h-3 w-3',
    lg: 'h-4 w-4',
}
// text-gray-700 dark:text-gray-200
export const Badge = (props: IBadge) => {
    const { size, style = {}, type, children, className, shape, pulse } = props;
    let _type = type != 'none' ? types.primary : undefined;
    let _size = children == null && sizes.md;

    if (type != 'none' && types[type!]) {
        _type = types[type!];
    }

    if (sizes[size!] && children == null) {
        _size = sizes[size!];
    }

    return (
        <div
            className={classNames([
                _type,
                _size,
                'flex items-center justify-center',
                children != null ? 'rounded-sm px-2 py-0.5' : 'rounded-full',
                className,
                pulse ? 'animate-ping' : '',
            ])}
            style={style}
        >
            {children !== undefined && (
                <Typography flat className='text-xs font-bold' darkText={isColorBright(style?.backgroundColor)}>
                {/* <Typography size={size} className='text-gray-200 text-xs'> */}
                    {children}
                </Typography>
            )}
        </div>
    )
}