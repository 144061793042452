export const THEME_PREFIX = 'optica';

export const THEME_PRIMARY = 'blue';
export const THEME_SECONDARY = 'gray';
export const THEME_SUCCESS = 'green';
export const THEME_WARNING = 'amber';
export const THEME_ERROR = 'red';
export const THEME_INFO = 'cyan';

export const THEME_DEFAULT_TEXT_COLOR = 'text-gray-700 dark:text-gray-200';
export const THEME_DEFAULT_TEXT_COLOR_INVERTED = 'text-gray-200 dark:text-gray-700';
export const THEME_DEFAULT_LIGHT_TEXT_COLOR = 'text-gray-200 dark:text-gray-200';
export const THEME_DEFAULT_DARK_TEXT_COLOR = 'text-gray-700 dark:text-gray-700';
export const THEME_DEFAULT_TEXT_HOVER_COLOR = 'hover:text-gray-700 dark:hover:text-gray-200';
export const THEME_DEFAULT_TEXT_COLOR_WEIGHT = 700;
export const THEME_DEFAULT_DARK_TEXT_COLOR_WEIGHT = 200;

export const THEME_DEFAULT_BORDER = 'border-gray-300 dark:border-gray-700';
export const THEME_DEFAULT_BORDER_ALT = 'border-gray-400 dark:border-gray-800';
export const THEME_BORDER_TRANSPARENT = 'border-transparent dark:border-transparent';
export const THEME_BORDER_TRANSPARENT_HOVER = 'hover:border-transparent dark:hover:border-transparent';
export const THEME_DEFAULT_BORDER_HOVER = 'hover:border-gray-400/70 dark:hover:border-gray-700/70';
export const THEME_PRIMARY_BORDER = `border-${THEME_PRIMARY}-400 dark:border-${THEME_PRIMARY}-400`;
export const THEME_SECONDARY_BORDER = `border-${THEME_SECONDARY}-400 dark:border-${THEME_SECONDARY}-400`;
export const THEME_SUCCESS_BORDER = `border-${THEME_SUCCESS}-400 dark:border-${THEME_SUCCESS}-400`;
export const THEME_WARNING_BORDER = `border-${THEME_WARNING}-400 dark:border-${THEME_WARNING}-400`;
export const THEME_ERROR_BORDER = `border-${THEME_ERROR}-400 dark:border-${THEME_ERROR}-400`;
export const THEME_INFO_BORDER = `border-${THEME_INFO}-400 dark:border-${THEME_INFO}-400`;
export const THEME_PRIMARY_BORDER_HOVER = `hover:border-${THEME_PRIMARY}-500 dark:hover:border-${THEME_PRIMARY}-500`;
export const THEME_SECONDARY_BORDER_HOVER = `hover:border-${THEME_SECONDARY}-500 dark:hover:border-${THEME_SECONDARY}-500`;
export const THEME_SUCCESS_BORDER_HOVER = `hover:border-${THEME_SUCCESS}-500 dark:hover:border-${THEME_SUCCESS}-500`;
export const THEME_WARNING_BORDER_HOVER = `hover:border-${THEME_WARNING}-500 dark:hover:border-${THEME_WARNING}-500`;
export const THEME_ERROR_BORDER_HOVER = `hover:border-${THEME_ERROR}-500 dark:hover:border-${THEME_ERROR}-500`;
export const THEME_INFO_BORDER_HOVER = `hover:border-${THEME_INFO}-500 dark:hover:border-${THEME_INFO}-500`;


// BACKGROUND
export const THEME_DARK_BACKGROUND_BODY = 'dark:bg-gray-900';
export const THEME_DARK_BACKGROUND_0 = 'dark:bg-gray-800';
export const THEME_DARK_BACKGROUND_1 = 'dark:bg-gray-700';
export const THEME_DARK_BACKGROUND_2 = 'dark:bg-gray-600';

export const THEME_STRIPED_ROWS = 'odd:bg-gray-50 even:bg-gray-200 bg-opacity-50 dark:odd:bg-gray-800 dark:even:bg-gray-700 dark:bg-opacity-50';

export const THEME_BACKGROUND_BODY = 'bg-gray-200/60';
export const THEME_BACKGROUND_WHITE = 'bg-white';
export const THEME_BACKGROUND_0 = 'bg-gray-50';
export const THEME_BACKGROUND_1 = 'bg-gray-100';
export const THEME_BACKGROUND_2 = 'bg-gray-200';

export const THEME_DEFAULT_SECONDARY_BACKGROUND = 'bg-gray-500 dark:bg-gray-500';
export const THEME_DEFAULT_PRIMARY_BACKGROUND = 'bg-blue-500 dark:bg-blue-500';
export const THEME_DEFAULT_SUCCESS_BACKGROUND = 'bg-green-500 dark:bg-green-500';
export const THEME_DEFAULT_WARNING_BACKGROUND = 'bg-yellow-500 dark:bg-yellow-500';
export const THEME_DEFAULT_ERROR_BACKGROUND = 'bg-red-500 dark:bg-red-500';
export const THEME_DEFAULT_INFO_BACKGROUND = 'bg-sky-500 dark:bg-sky-500';

export const THEME_DEFAULT_SECONDARY_BACKGROUND_HOVER = 'hover:bg-gray-600 dark:hover:bg-gray-600 hover:bg-opacity-30 dark:hover:bg-opacity-30';
export const THEME_DEFAULT_PRIMARY_BACKGROUND_HOVER = 'hover:bg-blue-600 dark:hover:bg-blue-600 hover:bg-opacity-30 dark:hover:bg-opacity-30';
export const THEME_DEFAULT_SUCCESS_BACKGROUND_HOVER = 'hover:bg-green-600 dark:hover:bg-green-600 hover:bg-opacity-30 dark:hover:bg-opacity-30';
export const THEME_DEFAULT_WARNING_BACKGROUND_HOVER = 'hover:bg-yellow-600 dark:hover:bg-yellow-600 hover:bg-opacity-30 dark:hover:bg-opacity-30';
export const THEME_DEFAULT_ERROR_BACKGROUND_HOVER = 'hover:bg-red-600 dark:hover:bg-red-600 hover:bg-opacity-30 dark:hover:bg-opacity-30';
export const THEME_DEFAULT_INFO_BACKGROUND_HOVER = 'hover:bg-blue-600 dark:hover:bg-blue-600 hover:bg-opacity-30 dark:hover:bg-opacity-30';

export const THEME_DEFAULT_SECONDARY_BACKGROUND_ACTIVE = 'bg-gray-500 dark:bg-gray-500 bg-opacity-30 dark:bg-opacity-30';
export const THEME_DEFAULT_PRIMARY_BACKGROUND_ACTIVE = 'bg-blue-500 dark:bg-blue-500 bg-opacity-30 dark:bg-opacity-30';
export const THEME_DEFAULT_SUCCESS_BACKGROUND_ACTIVE = 'bg-green-500 dark:bg-green-500 bg-opacity-30 dark:bg-opacity-30';
export const THEME_DEFAULT_WARNING_BACKGROUND_ACTIVE = 'bg-yellow-500 dark:bg-yellow-500 bg-opacity-30 dark:bg-opacity-30';
export const THEME_DEFAULT_ERROR_BACKGROUND_ACTIVE = 'bg-red-500 dark:bg-red-500 bg-opacity-30 dark:bg-opacity-30';
export const THEME_DEFAULT_INFO_BACKGROUND_ACTIVE = 'bg-blue-500 dark:bg-blue-500 bg-opacity-30 dark:bg-opacity-30';

// export const THEME_DEFAULT_BACKGORUND = 'bg-white dark:bg-transparent';
export const THEME_DEFAULT_BACKGROUND = `bg-white ${THEME_DARK_BACKGROUND_0}`;

export const THEME_DEFAULT_ACTIVE = 'bg-blue-500 bg-opacity-40 dark:bg-blue-500 dark:bg-opacity-40';
// export const THEME_DEFAULT_ACTIVE = 'bg-gray-100 bg-opacity-80 dark:bg-gray-700 dark:bg-opacity-30';
export const THEME_DEFAULT_HOVER = 'hover:bg-gray-100 hover:bg-opacity-80 dark:hover:bg-gray-700 dark:hover:bg-opacity-30';
export const THEME_DARK_HOVER = 'hover:bg-gray-200 hover:bg-opacity-80 dark:hover:bg-gray-900 dark:hover:bg-opacity-30';
export const THEME_DEFAULT_FOCUS = 'focus:bg-gray-100 focus:bg-opacity-80 dark:focus:bg-gray-700 dark:focus:bg-opacity-30';
export const THEME_DEFAULT_DISABLED = 'bg-gray-200/60 dark:bg-gray-700/60';
export const THEME_DEFAULT_FOCUS_DISABLED = 'focus:bg-gray-100 focus:bg-opacity-60 dark:focus:bg-gray-800 dark:focus:bg-opacity-15';

export const THEME_DEFAULT_DIVIDE_VERTICAL = 'divide-y divide-gray-300 dark:divide-gray-700';
export const THEME_DEFAULT_DIVIDE_HORIZONTAL = 'divide-x divide-gray-300 dark:divide-gray-700';

export const THEME_DEFAULT_WEIGHT = 400;
export const THEME_DEFAULT_HOVER_WEIGHT = 500;

// TEXT
export const THEME_DEFAULT_DISABLED_TEXT =      `text-gray-500 text-opacity-70 dark:text-opacity-70 dark:text-gray-400`;
export const THEME_DEFAULT_SECONDARY_TEXT =     `text-gray-500 dark:text-gray-400`;
export const THEME_DEFAULT_PRIMARY_TEXT =       `text-blue-500 dark:text-blue-400`;
export const THEME_DEFAULT_SUCCESS_TEXT =       `text-${THEME_SUCCESS}-500 dark:text-${THEME_SUCCESS}-400`;
export const THEME_DEFAULT_WARNING_TEXT =       `text-yellow-500 dark:text-yellow-400`;
export const THEME_DEFAULT_ERROR_TEXT =         `text-red-500 dark:text-red-400`;
export const THEME_DEFAULT_INFO_TEXT =          `text-cyan-500 dark:text-cyan-400`;
export const THEME_DEFAULT_SECONDARY_TEXT_HOVER = `hover:text-gray-400 dark:hover:text-gray-400`;
export const THEME_DEFAULT_PRIMARY_TEXT_HOVER = `hover:text-blue-500 dark:hover:text-blue-500`;
export const THEME_DEFAULT_SUCCESS_TEXT_HOVER = `hover:text-green-500 dark:hover:text-green-500`;
export const THEME_DEFAULT_WARNING_TEXT_HOVER = `hover:text-yellow-500 dark:hover:text-yellow-500`;
export const THEME_DEFAULT_ERROR_TEXT_HOVER = `hover:text-red-500 dark:hover:text-red-500`;
export const THEME_DEFAULT_INFO_TEXT_HOVER = `hover:text-blue-500 dark:hover:text-blue-500`;
export const THEME_PURPLE_TEXT =                `text-purple-500 dark:text-pruple-400`;
export const THEME_AMBER_TEXT =                `text-amber-500 dark:text-amber-400`;

export const THEME_DEFAULT_PRIMARY_TEXT_WITH_HOVER =       `text-blue-500 dark:text-blue-400 hover:text-blue-500/70 hover:dark:text-blue-400/70`;
export const THEME_DEFAULT_SUCCESS_TEXT_WITH_HOVER =       `text-green-500 dark:text-green-400 hover:text-green-500/70 hover:dark:text-green-400/70`;
export const THEME_DEFAULT_WARNING_TEXT_WITH_HOVER =       `text-yellow-500 dark:text-yellow-400 hover:text-yellow-500/70 hover:dark:text-yellow-400/70`;
export const THEME_DEFAULT_ERROR_TEXT_WITH_HOVER =         `text-red-500 dark:text-red-400 hover:text-red-500/70 hover:dark:text-red-400/70`;
export const THEME_DEFAULT_INFO_TEXT_WITH_HOVER =          `text-cyan-500 dark:text-cyan-400 hover:text-cyan-500/70 hover:dark:text-cyan-400/70`;

// COLORS BY HEX
export const THEME_COLORS = {
    black: '#000',
    white: '#fff',

    gray: {
        50: '#f8fafc',
        100: '#f1f5f9',
        200: '#e2e8f0',
        300: '#cbd5e1',
        400: '#94a3b8',
        500: '#64748b',
        600: '#475569',
        700: '#334155',
        800: '#1e293b',
        900: '#0f172a',
    },

    red: {
        100: '#fff5f5',
        200: '#fed7d7',
        300: '#feb2b2',
        400: '#fc8181',
        500: '#f56565',
        600: '#e53e3e',
        700: '#c53030',
        800: '#9b2c2c',
        900: '#742a2a',
    },
    
    orange: {
        100: '#fffaf0',
        200: '#feebc8',
        300: '#fbd38d',
        400: '#f6ad55',
        500: '#ed8936',
        600: '#dd6b20',
        700: '#c05621',
        800: '#9c4221',
        900: '#7b341e',
    },

    amber: {
        100: '#ffecb3',
        200: '#ffe082',
        300: '#ffd54f',
        400: '#ffca28',
        500: '#ffc107',
        600: '#ffb300',
        700: '#ffa000',
        800: '#ff8f00',
        900: '#ff6f00',
    },

    yellow: {
        100: '#fffff0',
        200: '#fefcbf',
        300: '#faf089',
        400: '#f6e05e',
        500: '#ecc94b',
        600: '#d69e2e',
        700: '#b7791f',
        800: '#975a16',
        900: '#744210',
    },

    green: {
        100: '#f0fff4',
        200: '#c6f6d5',
        300: '#9ae6b4',
        400: '#68d391',
        500: '#48bb78',
        600: '#38a169',
        700: '#2f855a',
        800: '#276749',
        900: '#22543d',
    },

    teal: {
        100: '#e6fffa',
        200: '#b2f5ea',
        300: '#81e6d9',
        400: '#4fd1c5',
        500: '#38b2ac',
        600: '#319795',
        700: '#2c7a7b',
        800: '#285e61',
        900: '#234e52',
    },
    
    cyan: {
        100: '#b2ebf2',
        200: '#80deea',
        300: '#4dd0e1',
        400: '#26c6da',
        500: '#00bcd4',
        600: '#00acc1',
        700: '#0097a7',
        800: '#00838f',
        900: '#006064',
    },

    blue: {
        100: '#ebf8ff',
        200: '#bee3f8',
        300: '#90cdf4',
        400: '#63b3ed',
        500: '#4299e1',
        600: '#3182ce',
        700: '#2b6cb0',
        800: '#2c5282',
        900: '#2a4365',
    },

    lightBlue: {
        100: '#b3e5fc',
        200: '#81d4fa',
        300: '#4fc3f7',
        400: '#29b6f6',
        500: '#03a9f4',
        600: '#039be5',
        700: '#0288d1',
        800: '#0277bd',
        900: '#01579b',
    },

    indigo: {
        100: '#ebf4ff',
        200: '#c3dafe',
        300: '#a3bffa',
        400: '#7f9cf5',
        500: '#667eea',
        600: '#5a67d8',
        700: '#4c51bf',
        800: '#434190',
        900: '#3c366b',
    },

    purple: {
        100: '#faf5ff',
        200: '#e9d8fd',
        300: '#d6bcfa',
        400: '#b794f4',
        500: '#9f7aea',
        600: '#805ad5',
        700: '#6b46c1',
        800: '#553c9a',
        900: '#44337a',
    },

    pink: {
        100: '#fff5f7',
        200: '#fed7e2',
        300: '#fbb6ce',
        400: '#f687b3',
        500: '#ed64a6',
        600: '#d53f8c',
        700: '#b83280',
        800: '#97266d',
        900: '#70245',
    }
}