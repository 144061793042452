import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useAuth } from "services/auth";
import { Avatar, Divider, Dropdown, Typography } from "app/components/generics";
import { ClientRoutes } from "shared/routes/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EUserRole } from "shared/enums/user-role.enum";
import {
  faAddressCard,
  faBallPile,
  faShield,
  faSignOut,
} from "@fortawesome/pro-duotone-svg-icons";

const clientRoutes = new ClientRoutes();

export const UserMenu = () => {
  const { me } = useAuth();

  const overlay = (
    <Dropdown.Menu>
      <Dropdown.Item
        as={Link}
        to={clientRoutes.profile.toURL}
        icon={<FontAwesomeIcon icon={faAddressCard} />}
      >
        Profile
      </Dropdown.Item>
      {me.roleId >= EUserRole.USER && (
        <Dropdown.Item
          as={Link}
          to={clientRoutes.organization.toURL}
          icon={<FontAwesomeIcon icon={faBallPile} />}
        >
          Organization
        </Dropdown.Item>
      )}
      {me.$isAdmin && (
        <Dropdown.Item
          as={Link}
          type="error"
          to={clientRoutes.admin.toURL}
          icon={<FontAwesomeIcon icon={faShield} />}
        >
          Admin
        </Dropdown.Item>
      )}
      <Divider size="sm" />
      <Dropdown.Item
        as={Link}
        to={clientRoutes.logout.toURL}
        icon={<FontAwesomeIcon icon={faSignOut} />}
      >
        Logout
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  return (
    <Dropdown overlay={overlay} ghost>
      <Avatar src={me?.$avatarUrl} text={me?.$initials} size="sm" />
      <span className="hidden ml-3 text-sm font-medium text-gray-700 lg:block">
        <span className="sr-only">Open me menu for </span>
        <Typography size="xs">{me?.$fullName}</Typography>
      </span>
      <ChevronDownIcon
        className="flex-shrink-0 hidden w-5 h-5 ml-1 text-gray-400 lg:block"
        aria-hidden="true"
      />
    </Dropdown>
  );
};
