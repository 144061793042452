import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  List,
  Popover,
} from "app/components/generics";
import React from "react";
import { useDebounce, usePrevious } from "react-use";
import { json } from "utils/functions.utils";

export const MultiSelectColumnFilter = (data) => {
  const {
    // @ts-ignore
    column: {
      filterValue = [],
      setFilter,
      preFilteredRows: colPreFilteredRows,
      filterOptions,
      accessor,
      id,
      ...rest
    },
    preFilteredRows: instancePreFilteredRows,
    globalFilteredRows,
    initialState: { filters },
    manualFilters,
  } = data;

  const preFilteredRows = colPreFilteredRows || instancePreFilteredRows;
  const [values, setValues] = React.useState(filterValue);
  const [search, setSearch] = React.useState();
  const options = React.useMemo(() => {
    if (manualFilters) {
      return filterOptions || [];
    } else {
      const options = new Set();
      preFilteredRows?.forEach((row) => {
        options.add(row.values[id]);
      });
      //@ts-ignore
      return [...options.values()];
    }
  }, [id, preFilteredRows]);
  const prevFilterValue = usePrevious(filterValue);
  const [searchedOptions, setSearchedOptions] = React.useState(options);

  // Update filters based on external change from table instance
  React.useEffect(() => {
    if (
      prevFilterValue &&
      json.stringify(filterValue) != json.stringify(prevFilterValue)
    ) {
      externalUpdateValues(filterValue);
    }
  }, [filterValue]);

  const handleOnApply = (e) => {
    setFilter(values);
  };

  const handleOnReset = (e) => {
    setValues([]);
    setFilter([]);
  };

  const externalUpdateValues = (values) => {
    setValues(values);
  };

  const [, cancel] = useDebounce(
    () => {
      let searchedOptions = [...options];
      if (search) {
        searchedOptions = searchedOptions?.filter((v) =>
          //@ts-ignore
          v?.toLowerCase()?.includes(search?.toLowerCase())
        );
      }
      //@ts-ignore
      setSearchedOptions(searchedOptions);
    },
    150,
    [search, options]
  );

  const handleOnPopoverClose = () => {
    //@ts-ignore
    setSearch();
    setValues(filterValue);
  };

  const columnId = typeof accessor == "string" ? accessor : id;

  const ListRow = (props) => {
    const { index, style } = props;
    const value = searchedOptions[index];
    return (
      <div style={style}>
        <Checkbox
          key={value + index}
          id={`${value}-${index}`}
          orientation="horizontal"
          name={id}
          label={value}
          value={value}
          //@ts-ignore
          checked={values?.includes(value) || false}
          onChange={(e) => {
            let _values = [...values];
            //@ts-ignore
            if (_values.includes(value)) {
              _values = _values.filter((j) => j != value);
            } else {
              //@ts-ignore
              _values.push(value);
            }
            setValues(_values);
          }}
        />
      </div>
    );
  };

  const filter = (
    <div className="w-64 p-2 truncate" onClick={(e) => e.stopPropagation()}>
      <div>
        <Input
          placeholder="Search filters"
          size="xs"
          block
          type="search"
          autoFocus
          onChange={({ currentTarget }) => {
            //@ts-ignore
            setSearch(currentTarget?.value);
          }}
          footer={null}
        />
      </div>
      <Divider size="sm" />
      <List height={192} data={options}>
        {ListRow}
      </List>
      <Divider size="sm" />
      <div className="flex justify-between">
        <Button
          onClick={handleOnReset}
          elevation="none"
          disabled={!filterValue?.length}
          size="sm"
        >
          Reset
        </Button>
        <Button
          onClick={handleOnApply}
          elevation="none"
          disabled={!values?.length && !filterValue?.length}
          type="primary"
          size="sm"
        >
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      overlay={filter}
      placement="bottom"
      onClose={handleOnPopoverClose}
      className="z-10"
    >
      <Button
        elevation="none"
        ghost
        className={`pl-1 pr-1 pt-1 pb-1 ${
          !!filterValue?.length
            ? "bg-blue-500 dark:bg-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
            : ""
        }`}
      >
        <FontAwesomeIcon icon={faFilter} size="sm" />
      </Button>
    </Popover>
  );
};
