import React, { useState } from "react";
import styled from "styled-components";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { useTable, useBlockLayout } from "react-table";
import { TableColumn } from "shared/types/table-column.type";
import { useSticky } from 'react-table-sticky';
import classNames from "classnames";
import { Typography } from "app/components/generics/Typography";

const Root = styled.div`
  /* width: fit-content; */
  display: inline-block;
  max-width: 100%;
  position: relative;
  .table {
    /* border: 1px solid #ddd; */
    /* width: fit-content; */
    width: 100%;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      /* border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff; */
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        /* width: fit-content; */
      }

      .header {
        z-index: 4;
        top: 0;
        /* box-shadow: 0px 3px 3px #ccc; */
      }

      .footer {
        z-index: 4;
        bottom: 0;
        /* box-shadow: 0px -3px 3px #ccc; */
      }

      .body {
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        /* box-shadow: 2px 0px 3px #ccc; */
      }

      [data-sticky-first-right-td] {
        /* box-shadow: -2px 0px 3px #ccc; */
      }
    }
  }
`;

interface IOnRow {
    onClick?: (id?: any, row?: any) => any;
    onDoubleClick?: (id?: any, row?: any) => any;
    onContextMenu?: (id?: any, row?: any) => any;
    onMouseEnter?: (id?: any, row?: any) => any;
    onMouseLeave?: (id?: any, row?: any) => any;
}

interface IRowSelection {
    onChange: Function;
    selectAll: boolean;
    onSelect: Function;
    onSelectAll: Function;
    onSelectPageAll: Function;
    hideSelectAll: boolean;
    preserveSelectedKeys: boolean;
    selectedFlatRows: any[];
    selectedRowIds: string[] | number[];
}

export interface IVirtualTable {
    height: number;
    width?: number;
    rowHeight?: number;
    loading?: boolean;
    border?: boolean;
    onLoadMore?(): Promise<any[]>;
    onRow?(index?: any, row?: any): IOnRow;
    data: any;
    columns: TableColumn[];
    size?: 'sm' | 'md' | 'lg';
}

interface ITableOptions {
    getRowId?: Function;
    pageCount?: number;
    manualPagination?: boolean;
    manualFilters?: boolean;
    manualSortBy?: boolean;    
    autoResetSelectedRows?: boolean;    
}

const textAlign = {
    left: 'text-left justify-start',
    center: 'text-center justify-center',
    right: 'text-right justify-end',
}

const bodySizes = {
    sm: 'px-1.5 py-1 text-xs font-normal',
    md: 'px-2.5 py-2 text-sm font-normal',
    lg: 'p-3 text-sm font-normal',
}
const footerSizes = {
    sm: 'p-1.5',
    md: 'p-2',
    lg: 'p-3',
}
const paginationSizes = {
    sm: 'px-2 py-1',
    md: 'px-3 py-1.5',
    lg: 'px-3.5 py-2',
}
const headerSizes = {
    sm: 'px-1.5 py-1 text-sm font-medium',
    md: 'px-2.5 py-2 text-sm font-medium',
    lg: 'p-3 text-md font-medium',
}
const headerSizesBorderless = {
    sm: 'px-1.5 py-0.5 my-0.5',
    md: 'px-2.5 py-1 my-1',
    lg: 'px-3 py-1.5 my-1.5',
}

const DEFAULT_ROW_HEIGHT = 32;

const VirtualTable = (props: IVirtualTable) => {
    const { 
        columns,
        data: initialData,
        onLoadMore,
        rowHeight = DEFAULT_ROW_HEIGHT,
        height,
        width,
        border,
    } = props;
    const defaultColumn = React.useMemo(() => ({
        // minWidth: 150,
        width: undefined,
        // maxWidth: 400
    }), []);

    const [data, setData] = useState(initialData || []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        totalColumnsWidth
    } = useTable(
        {
            columns,
            data,
            defaultColumn
        },
        useBlockLayout,
        useSticky,
    );
    
    React.useEffect(() => {
        if (!onLoadMore && Array.isArray(initialData) && initialData?.length) {
            setData(initialData);
        }
    }, [initialData])

    // Workaround as react-table footerGroups doesn't provide the same internal data than headerGroups
    // const footerGroups = headerGroups.slice().reverse();

    function renderRow({ index, style, isScrolling }: any) {
        const row = rows?.[index];
        if (!row) return <div>Loading...</div>;
        if (isScrolling) return <div>Loading...</div>
        prepareRow(row);
        const { style: rowStyle, ...restRow } = row.getRowProps({ style });
        return (
            <div
                {...restRow}
                style={{ ...rowStyle, width: totalColumnsWidth }}
                className="tr"
            >
                {row.cells.map((cell) => {
                    return (
                        <div {...cell.getCellProps()} className="td">
                            {cell.render("Cell")}
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <Root
            className={classNames([
                // 'overflow-hidden rounded bg-transparent',
                'rounded bg-transparent',
                border ? 'border border-gray-200 dark:border-gray-700' : '',
            ])}
        >
            <div {...getTableProps({ style: { width: width! - 10 }})} className={`table sticky ${border ? 'table-bordered' : ''}`}>
                <div style={{ position: "relative", flex: 1, zIndex: 0 }}>
                    <InfiniteLoader
                        isItemLoaded={(index) => data?.[index] !== undefined}
                        loadMoreItems={async (a) => {
                        }}
                        itemCount={data.length + 1}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <FixedSizeList
                                height={height!}
                                itemCount={data.length + 1}
                                itemSize={rowHeight}
                                width="100%"
                                onItemsRendered={onItemsRendered}
                                ref={ref}
                                innerElementType={({ children, style, ...rest }: any) => (
                                        <>
                                            <div className="header flex">
                                                <div style={{ width: isNaN(totalColumnsWidth) ? '100%' : totalColumnsWidth }}>
                                                    {headerGroups.map((headerGroup) => (
                                                        <div
                                                            {...headerGroup.getHeaderGroupProps()}
                                                            className={classNames([
                                                                'tr dark:bg-gray-700 bg-gray-50',
                                                            ])}
                                                        >
                                                            {headerGroup.headers.map((column) => (
                                                                <div
                                                                    {...column.getHeaderProps()}
                                                                    className={classNames([
                                                                        'th bg-transparent border-b border-gray-300 dark:border-gray-700 relative group',
                                                                        column?.width === undefined ? 'flex-1' : '',
                                                                    ])}
                                                                >
                                                                    <div 
                                                                        className={classNames([
                                                                            'flex flex-auto items-center h-full',
                                                                            //@ts-ignore
                                                                            'text-gray-800',
                                                                            'dark:text-gray-200',
                                                                            'uppercase',
                                                                            // 'tracking-wider',
                                                                        ])}
                                                                    >
                                                                        <div className='inline-block flex-auto'>
                                                                            <Typography className='font-medium' bold size='xs'>
                                                                                {column.render('Header')}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="body">
                                            {/* <div style={{ height: 500 - 58 - 57 }} className="body"> */}
                                                <div {...getTableBodyProps()} {...rest} style={style}>
                                                    {children}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                >
                                    {renderRow}
                                </FixedSizeList>
                            );
                        }}
                    </InfiniteLoader>
                </div>
            </div>
        </Root>
    );
}

export default VirtualTable;