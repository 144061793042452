import React from "react";

import { Navigation } from "./components/Navigation";
import { Search } from "./components/Search";
import { UserMenu } from "./components/UserMenu";
import { Notifications } from "./components/Notifications";
import { ThemeSwitch } from "./components/ThemeSwitch";
import { Banner } from "app/components/Banner";
import { CreateAction } from "./components/CreateAction";

export const TopBar = () => {
  return (
    <div className="sticky top-0 w-full flex flex-col bg-white dark:bg-gray-800 dark:border-gray-900 border-b dark:border-opacity-40 shadow-sm z-30">
      <Banner />
      <div className="flex pr-2 h-11">
        <Navigation />
        {/* <div className="flex items-center">
          <CreateAction />
        </div> */}
        <div className="flex-1" />
        {/* <div className='flex items-center'>
                    <ThemeSwitch/>
                </div> */}
        <div className="flex items-center space-x-2">
          {/* <Search/> */}
          <Notifications />
          <UserMenu />
        </div>
      </div>
      <div id="top-sub-nav" />
    </div>
  );
};
