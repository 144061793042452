import React from "react";
import { TopBar } from "./components/TopBar";
import { ToastContainer } from "react-toastify";
import { Sidebar } from "./components/Sidebar";
import { PRIVATE_LAYOUT_CONTAINER } from "constants/known-element-ids.contants";
import {
  THEME_BACKGROUND_0,
  THEME_DARK_BACKGROUND_1,
  THEME_DEFAULT_BORDER,
  THEME_DEFAULT_TEXT_COLOR,
} from "constants/theme.contants";
import classNames from "classnames";

const contextClass = {
  dark: THEME_DARK_BACKGROUND_1,
  // success: THEME_DEFAULT_SUCCESS_BACKGROUND,
  // // error: `${THEME_DARK_BACKGROUND_1} ${THEME_BACKGROUND_0}`,
  // error: THEME_DEFAULT_ERROR_BACKGROUND,
  // info: THEME_DEFAULT_INFO_BACKGROUND,
  // warning: THEME_DEFAULT_WARNING_BACKGROUND,
  default: classNames([
    THEME_BACKGROUND_0,
    THEME_DARK_BACKGROUND_1,
    THEME_DEFAULT_BORDER,
    THEME_DEFAULT_TEXT_COLOR,
    "border",
    // 'border border-gray-200 dark:border-gray-700 text-gray-800 dark:bg-gray-800 dark:text-gray-200 shadow-xl',
  ]),
};
// const contextClass = {
//     dark: 'bg-gray-700',
//     success: 'bg-green-500',
//     error: 'bg-red-500',
//     info: 'bg-blue-500',
//     warning: 'bg-yellow-500',
//     default: 'bg-white border border-gray-200 dark:border-gray-700 text-gray-800 dark:bg-gray-800 dark:text-gray-200 shadow-xl',
// };

const PrivateLayout = ({ children }) => {
  return (
    <>
      {/* NOTE: Removed overflow-hidden 4/11/2022, may cause issues */}
      <div className="h-screen">
        {/* <div className='flex flex-col flex-1 overflow-x-hidden overflow-y-auto'> */}
        <div>
          <TopBar />
          <div className="flex flex-1 min-h-[calc(100vh-120px)]">
            <div id="side-sub-nav" className="no-print" />
            {/* NOTE: Overflow auto here helps with nested large overflows. May need to review if layout breaks. */}
            {/* NOTE: 4/11/2022 - Removed  overflow-auto, causing issues with nested sticky (overflow-auto) */}
            <div className="relative flex-1" id={PRIVATE_LAYOUT_CONTAINER}>
              {/* <div className='relative flex-1 p-4'> */}
              {children}
            </div>
          </div>
        </div>
        <ToastContainer
          // toastClassName={({ type }) => contextClass[type || "default"] +
          //   " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
          // }
          limit={5}
          style={{ minWidth: 450 }}
          bodyClassName={() =>
            "w-full text-sm font-white font-med flex items-center whitespace-pre-wrap p-3"
          }
          toastClassName={({ type }: any) =>
            contextClass.default +
            " relative flex p-1 min-h-10 rounded justify-between overflow-hidden cursor-pointer mb-2"
          }
          //     // ' relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
          //     // ' relative flex min-h-20 rounded-md justify-between overflow-hidden cursor-pointer'
          // }
          // bodyClassName={() => 'text-sm font-white font-med block w-full'}
          // bodyClassName={() => 'text-sm font-white font-med block p-3 w-full'}
          hideProgressBar
        />
      </div>
    </>
  );
};

//@ts-ignore
PrivateLayout.Sidebar = Sidebar;

export { PrivateLayout };
