import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
    >svg {
        animation: rotate 2s linear infinite;
        z-index: 2;
        position: absolute;
        /* top: 50%; */
        /* left: 50%; */
        /* margin: -25px 0 0 -25px; */
        width: 80px;
        height: 80px;
        >circle {
            /* stroke: #3baefc94; */
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }
`

export const ComponentLoader = (props) => {
    const { abs } = props;
    return (
        <Root className={`h-full w-full flex items-center justify-center ${abs ? 'absolute top-0 bottom-0 left-0 right-0' : ''}`}>
            <svg viewBox='0 0 50 50' className='text-green-400'>
                <circle cx='25' cy='25' r='20' fill='none' stroke='currentColor' strokeWidth='4'></circle>
            </svg>
        </Root>
    )
}