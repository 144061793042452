import { Typography } from 'app/components/generics';
import classNames from 'classnames';
import { THEME_DEFAULT_SECONDARY_TEXT } from 'constants/theme.contants';
import React from 'react';
import { components } from 'react-select';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as VirtList } from "react-window";

const VIRT_LIST_HEIGHT_MULTIPLIER = 30;
const VIRT_LIST_ITEM_HEIGHT_DEFAULT = 82;
const VIRT_LIST_ITEM_HEIGHT_ADD = 4;
const DEFAULT_MENU_HEIGHT = 150;
const SUPPLIMENTAL_BASE_HEIGHT = 6;

export const VirtMenuList = (props) => {
    const { options, children, optionHeight = VIRT_LIST_ITEM_HEIGHT_DEFAULT, inputValue, getValue, ref, ...rest } = props;
    // References
    const listRef = React.useRef({});
    const rowHeights = React.useRef({});
    const [height, setHeight] = React.useState(DEFAULT_MENU_HEIGHT)

    React.useEffect(() => {
        if (Object.values(rowHeights?.current)?.length) {
            // @ts-ignore
            let height = Object.values(rowHeights?.current).reduce((acc, cur) => acc + cur) as number;

            if (children?.length > 5) {
                height = 150;
            } else {
                height = height + (SUPPLIMENTAL_BASE_HEIGHT * children?.length) || SUPPLIMENTAL_BASE_HEIGHT;
            }

            setHeight(height);
        }
    }, [rowHeights, children]);

    function getRowHeight(index) {
        return rowHeights.current[index] + VIRT_LIST_ITEM_HEIGHT_ADD || VIRT_LIST_ITEM_HEIGHT_DEFAULT;
    }

    React.useEffect(() => {
        if (!!inputValue) {
            // @ts-ignore
            listRef.current.scrollToItem(0)
        }
        // eslint-disable-next-line
    }, [inputValue]);

    let Row = ({ index, style }) => (
        <div className='absolute left-0 top-0 flex w-full h-full items-center justify-center'>
            <Typography type='secondary' className='select-none cursor-default'>
                No Results
            </Typography>
        </div>
    );
    
    if (!!children?.length) {
        Row = ({ index, style }) => {
            const rowRef = React.useRef({});
    
            React.useEffect(() => {
                if (rowRef.current) {
                    // @ts-ignore
                    setRowHeight(index, rowRef.current.clientHeight);
                }
                // eslint-disable-next-line
            }, [rowRef]);
    
            return (
                <div style={style}>
                    {/* @ts-ignore */}
                    <div ref={rowRef}>{children[index]}</div>
                </div>
            );
        }
    }

    function setRowHeight(index, size) {
        //   @ts-ignore
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }
    return (
        <VirtList
            height={height}
            itemCount={children?.length || 1}
            itemSize={getRowHeight}
            // @ts-ignore
            ref={listRef}
        >
            {Row}
        </VirtList>
    );
    // const [value] = getValue();
    // // References
    // const listRef = React.React.useRef({});
    // const rowHeights = React.React.useRef({});

    // const initialOffset = options.indexOf(value) * optionHeight;

    // const childCount = children?.length || 0;
    // const heightBasedOnChildren = childCount * VIRT_LIST_HEIGHT_MULTIPLIER;

    // let height = 300;

    // if (heightBasedOnChildren < height) {
    //     height = heightBasedOnChildren;
    // }

    // function getRowHeight(index) {
    //     return rowHeights.current[index] + VIRT_LIST_ITEM_HEIGHT_ADD || VIRT_LIST_ITEM_HEIGHT_DEFAULT;
    // }

    // const Row = ({ index, style }) => {
    //     const rowRef = React.React.useRef(null);
    
    //     React.React.useEffect(() => {
    //       if (rowRef.current) {
    //         // @ts-ignore
    //         setRowHeight(index, rowRef.current.clientHeight);
    //       }
    //     }, [rowRef]);
    
    //     return (
    //         <div ref={rowRef} style={style}>{children[index]}</div>
    //     );
    // }

    // function setRowHeight(index, size) {
    //     // @ts-ignore
    //     listRef?.current?.resetAfterIndex?.(0);
    //     rowHeights.current = { ...rowHeights.current, [index]: size };
    // }

    // return (
    //     // @ts-ignore
    //     <AutoSizer style={{ height: '100%', width: '100%' }}>
    //         {({height, width}) => (
    //             <VirtList
    //                 height={height}
    //                 itemCount={childCount}
    //                 itemSize={getRowHeight}
    //                 initialScrollOffset={initialOffset}
    //                 // itemData={[]}
    //                 // @ts-ignore
    //                 ref={listRef}
    //                 width={width}
    //             >
    //                 {Row}
    //             </VirtList>
    //         )}
    //     </AutoSizer>        
    // );
}

export const Control = props => (
    <components.Control 
        {...props}
        className={classNames([
            // 'bg-white',
            // 'dark:bg-gray-700',
            'bg-white',
            'dark:bg-transparent',
            'border-gray-300',
            'hover:border-gray-400',
            'dark:border-gray-600',
            'dark:hover:border-gray-700',
            // 'bg-opacity-60',
            // 'dark:bg-opacity-60',
            // 'focus-within:ring-1',
            // 'focus-within:ring-offset-1',
            // 'focus-within:ring-offset-transparent',
            // 'focus-within:ring-gray-300',
            // 'dark:focus-within:ring-gray-700',
            'focus-within:!outline-dashed',
            'focus-within:!outline-1',
            'focus-within:!outline-gray-500',
            '!dark:focus-within:outline-gray-500',
            'focus-within:!outline-offset-1',
            !props.isDisabled ? '' : 'bg-gray-400 dark:bg-gray-800',
        ])} />
);

export const ValueContainer = props => (
    <components.ValueContainer 
        {...props} 
        className={classNames([
            // props.isDisabled ? '' : 'bg-gray-400 dark:bg-gray-800',
            'bg-transparent',
            'max-h-40',
            '!overflow-auto',
        ])}
    />
);

export const Input = (props) => (
    <components.Input {...props} className='bg-transparent dark:bg-transparent border-white dark:border-gray-800 text-gray-800 dark:text-gray-200' />
);

export const Menu = props => (
    <components.Menu {...props} className='bg-white dark:bg-gray-800 border-white dark:border-gray-800 text-gray-800 dark:text-gray-200' />
);

export const MenuList = props => (
    <components.MenuList {...props} className='bg-transparent max-h-96' />
);

export const SingleValue = props => (
    <components.SingleValue {...props} className='bg-transparent text-gray-800 dark:text-gray-200' />
);

export const MultiValue = props => (
    <components.MultiValue {...props} className='bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200' />
);

export const MultiValueLabel = props => (
    <components.MultiValueLabel {...props} className='bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200' />
);

export const MultiValueContainer = props => (
    <components.MultiValueContainer {...props} className='bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200' />
);

export const Option = props => {
    return (
        <components.Option
            {...props} 
            // style={{ ':active': { backgroundColor: 'inherit!important' }}}
            className={classNames(
                'h-full',
                !props.isDisabled 
                    ? 'hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-200 dark:focus:bg-gray-700 active:bg-gray-200 dark:active:bg-gray-700 text-gray-800 dark:text-gray-200' 
                    : 'bg-gray-400 dark:bg-gray-600/50 text-gray-600 dark:text-gray-400 dark:active:bg-gray-600/50 pointer-events-none',
            )}
            // style={{
            //     ...props.style,
            //     flex: 1,
            //     minHeight: 36,
            //     alignItems: 'center',
            //     display: 'flex',
            // }}
        />
    )
}

export const Placeholder = props => (
    <components.Placeholder {...props} className={`${THEME_DEFAULT_SECONDARY_TEXT}`} />
    // <components.Placeholder {...props} className='text-gray-800 dark:text-gray-200 dark:text-opacity-40' />
);

export const ClearIndicator = props => (
    <components.ClearIndicator {...props} className='text-gray-800 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400' />
);

export const IndicatorSeparator = props => (
    <components.IndicatorSeparator {...props} className='bg-gray-400 dark:bg-gray-700' />
);

export const LoadingIndicator = props => (
    <components.LoadingIndicator {...props} className='text-gray-500 dark:text-gray-300' />
);

export const DropdownIndicator = props => (
    <components.DropdownIndicator {...props} className='text-gray-500 dark:text-gray-400 dark:hover:text-gray-400' />
);