import { Typography } from "app/components/generics";
import React from "react";

interface IAvatar {
  src?: string;
  text?: string;
  size?: "sm" | "md" | "lg" | "xl";
}

const sizes = {
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-10 w-10",
  xl: "h-12 w-12",
};

export const Avatar = ({ src, text, size }: IAvatar) => {
  let _size = sizes.md;

  if (size && sizes[size]) {
    _size = sizes[size];
  }

  return (
    <>
      {src ? (
        <img className={`${_size} rounded-full`} src={src} />
      ) : (
        <span
          className={`inline-flex items-center justify-center ${_size} rounded-full bg-gray-300 dark:bg-gray-500`}
        >
          <Typography size="sm">{text}</Typography>
        </span>
      )}
    </>
  );
};
