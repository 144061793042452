import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { ClientRoutes } from "shared/routes/client";

import NotFoundPage from "app/pages/not-found";
// import { EndpointPage from 'app/pages/endpoints/endpoint';
// import { EndpointsOverview } from 'app/pages/endpoints/endpoints';

// Endpoint Pages
const EndpointsPage = React.lazy(() => import("app/pages/endpoints/endpoints"));
const EndpointCreatePage = React.lazy(
  () => import("app/pages/endpoints/create")
);
const EndpointPage = React.lazy(() => import("app/pages/endpoints/endpoint"));

// Document Index Page
const DocumentsIndexPage = React.lazy(() => import("app/pages/documents"));

// Auth Pages
const LoginPage = React.lazy(() => import("app/pages/login"));
const LogoutPage = React.lazy(() => import("app/pages/logout"));

// Dashboard Pages
const DashboardPage = React.lazy(() => import("app/pages/dashboard"));

// Team Pages
const TeamsPage = React.lazy(() => import("app/pages/teams/teams"));
const TeamPage = React.lazy(() => import("app/pages/teams/team"));
const TeamCreatePage = React.lazy(() => import("app/pages/teams/team-create"));

// Profile Pages
const ProfilePage = React.lazy(() => import("app/pages/profile"));

// Group Pages
const GroupsPage = React.lazy(() => import("app/pages/groups/groups"));
const GroupPage = React.lazy(() => import("app/pages/groups/group"));
const GroupCreatePage = React.lazy(
  () => import("app/pages/groups/group-create")
);

// Organization Pages
const OrganizationPage = React.lazy(() => import("app/pages/organization"));

// Admin Pages
const AdminPage = React.lazy(() => import("app/pages/admin"));

// Compliance
const CompliancePage = React.lazy(() => import("app/pages/compliance"));

// Reports
const ReportsPage = React.lazy(() => import("app/pages/reports"));

// Remedation
const RemediationIndexPage = React.lazy(() => import("app/pages/remediation"));

// Notification Page
const NotificationsPage = React.lazy(
  () => import("app/pages/notifications/notifications")
);
const NotificationPage = React.lazy(
  () => import("app/pages/notifications/notification")
);

// const LoginPage = React.lazy(() => import('app/pages/Login'))
// const DashboardPage = React.lazy(() => import('app/pages/Dashboard'))
// const CompliancePage = React.lazy(() => import('app/pages/Compliance'))
// const ReportsPage = React.lazy(() => import('app/pages/Reports'))
// const EndpointsPage = React.lazy(() => import('app/pages/Endpoints'))
// const SettingsPage = React.lazy(() => import('app/pages/Settings'))
// const LogoutPage = React.lazy(() => import('app/pages/Logout'))

const clientRoutes = new ClientRoutes();

export const Routes = (props) => {
  return (
    <Switch>
      {/* PUBLIC */}
      {/* <Route 
                path='/' 
                exact     
                render={() => <Redirect to={appclientRoutes.auth.base.toRoute}/>}
            />
        <PublicRoute path={appclientRoutes.auth.base.toRoute} component={AuthPage}/> */}
      <Route
        path="/"
        exact
        render={() => <Redirect to={clientRoutes.login.toRoute} />}
      />

      {/* ORDER MATTERS!!! */}
      <PrivateRoute
        exact
        path={clientRoutes.notifications.toRoute}
        component={NotificationsPage}
      />
      <PrivateRoute
        path={clientRoutes.notification.toRoute}
        component={NotificationPage}
      />

      <PrivateRoute
        path={clientRoutes.dashboards.toRoute}
        component={DashboardPage}
      />

      <PrivateRoute path={clientRoutes.endpoints.toRoute}>
        <Route
          exact
          path={clientRoutes.endpoints.toRoute}
          component={EndpointsPage}
        />
        <Route
          exact
          path={clientRoutes.endpointsCreate.toRoute}
          component={EndpointCreatePage}
        />
        <Route path={clientRoutes.endpoint.toRoute} component={EndpointPage} />
      </PrivateRoute>

      <PrivateRoute path={clientRoutes.groups.toRoute}>
        <Route
          exact
          path={clientRoutes.groups.toRoute}
          component={GroupsPage}
        />
        <Route
          exact
          path={clientRoutes.groupsCreate.toRoute}
          component={GroupCreatePage}
        />
        <Route path={clientRoutes.group.toRoute} component={GroupPage} />
      </PrivateRoute>

      <PrivateRoute
        path={clientRoutes.remediations.toRoute}
        component={RemediationIndexPage}
      />

      {/* DOCUMENT ROUTES */}
      <PrivateRoute
        path={clientRoutes.documents.toRoute}
        component={DocumentsIndexPage}
      />

      {/* TEAM ROUTES */}
      <PrivateRoute path={clientRoutes.teams.toRoute}>
        <Route exact path={clientRoutes.teams.toRoute} component={TeamsPage} />
        <Route
          exact
          path={clientRoutes.teamsCreate.toRoute}
          component={TeamCreatePage}
        />
        <Route path={clientRoutes.team.toRoute} component={TeamPage} />
      </PrivateRoute>

      {/* COMPLIANCE */}
      <PrivateRoute
        path={clientRoutes.compliance.toRoute}
        component={CompliancePage}
      />
      <PrivateRoute
        path={clientRoutes.reports.toRoute}
        component={ReportsPage}
      />

      {/* ORGANIZATION ROUTES */}
      <PrivateRoute
        path={clientRoutes.organization.toRoute}
        component={OrganizationPage}
      />

      {/* ADMIN ROUTES */}
      <PrivateRoute
        requiresAdmin={clientRoutes.admin.requiresAdmin}
        path={clientRoutes.admin.toRoute}
        component={AdminPage}
      />

      {/* PROFILE/AUTH*/}
      <PrivateRoute
        path={clientRoutes.profile.toRoute}
        component={ProfilePage}
      />
      <PublicRoute path={clientRoutes.logout.toRoute} component={LogoutPage} />
      <PublicRoute path={clientRoutes.login.toRoute} component={LoginPage} />

      <PrivateRoute path="*" component={NotFoundPage} />
    </Switch>
  );
};
