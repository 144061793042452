import { AppAPI } from "services/AppApi.service";
import React from "react";
import { Context, IAuthState } from "./context";
import { parseAxiosErrorForMessage } from "utils/functions.utils";
import { LocalStorageService } from "services/LocalStorage.service";
import { fromUnixTime, isPast, sub } from "date-fns";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { IUser } from "shared/interfaces/user.interface";

interface ILogin {
  username: string;
  password: string;
}

interface IAuthHook extends IAuthState {
  authCheck: () => {};
  handleLogin: (data: ILogin) => Promise<void>;
  handleCertLogin: () => Promise<void>;
  handleLogout: () => Promise<void>;
  handleUpdateAuthUser: (data: Partial<IUser>) => {};
  handleAddAvatar: (data: FormData) => {};
  handleRemoveAvatar: () => {};
  handleChangePassword: (data: FormData) => Promise<void>;
  handleRegisterCert: () => {};
  handleRemoveCert: () => {};
}

const LOGIN_PATH = "/auth/login";
const CERT_LOGIN_PATH = "/auth/cert";
const CERT_REGISTER_PATH = "/auth/me/cert";
const LOGOUT_PATH = "/auth/logout";
const AUTH_USER_PATH = "/auth/me";
const AUTH_USER_AVATAR_PATH = "/auth/me/avatar";
const AUTH_USER_PASSWORD_PATH = "/auth/me/password";
const REFRESH_TOKENS_PATH = "/auth/tokens";

export const useAuth = () => {
  const [state, setState, reset] = React.useContext(Context);
  const api = React.useMemo(() => new AppAPI(), []);

  const authCheck = async () => {
    try {
      let isAuthenticated = false;
      let requestAccessToken = true;
      let requestRefreshToken = true;
      let allowRequestTokens = false;

      const accessToken = LocalStorageService.getAccessToken();
      const refreshToken = LocalStorageService.getRefreshToken();

      // Ensure both tokens exists in local storage
      if (accessToken && refreshToken) {
        // Check if access token is expired
        // @ts-ignore
        const decodedAccessToken = jwtDecode(accessToken) as { exp: string };
        const accessExpirationDate = fromUnixTime(
          parseInt(decodedAccessToken.exp)
        );
        const accessWarningWindowDate = sub(accessExpirationDate, {
          minutes: 2,
        });

        requestAccessToken =
          isPast(accessExpirationDate) || isPast(accessWarningWindowDate);

        // Refresh token found in local storage
        // @ts-ignore
        const decodedRefreshToken = jwtDecode(refreshToken) as { exp: string };
        const refreshExpirationDate = fromUnixTime(
          parseInt(decodedRefreshToken.exp)
        );
        const refreshWarningWindowDate = sub(refreshExpirationDate, {
          hours: 2,
        });
        requestRefreshToken = isPast(refreshWarningWindowDate);

        allowRequestTokens = !isPast(refreshExpirationDate);

        // Clear invalid tokens from local storage if no authentication was found
        if (!allowRequestTokens) {
          LocalStorageService.clearTokens();
          reset();
        }
      }

      if (allowRequestTokens) {
        // Refresh access token using refresh token
        if (requestRefreshToken) {
          await handleRefreshTokens("refresh");
        }
        // Refresh refresh token using refresh token
        if (requestAccessToken) {
          await handleRefreshTokens("access");
        }
        // Refresh authenticated user from page reload with found/refreshed tokens

        if (!state.me) {
          await handleRefreshUser();
          isAuthenticated = true;
        } else {
          isAuthenticated = true;
        }
      }

      if (state.isInitialAuthCheck || !isAuthenticated) {
        setState({ isAuthenticated, isInitialAuthCheck: false });
      }

      return isAuthenticated;
    } catch (error) {
      return false;
    }
  };

  const handleRefreshTokens = async (type: string) => {
    try {
      const refreshToken = LocalStorageService.getRefreshToken();

      if (!refreshToken) return false;

      const res = await api.get({
        url: REFRESH_TOKENS_PATH,
        config: {
          headers: { "x-refresh-token": refreshToken },
          params: { type },
        },
      });

      if (res?.data?.data?.access || res?.data?.data?.refresh) {
        LocalStorageService.setTokens({
          accessToken: res?.data?.data?.access,
          refreshToken: res?.data?.data?.refresh,
        });
      }
    } catch (error) {
    } finally {
    }
  };

  const handleRefreshUser = async () => {
    try {
      const refreshToken = LocalStorageService.getRefreshToken();

      if (!refreshToken) return false;

      const res = await api.get({ url: AUTH_USER_PATH });

      if (res?.data?.data) {
        setState({ isAuthenticated: true, me: res?.data?.data });
      }
    } catch (error) {
    } finally {
    }
  };

  const handleLogin = async (data: ILogin) => {
    try {
      setState({ error: null, isAuthenticating: true, isAuthenticated: false });
      const res = await api.post({ url: LOGIN_PATH, data });

      if (res?.data?.data) {
        LocalStorageService.setTokens({
          accessToken: res?.data?.data?.access,
          refreshToken: res?.data?.data?.refresh,
        });
        LocalStorageService.setBannerModalAck();
        setState({ isAuthenticated: true, me: res?.data?.data?.me });
      }
    } catch (error) {
      setState({ error: parseAxiosErrorForMessage(error)?.message });
    } finally {
      setState({ isAuthenticating: false });
    }
  };

  const handleCertLogin = async () => {
    try {
      setState({
        error: null,
        isCertAuthenticating: true,
        isCertAuthenticated: false,
      });
      const res = await api.post({ url: CERT_LOGIN_PATH });

      if (res?.data?.data) {
        LocalStorageService.setTokens({
          accessToken: res?.data?.data?.access,
          refreshToken: res?.data?.data?.refresh,
        });
        LocalStorageService.setBannerModalAck();

        setState({ isCertAuthenticated: true, me: res?.data?.data?.me });
      }
    } catch (error) {
      setState({ error: parseAxiosErrorForMessage(error)?.message });
    } finally {
      setState({ isCertAuthenticating: false });
    }
  };

  const handleLogout = async () => {
    try {
      setState({ isLoggingOut: true });
      await api.post({ url: LOGOUT_PATH });
      LocalStorageService.clearOnLogout();
      setState({ isLoggingOut: false, me: null });
    } catch (error) {
      setState({ error: parseAxiosErrorForMessage(error)?.message });
      setState({ isLoggingOut: false });
      LocalStorageService.clearOnLogout();
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    }
  };

  const handleUpdateAuthUser = async (data: Partial<IUser>) => {
    try {
      const res = await api.put({ url: AUTH_USER_PATH, data });
      if (res?.data?.data) {
        setState({ me: res?.data?.data });
      }
    } catch (error) {
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    }
  };

  const handleAddAvatar = async (data: FormData) => {
    try {
      const res = await api.post({ url: AUTH_USER_AVATAR_PATH, data });
      if (res?.data?.data) {
        setState({ me: res?.data?.data });
      }
    } catch (error) {
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    }
  };

  const handleRemoveAvatar = async () => {
    try {
      const res = await api.delete({ url: AUTH_USER_AVATAR_PATH });
      if (res?.data?.data) {
        setState({ me: res?.data?.data });
      }
    } catch (error) {
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    }
  };

  const handleChangePassword = async (data: FormData) => {
    try {
      setState({ error: null, isChangingPassword: true });
      const res = await api.put({ url: AUTH_USER_PASSWORD_PATH, data });
      if (res?.data?.data) {
        setState({ me: res?.data?.data, isChangingPassword: false });
      }
    } catch (error) {
      setState({
        error: parseAxiosErrorForMessage(error)?.message,
        isChangingPassword: false,
      });
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    }
  };

  const handleRegisterCert = async () => {
    try {
      setState({ error: null, isChangingCert: true });
      const res = await api.post({ url: CERT_REGISTER_PATH });
      if (res?.data?.data) {
        setState({ me: res?.data?.data, isChangingCert: false });
      }
    } catch (error) {
      toast.error(parseAxiosErrorForMessage(error)?.message);
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    } finally {
      setState({ isChangingCert: false });
    }
  };

  const handleRemoveCert = async () => {
    try {
      setState({ error: null, isChangingCert: true });

      const res = await api.delete({ url: CERT_REGISTER_PATH });
      if (res?.data?.data) {
        setState({ me: res?.data?.data, isChangingCert: false });
      }
    } catch (error) {
      setState({
        error: parseAxiosErrorForMessage(error)?.message,
      });
      throw new Error(parseAxiosErrorForMessage(error)?.message);
    } finally {
      setState({ isChangingCert: false });
    }
  };

  return {
    ...state,
    authCheck,
    handleLogin,
    handleCertLogin,
    handleLogout,
    handleUpdateAuthUser,
    handleAddAvatar,
    handleRemoveAvatar,
    handleChangePassword,
    handleRegisterCert,
    handleRemoveCert,
  } as IAuthHook;
};
