import React from 'react';
import { ISelect } from './select.interface';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import { 
    VirtMenuList,
    Input,
    Control,
    ValueContainer,
    IndicatorSeparator,
    LoadingIndicator,
    Placeholder,
    DropdownIndicator,
    Menu,
    SingleValue,
    MultiValueLabel,
    MultiValueContainer,
    MultiValue,
    Option,
    ClearIndicator,
    MenuList,
} from 'app/components/generics/Select/custom-components';
import { Input as GenericInput } from '../Input';

export default React.forwardRef<HTMLSelectElement, ISelect>((props, ref) => {
    const { 
        className = '', 
        error, 
        label, 
        labelFor, 
        message, 
        block, 
        autoFocus,
        virtual, 
        footer,
        hint, 
        size, 
        register = {},
        labelProps = {},
        hintProps = {},
        messageProps = {},
        ...rest
    } = props;

    let styles: any = {
        control: base => {
            delete base['&:hover']?.borderColor;
            delete base?.borderColor;
            return ({ ...base, minHeight: 32, boxShadow: 'none' });
        },
        dropdownIndicator: base => ({ ...base, paddingTop: 4, paddingBottom: 4 }),
        clearIndicator: base => ({ ...base, padding: 4 }),
        indicatorSeparator: base => ({ ...base, marginTop: 4, marginBottom: 4 }),
        input: base => ({ ...base, padding: 0, margin: 0, fontSize: '0.85rem' }),
        multiValue: base => ({ ...base, margin: 1 }),
        multiValueLabel: base => ({ ...base, padding: '2px 4px', fontSize: '0.85rem' }),
        valueContainer: base => ({ ...base, padding: '3px 6px', }),
        multiValueRemove: base => ({ ...base, paddingLeft: 2, paddingRight: 2 }),
        option: base => ({ ...base, padding: '3px 8px', fontSize: '0.85rem' }),
        placeholder: base => ({ ...base, fontSize: '0.85rem' }),
    };

    if (size == 'sm') {
        styles = {
            control: base => {
                delete base['&:hover']?.borderColor;
                delete base?.borderColor;
                return ({ ...base, minHeight: 28, boxShadow: 'none' });
            },
            dropdownIndicator: base => ({ ...base, paddingTop: 3, paddingBottom: 3 }),
            clearIndicator: base => ({ ...base, padding: 4 }),
            indicatorContainer: base => ({ ...base, padding: 3, }),
            indicatorSeparator: base => ({ ...base, marginTop: 4, marginBottom: 4 }),
            input: base => ({ ...base, padding: 0, margin: 0, fontSize: '0.75rem' }),
            multiValue: base => ({ ...base, margin: 1 }),
            multiValueLabel: base => ({ ...base, padding: '1px 4px', fontSize: '0.75rem' }),
            valueContainer: base => ({ ...base, padding: '2px 6px', }),
            multiValueRemove: base => ({ ...base, paddingLeft: 2, paddingRight: 2 }),
            option: base => ({ ...base, padding: '3px 8px', fontSize: '0.75rem' }),
            placeholder: base => ({ ...base, fontSize: '0.75rem' }),
        }
    } else if (size == 'lg') {
        styles = {            
            control: base => {
                delete base['&:hover']?.borderColor;
                delete base?.borderColor;
                return ({ ...base, minHeight: 36, boxShadow: 'none' });
            },
            option: base => ({ ...base, fontSize: '85%' }),
        }
    }

    const handleKeyDown = (evt) => {
        switch (evt.key) {
          case "Home":
            evt.preventDefault();
            if (evt.shiftKey) evt.target.selectionStart = 0;
            else evt.target.setSelectionRange(0, 0);
            break;
          case "End":
            evt.preventDefault();
            const len = evt.target.value.length;
            if (evt.shiftKey) evt.target.selectionEnd = len;
            else evt.target.setSelectionRange(len, len);
            break;
          default:
            return;
        }
    };

    return (
        <div className={classNames(block ? 'w-full' : '', className)}>
            {
                (label || hint) && (
                    <div className='flex items-center justify-between mb-1'>
                        {
                            label && (
                                <GenericInput.Label htmlFor={labelFor || label}>
                                    {label}
                                </GenericInput.Label>
                            )
                        }
                        {
                            hint && (
                                <GenericInput.Hint>
                                    { hint }
                                </GenericInput.Hint>
                            )
                        }
                    </div>                    
                )
            }
            <div className={classNames('relative rounded-sm', className, rest.isDisabled ? 'cursor-not-allowed' : '')}>
                <AsyncSelect
                //@ts-ignore
                    ref={ref}
                    autoFocus={autoFocus}
                    styles={styles}
                    components={{
                        Input,
                        Control,
                        ValueContainer,
                        IndicatorSeparator,
                        LoadingIndicator,
                        Placeholder,
                        DropdownIndicator,
                        Menu,
                        SingleValue,
                        MultiValueLabel,
                        MultiValueContainer,
                        MultiValue,
                        Option,
                        ClearIndicator,
                        MenuList: virtual ? VirtMenuList : MenuList,
                    }}
                    classNamePrefix="react-select"
                    // options={[
                    //     {label: 'Test 1', value: 1},
                    //     {label: 'Test 2', value: 2},
                    //     {label: 'Test 3', value: 3},
                    // ]}
                    // defaultValue={[
                    //     {label: 'Test 1', value: 1},
                    //     {label: 'Test 2', value: 2},
                    //     {label: 'Test 3', value: 3},
                    // ]}
                    // isDisabled
                    // isLoading
                    // isClearable
                    // isMulti
                    {...rest}
                    onKeyDown={e => {
                        rest.onKeyDown && rest.onKeyDown(e);
                        handleKeyDown(e);
                    }}
                />
            </div>
            {
                footer === null ? null  : (
                    typeof footer == 'function' ? footer({ message }) : !!message && (
                        <div className='flex justify-between mt-0.5'>
                            <div>
                                {
                                    message && (
                                        <p 
                                            className={classNames([
                                                'text-xs',
                                                error 
                                                    ? 'text-red-500 dark:text-red-400'
                                                    : 'text-gray-600 dark:text-gray-300',
                                            ])}
                                        >
                                            {message}
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                    )
                )
            }
        </div>
    )
})