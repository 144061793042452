const ACCESS_TOKEN = "ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const BANNER_ACK = "BANNER_ACK";
const BANNER_MODAL_TEXT = "BANNER_MODAL_TEXT";
const BANNER_TEXT = "BANNER_TEXT";
const BANNER_COLOR = "BANNER_COLOR";

export class LocalStorageService {
  /* TOKEN ACTIONS || TOKEN ACTIONS || TOKEN ACTIONS */
  static clearTokens() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }
  static clearAccessTokens() {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  static getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }
  static getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  static setTokens(tokens: { accessToken: string; refreshToken: string }) {
    if (tokens.accessToken) {
      localStorage.setItem(ACCESS_TOKEN, tokens.accessToken);
    }
    if (tokens.refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
    }
    if (window) {
      window.dispatchEvent(new Event("storage"));
    }
  }
  /* TOKEN ACTIONS || TOKEN ACTIONS || TOKEN ACTIONS */

  static getBannerText() {
    return localStorage.getItem(BANNER_TEXT);
  }
  static setBannerText(input) {
    return localStorage.setItem(BANNER_TEXT, input);
  }

  static getBannerColor() {
    return localStorage.getItem(BANNER_COLOR);
  }
  static setBannerColor(input) {
    return localStorage.setItem(BANNER_COLOR, input);
  }

  static getBannerModalText() {
    return localStorage.getItem(BANNER_MODAL_TEXT);
  }
  static setBannerModalText(input) {
    return localStorage.setItem(BANNER_MODAL_TEXT, input);
  }

  static getBannerModalAck() {
    return localStorage.getItem(BANNER_ACK);
  }
  static setBannerModalAck() {
    localStorage.setItem(BANNER_ACK, new Date().toISOString());
  }
  static removeBannerAck() {
    localStorage.removeItem(BANNER_ACK);
  }

  static clearOnLogout() {
    this.clearTokens();
    this.clearAccessTokens();
    this.removeBannerAck();
  }
}
