import classNames from 'classnames';
import styled from 'styled-components';
export { PageLoader } from './PageLoader';
export { ButtonLoader } from './ButtonLoader';
export { ComponentLoader } from './ComponentLoader';

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface ILoader {
    size: SizeType;
    center?: boolean;
    abs?: boolean;
    overlay?: boolean;
    className?: string;
}

const sizes = {
    xs: 10,
    sm: 12,
    md: 16,
    lg: 20,
    xl: 24,
    xxl: 48,
}

export default (props: Partial<ILoader>) => {
    const { size = 'md', center, abs, className = '', overlay } = props;
    return (
        <div  
            className={classNames(
                'z-10',
                className,
                abs ? 'absolute' : '',
                center ? 'flex justify-center items-center' : '',
                center ? (abs ? 'left-0 right-0 top-0 bottom-0' : 'flex-1 w-full h-full') : '',
                overlay ? 'bg-gray-300 dark:bg-gray-800 bg-opacity-30 dark:bg-opacity-70' : '',
                'text-blue-500 dark:text-blue-400'
            )}
        >
            <div
                style={{
                    height: sizes[size],
                    width: sizes[size],
                }}
            >
                <svg className='animate-spin' style={{ color: 'inherit' }} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                    <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                </svg>
            </div>
        </div>
    )
}