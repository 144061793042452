import * as React from 'react';

import { useAuth } from 'services/auth';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { PageLoader } from 'app/components/generics/Loader';
import { ClientRoutes } from 'shared/routes/client';

const AUTH_CHECK_INTERVAL = 15 * 1000; // Seconds

const clientRoutes = new ClientRoutes();

export const Main: React.FC = ({ children }) => {
    const { isAuthenticated, isInitialAuthCheck, checkingTokens, authCheck, me } = useAuth();
    const location = useLocation();
    
    React.useEffect(() => {

        handleAuthCheck();

        const interval = setInterval(() => {
            handleAuthCheck();
        }, AUTH_CHECK_INTERVAL);
        return () => clearInterval(interval);
    }, [me, isInitialAuthCheck]);
    
    React.useEffect(() => {
        if (!isInitialAuthCheck && !checkingTokens && location.pathname != clientRoutes.login.toURL && !isAuthenticated) {
            window.location.assign(clientRoutes.login.toURL);
        }
    }, [isInitialAuthCheck, checkingTokens, location.pathname]);

    // Check tokens for authentication
    const handleAuthCheck = async () => {
        if (!clientRoutes.login.isMatch() || isInitialAuthCheck) {
            await authCheck();
        }
    }

    return (
        <React.Fragment>
            {
                isInitialAuthCheck ? <PageLoader abs/> : (!isInitialAuthCheck && children)
            }
        </React.Fragment>
    ); 
}