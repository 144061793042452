import { Typography } from "app/components/generics";
import * as React from "react";

export const NotFoundPage = () => {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center space-y-4">
      <Typography size="3xl">Page not found</Typography>
    </div>
  );
};
