import { Input } from 'app/components/generics/Input';
import classNames from 'classnames';
import { rest } from 'lodash';
import React from 'react';
import { generateRandomKey } from 'utils/functions.utils';

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
interface ICheckbox extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    register?: any;
    indeterminate?: boolean;
    checked?: boolean;
    flex?: boolean;
    placement?: 'left' | 'right';

    displayLabel?: string;
    orientation?: 'vertical' | 'horizontal';
    size?: SizeType;
    error?: boolean;
    message?: any;
    align?: 'start' | 'center' | 'end';
    hint?: any;
    block?: boolean;
    isOptional?: boolean;
    footer?: any;
    loading?: boolean;
    label?: string;
    labelFor?: string;
    labelProps?: any;
    hintProps?: any;
    messageProps?: any;
}

interface ICheckboxGroup extends React.FieldsetHTMLAttributes<HTMLFieldSetElement> {
    orientation?: 'vertical' | 'horizontal';
}

interface ICompoundedCheckboxComponent extends React.ForwardRefExoticComponent<ICheckbox & React.RefAttributes<HTMLInputElement>> {
    Group: React.ForwardRefExoticComponent<ICheckboxGroup & React.RefAttributes<ICheckbox>>
}

const containerSizes = {
    md: 'h-4 w-4',
    lg: 'h-5 w-5',
    // lg: 'h-6 w-6',
}
const checkSizes = {
    md: 'h-2 w-2',
    lg: 'h-3 w-3',
    // lg: 'h-4 w-4',
}
const textSizes = {
    md: 'text-xs',
    lg: 'text-sm',
    // lg: 'h-4 w-4',
}

const Checkbox = React.forwardRef<HTMLInputElement, ICheckbox>((props, ref) => {
    const { 
        indeterminate, 
        checked,
        className = '', 
        error, 
        label, 
        labelFor,
        placement = 'left',
        orientation = 'vertical',
        align,
        message,
        flex,
        block,
        isOptional,
        hint, 
        size,
        footer = null,
        register = {},
        labelProps = {},
        hintProps = {},
        messageProps = {},
        ...rest
    } = props;
    const innerRef = React.useRef();
    const [defaultId] = React.useState(`input-${generateRandomKey()}`)

    React.useEffect(() => {
        // @ts-ignore
        if (innerRef?.current) {
            // @ts-ignore
            // ref.current.checked = rest.value == 'checked' || checked;
            // @ts-ignore
            innerRef.current.indeterminate = rest.value == 'indeterminate' || indeterminate;
        }
    }, [innerRef, rest.value, indeterminate, checked]);

    let containerSize = containerSizes.md;
    let checkSize = checkSizes.md;
    let textSize = textSizes.md;

    if (size && containerSizes[size]) {
        containerSize = containerSizes[size];
        checkSize = checkSizes[size];
        textSize = textSizes[size];
    }

    const displayLabel = (label || hint || isOptional) && (
        <div 
            className={classNames([
                'flex flex-auto justify-between mb-1 items-center',
                orientation == 'horizontal' ? placement == 'left'  ? 'mr-2' : 'ml-2' : '',
            ])}
        >
            <div className='w-full'>
                {
                    label && (
                        <Input.Label type={error ? 'error' : ''} labelFor={rest.id || labelFor || defaultId}>
                            {label}
                        </Input.Label>
                    )
                }
            </div>
            <div className='ml-2'>
                {
                    (hint || isOptional) && (
                        <Input.Hint>
                            { hint || isOptional && 'Optional' }
                        </Input.Hint>
                    )
                }
            </div>
        </div>                    
    )

    return (
        <div className={classNames([
            block ? 'w-full' : '',
            'relative flex flex-col',
            className,
        ])}>
            <div
                className={classNames([
                    'flex',
                    align == 'center' ? 'items-center justify-center' : '',
                    orientation == 'vertical' ? 'flex-col space-y-2' : 'items-baseline space-x-2',
                ])}
            >
                {
                    placement == 'right' && displayLabel
                }
                <div className={classNames([
                    `flex items-center justify-center ${containerSize}`,
                ])}>
                    <div className={classNames([
                        'rounded-sm',
                        `flex items-center justify-center ${containerSize}`,
                        // 'focus-within:ring-1',
                        // 'focus-within:ring-offset-2',
                        // 'focus-within:ring-offset-transparent',
                        // 'focus-within:ring-gray-300',
                        // 'dark:focus-within:ring-gray-600',
                        'focus-within:outline-dashed',
                        'focus-within:outline-1',
                        'focus-within:outline-gray-500',
                        'focus-within:dark:outline-gray-500',
                        'focus-within:outline-offset-1',
                    ])}>
                        <input
                            ref={ref || innerRef}
                            id={rest.id || rest.name || defaultId}
                            type='checkbox'
                            className={classNames([
                                'absolute',
                                containerSize,
                                'opacity-0',
                                !rest.disabled ? 'cursor-pointer' : '',
                            ])}
                            checked={checked}
                            {...register}
                            {...rest}
                            onChange={e => {
                                rest.onChange && rest.onChange(e);
                                register.onChange && register.onChange(e);
                            }}
                        />
                        <div className={classNames([
                            `${containerSize} border-2 rounded flex flex-shrink-0 justify-center items-center focus-within:border-blue-400`,
                            rest.disabled ? 'bg-gray-300 dark:bg-gray-600 border-transparent bg-opacity-40 dark:bg-opacity-60' : 'border-blue-400',
                            !rest.disabled ? 'cursor-pointer' : '',
                        ])}>
                            <svg className={`fill-current invisible ${checkSize} !bg-transparent pointer-events-none`} version='1.1' viewBox='0 0 17 12' xmlns='http://www.w3.org/2000/svg'>
                                {
                                    indeterminate ? (
                                        <line x1="0" y1="7" x2="20" y2="7" fill='currentColor' strokeLinecap="round" style={{ stroke: 'currentcolor', strokeWidth: 4 }}/>
                                    ) : (
                                        <g fill='none' fillRule='evenodd'>
                                            <g transform='translate(-9 -11)' fill='currentColor' fillRule='nonzero'>
                                                <path d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
                                            </g>
                                        </g>
                                    )
                                }
                            </svg>
                        </div>
                    </div>                
                </div>
                {
                    placement == 'left' && displayLabel
                }
            </div>
            {
                footer === null ? null : (
                    typeof footer == 'function' ? footer({ message }) : !!message ? (
                        <div className='flex mt-0.5'>
                            <div>
                                {
                                    message && (
                                        <p 
                                            className={classNames([
                                                'text-xs',
                                                error 
                                                    ? 'text-red-500 dark:text-red-400'
                                                    : 'text-gray-600 dark:text-gray-300',
                                            ])}
                                        >
                                            {message}
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                    ) : <div style={{ minHeight: 16 }}/>
                )
            }
        </div>
    )
});

const Group = (props: ICheckboxGroup) => {
    const {
        orientation,
        className = '',
        children,
        ...rest
    } = props;

    let _orientation = 'space-y-2';
    
    if (orientation == 'horizontal') {
        _orientation = 'flex space-x-2';
    }

    React.Children.map(children, v => {
        //@ts-ignore
        if (!v.type?.name || v.type?.name != 'Checkbox') {
            throw new Error('Only a Checkbox can be a child of a checkbox group')
        }
    })

    return (
        <fieldset className={`${_orientation} ${className}`} {...rest}>
            {children}
        </fieldset>
    )
}

// @ts-ignore
Checkbox.Group = Group;

export {
    Checkbox
}