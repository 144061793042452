import { Banner } from 'app/components/Banner';
import * as React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PublicLayout: React.FC = ({ children }) => (
    <>  
        <div className='fixed top-0 left-0 right-0'>
            <Banner/>
        </div>
        { children }
    </>
)