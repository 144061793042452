// @ts-nocheck
import { EAcasRiskFactor } from "shared/enums/acas-risk-factor.enum";
import { IStigComment } from "shared/interfaces/stig-comment.interface";
import { json } from "utils/functions.utils";

export const downloadFromApiResponse = (url, filename = "") => {
  if (!url || typeof url != "string") return;
  const link = document.createElement("a");
  link.href = url;
  if (filename) {
    link.download = filename;
  }

  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getAcasRiskFromScore = (
  score,
  options?: { isDod?: boolean; version?: 2 | 3 } = { isDod: false, version: 2 }
) => {
  let risk = EAcasRiskFactor.NONE;
  if (options.isDod) {
  } else {
    if (options.version == 2) {
      if (score >= 7) risk = EAcasRiskFactor.HIGH;
      if (score >= 4 && score < 7) risk = EAcasRiskFactor.MEDIUM;
      if (score > 0 && score < 4) risk = EAcasRiskFactor.LOW;
    } else {
      if (score >= 9) risk = EAcasRiskFactor.CRITICAL;
      if (score >= 7 && score < 9) risk = EAcasRiskFactor.HIGH;
      if (score >= 4 && score < 7) risk = EAcasRiskFactor.MEDIUM;
      if (score > 0 && score < 4) risk = EAcasRiskFactor.LOW;
      if (score == 0) risk = EAcasRiskFactor.NONE;
    }
  }

  return risk;
};

export const copyTextToClipboard = async (text) => {
  if (typeof text != "string") return;

  try {
    await navigator.clipboard.writeText(text);
    return;
  } catch (error) {}

  fallbackCopyTextToClipboard(text);
};

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "-9999px";
  textArea.style.left = "-9999px";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {}

  document.body.removeChild(textArea);
}

export const arrayHasMany = (array: any[], retValueIfTrue) => {
  const isTrue = array?.length > 1;

  if (isTrue && typeof retValueIfTrue != "undefined") {
    return retValueIfTrue;
  }

  return isTrue;
};

export function keepPropListFromObject<T = string>(obj: {}, list?: T[]) {
  const newObj = { ...obj };

  if (!obj || !list?.length) return newObj;

  Object.keys(newObj).forEach((key) => {
    if (!list.includes(key)) {
      delete newObj[key];
    }
  });

  return newObj;
}

// $roots keeps previous parent properties as they will be added as a prefix for each prop.
// $sep is just a preference if you want to seperate nested paths other than dot.
export const flattenObject = (obj, roots = [], sep = ".") =>
  Object
    // find props of given object
    .keys(obj)
    // return an object by iterating props
    .reduce(
      (memo, prop) =>
        Object.assign(
          // create a new object
          {},
          // include previously returned object
          memo,
          // run array through
          Array.isArray(obj[prop])
            ? flattenObject(obj[prop], roots.concat([prop]), sep)
            : // keep working if value is an object
            Object.prototype.toString.call(obj[prop]) === "[object Object]"
            ? flattenObject(obj[prop], roots.concat([prop]), sep)
            : // include current prop and value and prefix prop with the roots
              { [roots.concat([prop]).join(sep)]: obj[prop] }
        ),
      {}
    );

// $roots keeps previous parent properties as they will be added as a prefix for each prop.
// $sep is just a preference if you want to seperate nested paths other than dot.
export const deepenObject = (obj) => {
  const result = {};

  if (Array.isArray(obj)) {
    return obj.map((v) => deepenObject(v));
  } else {
    // For each object path (property key) in the object
    for (const objectPath in obj) {
      // Split path into component parts
      const parts = objectPath.split(".");

      // Create sub-objects along path as needed
      let target = result;
      while (parts.length > 1) {
        const part = parts.shift();
        target = target[part] = target[part] || {};
      }

      // Set value at end of path
      target[parts[0]] = obj[objectPath];
    }

    return result;
  }
};

export function removePropListFromObject(obj: {}, list?: string[]) {
  const newObj = { ...obj };

  if (!obj || !list?.length) return newObj;

  for (let i = 0; i < list.length; i++) {
    const prop = list[i];

    if (prop.includes(".")) {
      const splitProps = prop.split(".");
      for (let j = 0; j < splitProps.length; j++) {
        const splitProp = splitProps[j];
        if (newObj.hasOwnProperty(splitProp)) {
          if (
            typeof newObj[splitProp] == "object" &&
            !Array.isArray(newObj[splitProp]) &&
            j != splitProps.length - 1
          ) {
            removePropListFromObject(newObj[splitProp], splitProps.slice(j));
          } else {
            delete newObj[splitProp];
          }
        }
      }
    } else {
      delete newObj[prop];
    }
  }

  return newObj;
}

export function findInArrayOfObjects(arr = [], val) {
  if (!val) return arr;

  return arr.reduce((acc, cur) => {
    if (findInArrayOfObjects(cur, val)) {
      acc.push({ ...cur });
    }
    return acc;
  }, []);
  // return arr.filter(v => findInObject(v, val));
}

export function findInArrayOfObjectsByKeyList(
  arr: any[] = [],
  val: string | string[] = "",
  keyList: any[] = []
) {
  if (!keyList?.length) return [];

  return arr.reduce((acc, cur) => {
    if (findInObjectByKeyList(cur, val, keyList)) {
      acc.push({ ...cur });
    }
    return acc;
  }, []);
  // return arr.filter(v => findInObjectByKeyList(v, val, keyList));
}

export function findInObjectByKeyList(
  obj = {},
  val = "",
  keyList = []
): boolean {
  obj = flattenObject(obj);
  const objKeys = Object.keys(obj);
  let found = false;
  const requiredFound: any = {};
  let hasRequired = false;

  if (Array.isArray(val)) {
    found = [];
  }
  if (!Array.isArray(val) && typeof val == "object") {
    found = {};
    Object.keys(val).forEach((key) => {
      const valValue = val[key];
      const objValue = obj[key];
      found[key] = (objValue + "")
        ?.toLowerCase?.()
        ?.includes?.(valValue?.toLowerCase?.());
    });
  } else {
    for (let i = 0; i < objKeys.length; i++) {
      const key = objKeys[i];
      const objValue = obj[key];

      if (keyList.includes(key)) {
        if (Array.isArray(val)) {
          for (let j = 0; j < val.length; j++) {
            if (typeof val[j] == "object") {
              if (!hasRequired) {
                hasRequired = true;
              }
              Object.keys(val[j]).forEach((key) => {
                const valValue = val[j][key];
                const objValue = obj[key];
                requiredFound[key] = (objValue + "")
                  ?.toLowerCase?.()
                  ?.includes?.(valValue?.toLowerCase?.());
              });
            } else {
              const v = val[j]?.toLowerCase?.();
              found.push(
                (objValue + "")?.toLowerCase?.()?.includes?.(v?.toLowerCase?.())
              );
            }
          }
        } else {
          found = (objValue + "")
            ?.toLowerCase?.()
            ?.includes?.(val?.toLowerCase?.());
        }
      }

      if (
        (Array.isArray(val) &&
          found?.length == val?.length &&
          found?.every((v) => !!v)) ||
        (!Array.isArray(val) && found)
      ) {
        break;
      }
    }
  }

  if (hasRequired) {
    return (
      Object.values(requiredFound).every((v) => !!v) &&
      (!found?.length || (!!found?.length && !!found.filter(Boolean)?.length))
    );
  }

  return (
    (!Array.isArray(found) &&
      typeof found == "object" &&
      Object.values(found).every((v) => !!v)) ||
    (Array.isArray(found) && !!found.filter(Boolean)?.length) ||
    (!Array.isArray(found) && typeof found != "object" && found)
  );
}

export function findInObject(obj = {}, val, deep = false): boolean {
  let found = false;
  val = val?.toLowerCase();

  if (!deep) {
    const sObj = json.stringify(obj);
    found = sObj?.toLowerCase()?.includes(val);
  } else {
    for (let i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      const o = obj[i];
      // If value is an array perform a recursive search
      if (Array.isArray(o)) {
        found = o.some((v) => findInObject(v, val));
      }
      // If property is an object, recursive search
      else if (typeof o == "object") {
        found = findInObject(obj[i], val);
      }
      // If property is string, search the string
      else if ((o + "")?.toLowerCase()?.includes(val)) {
        //
        objects.push(obj);
      }
    }
  }

  return found;
}

//return an array of objects according to key, value, or key and value matching
export function getObjects(obj, key, val) {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getObjects(obj[i], key, val));
    }
    //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    else if ((i == key && obj[i] == val) || (i == key && val == "")) {
      //
      objects.push(obj);
    } else if (obj[i] == val && key == "") {
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) == -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
}

//return an array of values that match on a certain key
export function getValues(obj, key) {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i == key) {
      objects.push(obj[i]);
    }
  }
  return objects;
}

//return an array of keys that match on a certain value
export function getKeys(obj, val) {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getKeys(obj[i], val));
    } else if (obj[i] == val) {
      objects.push(i);
    }
  }
  return objects;
}

export const formatStigComments = (comments: IStigComment[]) => {
  let output = "";
  for (const comment of comments) {
    output += `[CREATED AT]: ${comment?.createdAtDisplay}`;
    output += "\n";
    output += `[COMMENT]: ${comment?.comment}`;
    output += "\n";
    output += `[CREATOR]: ${comment?.creator?.$fullName} (${comment?.creator?.email})`;
    output += "\n";
    if (comments?.length > 1) {
      output += "\n";
      output += "-".repeat(50);
      output += "\n";
    }
  }

  output = `\n${"-".repeat(14)} [IMPORTED COMMENTS] ${"-".repeat(
    15
  )}\n${output}`;

  return output;
};

export const booleanToYesNo = (value: boolean, invert?: boolean = false) =>
  invert ? (value ? "No" : "Yes") : value ? "Yes" : "No";
