import React from "react";

import { IUser } from "shared/interfaces/user.interface";

export interface IAuthState {
  me: IUser;
  isAuthenticating: boolean;
  isCertAuthenticating: boolean;
  isChangingCert: boolean;
  isChangingPassword: boolean;
  isAuthenticated: boolean;
  checkingTokens: boolean;
  isInitialAuthCheck: boolean;
  error: string | null;
}

// interface IAuthContext {
//     [initialValues: number]: IInitialProps;
//     : (args?: Partial<IInitialProps>) => {};
// }

const initialValues: IAuthState = {
  //@ts-ignore
  me: null,
  isAuthenticating: false,
  isCertAuthenticating: false,
  isChangingPassword: false,
  isChangingCert: false,
  isAuthenticated: false,
  checkingTokens: false,
  isInitialAuthCheck: true,
  error: "",
};

export const Context = React.createContext<[IAuthState, any, any]>([
  { ...initialValues },
  null,
  null,
]);

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = React.useState<IAuthState>(initialValues);

  const handleUpdateState = (newState: Partial<IAuthState> = {}): any => {
    setState((state) => ({ ...state, ...newState }));
  };

  const resetState = (): any => {
    setState(initialValues);
  };

  return (
    <Context.Provider
      value={[state as IAuthState, handleUpdateState, resetState]}
    >
      {children}
    </Context.Provider>
  );
};
