import { useApi } from "hooks/api.hook";
import React from "react";
import { useDropzone } from "react-dropzone";
import { EVENT_APP_UPDATE_STIG } from "services/socket/events/app-update.event";
import { useSocket } from "services/socket/socket";
import { ApiRoutes } from "shared/routes/api";
import { maybeObject } from "utils/functions.utils";

export enum EAppUpdateStatus {
  IDLE = "IDLE",
  PENDING = "PENDING",
  UPDATING = "UPDATING",
  COMPLETE = "COMPLETE",
}

export interface IUpdateItem {
  count?: number;
  version: string;
  message: string;
  status: EAppUpdateStatus;
}

export interface IUpdateItems {
  stig: IUpdateItem;
}

export interface IUpdateState {
  showUpdateToast: boolean;
  error: string;
}

const defaultUpdateData = {
  message: "",
  version: "",
  status: EAppUpdateStatus.IDLE,
};

const initialValues: IUpdateState = {
  //@ts-ignore
  showUpdateToast: false,
  error: "",
};
const updateInitialValues: IUpdateItems = {
  stig: { ...defaultUpdateData },
};

const apiRoutes = new ApiRoutes();

export const Context = React.createContext<[any, any]>([
  { ...initialValues },
  null,
]);

export const UpdateContextProvider = ({ children }) => {
  const [updateToastExpanded, setUpdateToastExpanded] = React.useState(false);
  const [state, setState] = React.useState<IUpdateState>(initialValues);
  const [updates, setUpdates] =
    React.useState<IUpdateItems>(updateInitialValues);
  const [updateProgress, setUpdateProgress] = React.useState<
    number | undefined
  >(undefined);
  const UpdateService = useApi(apiRoutes.appUpdate.toURL, { load: true });
  const { socket } = useSocket();

  const uploading = updateProgress !== undefined;

  // STIG UPDATE
  React.useEffect(() => {
    socket?.on(EVENT_APP_UPDATE_STIG, (event) => {
      if (event.data) {
        setUpdates((state) => ({
          ...state,
          ...maybeObject(event.data),
          stig: {
            ...maybeObject(state?.stig),
            ...maybeObject(event.data?.stig),
          },
        }));
      }
    });
    return () => {
      socket?.off(EVENT_APP_UPDATE_STIG);
    };
  }, [socket, state]);

  React.useEffect(() => {
    if (UpdateService.data) {
      setUpdates((state) => ({
        ...state,
        stig: {
          ...(maybeObject(state?.stig) as any),
          version: UpdateService.data?.stig?.version,
          status: UpdateService.data?.stig?.status,
        },
      }));
    }
  }, [UpdateService.data]);

  const reset = (): any => {
    setState(initialValues);
  };

  const update = () => open();

  const handleUpdateProgress = (e) => {
    const progress = Math.ceil((e?.loaded / e?.total) * 100);
    setUpdateProgress(progress);
    if (progress == 100) {
      setTimeout(() => {
        setUpdateProgress(undefined);
      }, 1000);
    }
  };

  const onDropAccepted = (files) => {
    const formData = new FormData();

    formData.append("file", files?.[0]);

    UpdateService.create(formData, { onUploadProgress: handleUpdateProgress });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
  });

  return (
    <Context.Provider
      value={[
        {
          updates,
          uploading,
          updateProgress,
        },
        {
          reset,
          update,
        },
      ]}
    >
      {children}
      <div
        {...getRootProps()}
        className="absolute hidden"
        style={{ right: "-9999px" }}
      >
        <input {...getInputProps()} />
      </div>
    </Context.Provider>
  );
};
