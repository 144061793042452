import { Modal } from "app/components/generics/Modal"
import { Typography } from "app/components/generics/Typography";

export const ExternalLink = (props: any) => {
    const { url, open, onCancel, afterClose } = props;
    return (
        <Modal
            // maskClosable={false}
            open={open}
            onCancel={onCancel}
            // hideOkButton
            // hideCancelButton
            footer={null}
            header={null}
            width={400}
            afterClose={afterClose}
        >
            <div className="pt-4 space-y-4">
                <div className="space-y-4">
                    <Typography flat>
                        You are about to visit an external link and we would like to take a moment for you to review the link below. Links to external sites are generally benign but may be accompanied by unintentional consequences. Please review the link below before proceeding. 
                    </Typography>
                    <div className="space-y-0.5">
                        <Typography flat className="p-1">
                            External Link
                        </Typography>
                        <div className="dark:bg-gray-900 rounded-md p-2">
                            <Typography.Paragraph style={{ overflowWrap: 'break-word' }}>
                                {url}
                            </Typography.Paragraph>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        onClick={() => {
                            const a = document.createElement('a');
                            a.target = '_blank';
                            a.href = url;
                            a.rel = 'noopener noreferrer';
                            a.click();
                            a.remove();
                            onCancel?.();
                        }}
                        className="w-64 bg-red-500 text-white rounded-md px-2 h-9 flex items-center justify-center"
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </Modal>
    )
}