import { Typography } from 'app/components/generics';
import React from 'react';
import { parseAxiosErrorForMessage } from 'utils/functions.utils';

interface IToastError {
    error: any;
    id?: string;
    timestamp?: string;
    message?: string;
}

export const ToastError = (props: IToastError) => {
    const { error } = props;
    const parsedError = parseAxiosErrorForMessage(error);

    if (!parsedError?.id) {
        return (
            <Typography.Paragraph size='sm'>
                {parsedError?.message}
            </Typography.Paragraph>
        )
    }

    return (
        <div className='ml-2 cursor-default' style={{ width: 350 }}>
            <div className='flex space-x-2 items-center'>
                <div className='w-1/4'>
                    <Typography bold flat>
                        ID:
                    </Typography>
                </div>
                <div className='w-3/4 pt-0.5'>
                    <Typography size='sm'>
                        {parsedError?.id}
                    </Typography>
                </div>
            </div>
            <div className='flex space-x-2 items-center'>
                <div className='w-1/4'>
                    <Typography bold flat>
                        Timestamp:
                    </Typography>
                </div>
                <div className='w-3/4 pt-0.5'>
                    <Typography size='sm'>
                        {parsedError?.timestamp}
                    </Typography>
                </div>
            </div>
            <div className='flex space-x-2 pt-1'>
                <div className='w-1/4 self-start'>
                    <Typography bold flat>
                        Message:
                    </Typography>
                </div>
                <div className='w-3/4 pt-0.5'>
                    <Typography.Paragraph size='sm'>
                        {parsedError?.message}
                    </Typography.Paragraph>
                </div>
            </div>
        </div>
    )
}