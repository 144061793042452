import React, { CSSProperties } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Divider } from "app/components/generics";
import classNames from "classnames";
import { Button } from "app/components/generics/Button";
import { isPromise } from "utils/functions.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { THEME_DEFAULT_BORDER } from "constants/theme.contants";
import Loader from "app/components/generics/Loader";
import { Typography } from "app/components/generics/Typography";
import { ExternalLink } from "app/components/generics/Modal/ExternalLinkModal";
import {
  faCircleExclamation,
  faCircleQuestion,
  faCircleXmark,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";

interface IButtonProps {
  disabled?: boolean;
  loading?: boolean;
  block?: boolean;
  form?: string;
  onClick?: Function;
  htmlType?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
}

type ModalType = "warning" | "error" | "info" | "confirm" | "primary";

export interface IModalProps {
  open: boolean;
  loading?: boolean;
  maskClosable?: boolean;
  hideOkButton?: boolean;
  hideCancelButton?: boolean;
  bodyStyle?: CSSProperties;

  okText?: string;
  cancelText?: string;

  scroll?: "window" | "contained";

  type?: ModalType;
  okButtonProps?: IButtonProps;
  cancelButtonProps?: IButtonProps;

  onCancel?: Function;
  onOk?: Function;
  afterClose?: Function;

  width?: number;
  height?: number;

  className?: string;
  title?: any;
  children?: any;
  header?: any;
  footer?: any;
  extra?: any;
}

const iconColor = {
  warning: "text-yellow-500 dark:text-yellow-400",
  confirm: "text-yellow-500 dark:text-yellow-400",
  primary: "text-blue-500 dark:text-blue-400",
  error: "text-red-500 dark:text-red-400",
  info: "text-blue-500 dark:text-blue-400",
};

const icon = {
  warning: faCircleExclamation,
  confirm: faCircleExclamation,
  primary: faCircleQuestion,
  error: faCircleXmark,
  info: faCircleQuestion,
};

const MAX_BODY_HEIGHT = 500;

const Modal = (props: IModalProps) => {
  const {
    loading,
    onCancel,
    onOk,
    open,
    children,
    title,
    header,
    maskClosable = true,
    okButtonProps = {},
    okText,
    cancelButtonProps = {},
    cancelText,
    type,
    footer,
    height,
    width,
    scroll = "contained",
    afterClose,
    extra,
    hideOkButton,
    className,
    hideCancelButton,
    bodyStyle = {},
    ...rest
  } = props;

  const [pending, setPending] = React.useState(false);
  const [pendingOk, setPendingOk] = React.useState(false);
  const [pendingCancel, setPendingCancel] = React.useState(false);
  const wrapperStyle: CSSProperties = {};
  const _bodyStyle: CSSProperties = { ...bodyStyle };
  let maxWidthClass: any = "md:max-w-xl";

  if (width) {
    wrapperStyle.width = width;
    maxWidthClass = null;
  }
  if (className?.includes("max-w")) {
    maxWidthClass = null;
  }

  if (height) {
    wrapperStyle.height = height;
  }

  if (scroll == "contained" && !bodyStyle?.maxHeight) {
    _bodyStyle.maxHeight = MAX_BODY_HEIGHT;
  }

  const handleOnCancel = async () => {
    if (onCancel) {
      if (isPromise(onCancel)) {
        setPending(true);
        setPendingCancel(true);
        await (onCancel() as Promise<any>);
        setPending(false);
        setPendingCancel(false);
      } else {
        onCancel();
      }
    }
  };

  const handleOnOk = async () => {
    if (onOk) {
      if (isPromise(onOk)) {
        setPending(true);
        setPendingOk(true);
        await (onOk() as Promise<any>);
        setPending(false);
        setPendingOk(false);
      } else {
        onOk();
      }
    }
  };

  if (!okButtonProps?.style) {
    okButtonProps.style = { minWidth: 80 };
  }

  if (!cancelButtonProps?.style) {
    cancelButtonProps.style = { minWidth: 80 };
  }

  const handleAfterClose = (fn) => {
    setTimeout(() => {
      fn?.();
    }, 300);
  };

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        // className='fixed inset-0 z-50 overflow-y-auto'
        // open={open}
        onClose={() => {
          if (!maskClosable || pending) return;
          handleOnCancel?.();
        }}
        // {...rest}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-150"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames([
                  className,
                  maxWidthClass,
                  "inline-flex",
                  "flex-col",
                  "relative",
                  "align-bottom",
                  "bg-white",
                  "dark:bg-gray-800",
                  "rounded-lg",
                  // 'p-6',
                  "text-left",
                  // 'overflow-hidden',
                  "shadow-xl",
                  "transform",
                  "transition-all",
                  "sm:my-8",
                  "sm:align-middle",
                  "w-full",
                ])}
                style={wrapperStyle}
              >
                {header !== null && (
                  <div>
                    <div className="flex items-center p-4 space-x-4">
                      {type && (
                        <FontAwesomeIcon
                          className={iconColor[type]}
                          //@ts-ignore
                          icon={icon[type]}
                          style={{ fontSize: 24 }}
                        />
                      )}
                      {title && (
                        <>
                          <div className="flex justify-between flex-1">
                            {typeof title == "string" ? (
                              <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-800 dark:text-gray-200"
                              >
                                {title}
                              </Dialog.Title>
                            ) : (
                              title
                            )}
                            <div>{extra}</div>
                          </div>
                        </>
                      )}
                      <div className="absolute top-0 right-1.5">
                        <button
                          onClick={() => onCancel?.()}
                          className="w-4 hover:opacity-60"
                        >
                          <Typography flat type="secondary">
                            <FontAwesomeIcon icon={faTimes} />
                          </Typography>
                        </button>
                      </div>
                    </div>
                    <Divider flat />
                  </div>
                )}
                {/* TODO: Keep an eye on overflow auto, may need to remove */}
                <div
                  style={_bodyStyle}
                  className="flex flex-col flex-auto h-0 p-4 overflow-auto min-h-min"
                >
                  {loading ? (
                    <div className="h-52">
                      <Loader size="xl" center />
                    </div>
                  ) : (
                    children
                  )}
                </div>
                {React.isValidElement(footer)
                  ? footer
                  : footer !== null && (
                      <div
                        className={classNames([
                          "flex flex-row-reverse px-4 py-3 border-t",
                          THEME_DEFAULT_BORDER,
                        ])}
                      >
                        {!hideOkButton && (
                          <Button
                            type="primary"
                            onClick={handleOnOk}
                            loading={pendingOk}
                            disabled={pending}
                            className="ml-3"
                            {...(okButtonProps || {})}
                          >
                            {okText || "Ok"}
                          </Button>
                        )}
                        {!hideCancelButton && (
                          <Button
                            onClick={handleOnCancel}
                            loading={pendingCancel}
                            disabled={pending}
                            {...(cancelButtonProps || {})}
                          >
                            {cancelText || "Cancel"}
                          </Button>
                        )}
                      </div>
                    )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Title = ({ children }) => {
  return (
    <Dialog.Title
      as="h3"
      className="text-xl font-medium leading-6 text-gray-800 dark:text-gray-200"
    >
      {children}
    </Dialog.Title>
  );
};

Modal.Title = Title;
Modal.ExternalLink = ExternalLink;

export { Modal };
