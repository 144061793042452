interface IRouteProps {
    parent?: Pather;
    isNav?: boolean;
    isPrivate?: boolean;
    title?: string;
    roles?: string[] | number[];
    route: string;
    requiresAdmin?: boolean;
}

type IIsMatch = {
    exact?: boolean | undefined;
}

export default class Pather {
    public hasParams = false;
    public paramCount = 0;

    private __route!: IRouteProps | string;
    private __parent?: Pather;
    public _isNav?: boolean;
    public _isPrivate?: boolean;
    public _title?: string;
    private _parent?: Pather;
    private _relativeRoute: string;
    private _route: string;
    private _roles?: string[] | number[];
    private _children?: string[] | number[] = [];
    private _url?: string;
    private _protocol?: string;
    private _params?: {} | [];
    private _requiresAdmin?: boolean = false;

    constructor(route: IRouteProps | string, parent?: Pather) {
        this.__route = route;
        this.__parent = parent;
        if (typeof route === 'object') {
            this._isNav = route.isNav;
            this._isPrivate = route.parent ? route.parent._isPrivate : route.isPrivate;
            this._title = route.title;
            this._roles = route.roles;
            this._relativeRoute = route.route;
            this._protocol = route.route?.includes('https://') ? 'https://' : route.route?.includes('http://') ? 'http://' : '';
            this._route = route.route?.replace('http://', '')?.replace('https://', '');
            this._parent = route.parent;
            this._requiresAdmin = route.requiresAdmin;
        } else {
            this._protocol = route?.includes('https://') ? 'https://' : route?.includes('http://') ? 'http://' : '';
            this._route = route?.replace('http://', '')?.replace('https://', '');
            this._relativeRoute = route?.replace('http://', '')?.replace('https://', '');
            this._parent = parent;
        }

        if (this._parent) {
            this._route = `${this._parent._route}/${this._route}`?.replace(/\/\/+/gi, '/');
            //@ts-ignore
            this._parent?._children?.push(this._route)
        }

        if (!route) {
            new Error('Route required to initialize.')
        }
        if (typeof route != 'string') {
            new Error('Route must be a string to initialize.')
        }

        if (this._route?.replace('http:', '')?.replace('https:', '').indexOf(':') > -1) {
            this.hasParams = true;
            this.paramCount = (this._route.replace('://', '').split(':') || [0]).length - 1;
        }
    }
    
    get requiresAdmin() {
        return !!this?._requiresAdmin || !!this._parent?._requiresAdmin;
    }
    
    get isChild() {
        return !!this?._parent;
    }
    
    get hasChildren() {
        return !!this?._children?.length;
    }
    
    get toRoute() {
        let route = this._route.replaceAll('*', '');
        if (this._route.endsWith('*')) {
            route += '/*';
        }
        return this._removeExtraSlashes(route);
    }
    
    get toRelativeRoute() {
        return this._relativeRoute;
    }

    get toURL() {
        if (this.hasParams && !this._url) {
            throw new Error('URL requires params. Use createURL to build the URL string before attempting to access the toURL getter.')
        }
        return this._protocol + this._removeExtraSlashes(this._removeWildCards(this._url || this._route));
    }

    public clone(): Pather {
        return new Pather(this.__route, this.__parent);
    }
    
    // STATIC METHODS
    static join(...args): string {
        let newPath = '';
        if (args?.length > 1) {
            for (const arg of args) {
                if (typeof arg == 'string') {
                    newPath += arg.replaceAll('/*', '');
                }
            }
        } else {
            // throw new War;
        }

        return newPath.replace(/(?<!:)\/\/+/g, '/');
    }

    /**
     * Adds hash fragment into the url
     * 
     * @param {string} params Contains the hash value to insert into the url
     */
    public addHash(hash:string) {

        if (!!hash) {
            this._url = `${this.toURL}#${hash}`;
        }

        return this;
    }

    /**
     * Takes a dynamic URL and places the values in their respective positions.
     * 
     * @param {[Object, Array]} params Contains the values to replace the dynamic params in a URL.
     */
    public addParams(params: {} | [] | undefined, temp = false) {
        if (!this.hasParams) {
            throw new Error(`${this._route} - URL does not require params. Access url using getter to receive url string.`);
        } else if (!params) {
            throw new Error('Params are required to create the URL.');
        } else if (typeof params != 'object' && !Array.isArray(params)) {
            throw new Error('Params must be an Object or an Array.');
        }

        let url: any = this._route;
        if (Array.isArray(params)) {
            if ((url.split(':').length - 1) !== params.length) {
                throw new Error('The count in the array provided does not match the count of params required. Review the array provided.');
            }
            url = this._buildURLFromArray(params);

        } else if (typeof params == 'object') {
            url = this._buildURLFromObj(params);            
        }

        this._params = params;

        this._url = this._removeWildCards(url);

        return this;
    }

    /**
     * Build the query string for a link.
     * 
     * @param {Object} query Contains the prop/values to build the query string to append to the URL.
     */
    public addQuery(query: {[x: string]: any} | string, params?: {} | [] | undefined) {
        let url: string;
        // if (typeof query != 'object') {
        //     throw new Error('Query must be an object.');
        // }
        if (typeof query == 'string') {
            if (this.hasParams) {
                url = `${this.addParams(params || this._params).toURL}?${query}`;
            } else {
                url = `${this._route}?${query}`;
            }
        } else {
            if (this.hasParams && (
                (typeof this._params === 'object' && Object.keys(this._params).length === 0) ||
                (Array.isArray(this._params) && this._params.length === 0)
            )) {
                throw new Error('URL requires params. Pass the route required paramaters prior to building the query string to complete the URL.');
            }
    
    
            const queryArr: any[] = [];
            const queryHasArrayValues = Object.keys(query).some(key => Array.isArray(query[key]))
            // const queryHasDuplicateKeys = Object.keys(query).map(key => `${key}=${query[key]}`)
            if (queryHasArrayValues) {
                Object.keys(query).forEach(key => {
                    if (query[key] !== undefined) {
                        if (Array.isArray(query[key])) {
                            query[key].forEach((v: any) => {
                                queryArr.push(`${key}[]=${v}`);
                            })
                        } else {
                            queryArr.push(`${key}=${query[key]}`);
                        }
                    }
                })
            } else {
                queryArr.push(...Object.keys(query).map(key => {
                    if (query[key] !== undefined) {
                        return `${key}=${query[key]}`;
                    }
                }));
            }
    
            if (this.hasParams) {
                url = `${this.addParams(params || this._params).toURL}?${queryArr.join('&')}`;
            } else {
                url = `${this._route}?${queryArr.join('&')}`;
            }
    
        }
        
        this._url = url;

        return this;
    }

    public isMatch(args?: IIsMatch): RegExpMatchArray | null {
        const exact = args?.exact;

        const path = window.location.pathname;
        let matchRegex = this.toRoute.replace(/:[^\s/]+/g, '([\\w-]+)');
        matchRegex = '^' + matchRegex;

        if (exact) {
            matchRegex = matchRegex + '$';
        }

        const routeMatcher = new RegExp(matchRegex);
        return path.match(routeMatcher);
    }

    /**
     * Check access to route
     * 
     * @param {Array} roles Contains roles passed by user to return if they have access to the route
     */
    public hasAccess(roles = []): boolean {
        if (!this._roles || (Array.isArray(this._roles) && this._roles.length === 0)) {
            return true;
        }

        return roles.some((r: any) => this._roles && this._roles[r])
    }





    /**
     * ______________________________________________________________________________________________
     * 
     * 
     *            PRIVATE METHODS              PRIVATE METHODS             PRIVATE METHODS
     * 
     * ______________________________________________________________________________________________
     */

    private _buildURLFromArray(arr: any[] | undefined) {
        if (!arr) return;
        let url = this._route;

        for (let i = 0; i < arr.length; i++) {
            const a = arr[i];
            for (let k = 0; k < url.length; k++) {
                const c = url[k];

                if (c === ':') {
                    const slashLoc = url.indexOf('/', k);
                    
                    const nextPart = slashLoc > -1 ? url.slice(slashLoc) : '';
                    
                    url = [url.slice(0, k), a, nextPart].join('');
                    break;
                }
            }
        }
        return url;
    }

    private _buildURLFromObj(obj: {[x:string]: any}) {
        let url = this._route;
        const matches = url.match(/:[A-Za-z]*[^\/]/g);

        if (!matches) {
            throw new Error('No parameters could be found in route.');
        }

        matches.forEach(match => {
            const objMatch = match.replace(':', '');

            if (obj[objMatch]) {
                // throw new Error('One of the properties inside of the object provided does not match any route params. Review the object provided.');
                url = url.replace(match, obj[objMatch]);
            }
        })

        if (url?.replace('://', '')?.includes(':')) {
            throw new Error(`Missing one or more parameters for the provided route. ${this.__route}`)
        }
        
        return url;
    }

    private _removeWildCards(path: string) {
        return path.replaceAll('/*', '');
    }

    private _removeExtraSlashes(path: string) {
        return path.replace(/(?<!:)\/\/+/g, '/');
    }
}